<template lang="html">
    <div class="container" id="text-page">
      <!-- English -->
      <div v-if="$i18n.locale == 'en'">
        <h1 class="mb-2 page-title txt-black">Privacy policy</h1>
  
        <p>The policy set forth below is provided in English only to avoid any misinterpretations caused by a language translation. If necessary, please seek the proper advice, or please do not use this web site.</p>
  
        <p>Effective Date: January 1, 2023</p>
        <p><br></p>
        <p>Sotheby’s International Realty® Online Privacy Statement</p>
        <p><br></p>
        <p>Sotheby’s International Realty Affiliates LLC (“SIRA”) and our affiliate and subsidiary companies (such as Anywhere Real Estate Inc., Guaranteed Rate Affinity, Anywhere Integrated Services, Anywhere Advisors, Realogy Insurance Agency, and Anywhere Leads Inc.) (collectively, “SIRA” “we,” “our,” or “us”) are committed to privacy and to transparency in our information practices. This Privacy Policy describes how we collect, use, disclose, and otherwise process the personal information described in this Privacy Policy, as well as the rights and choices individuals have regarding such personal information. This Privacy Policy applies to the extent we process personal data on our own behalf, as a controller or business.</p>
        <p><br></p>
        <p>If you are a California resident, please be sure to review Section 17B. California Residents for important information about the categories of personal information we collect and disclose as well as your rights under California privacy laws.</p>
        <p><br></p>
        <!-- <p>You can click the links below to go directly to a particular section in our Privacy Policy.</p>
        <p><br></p> -->
        <ol>
          <li><a class="no-cursor">SCOPE OF THIS PRIVACY POLICY</a></li>
          <li><a class="no-cursor">OUR COLLECTION OF PERSONAL INFORMATION</a></li>
          <li><a class="no-cursor">PURPOSES AND LEGAL BASIS FOR OUR PROCESSING</a></li>
          <li><a class="no-cursor">DISCLOSURE OF PERSONAL INFORMATION</a></li>
          <li><a class="no-cursor">AGGREGATE AND NON-IDENTIFIABLE DATA</a></li>
          <li><a class="no-cursor">COOKIES, TRACKING, AND ANALYTICS</a></li>
          <li><a class="no-cursor">INTEREST-BASED ADVERTISING</a></li>
          <li><a class="no-cursor">SOCIAL FEATURES AND THIRD-PARTY PLUG INS</a></li>
          <li><a class="no-cursor">INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</a></li>
          <li><a class="no-cursor">SECURITY</a></li>
          <li><a class="no-cursor">DATA RETENTION</a></li>
          <li><a class="no-cursor">YOUR RIGHTS AND CHOICES</a></li>
          <li><a class="no-cursor">CHILDREN</a></li>
          <li><a class="no-cursor">THIRD-PARTY WEBSITES</a></li>
          <li><a class="no-cursor">CHANGES TO THIS PRIVACY POLICY</a></li>
          <li><a class="no-cursor">CONTACT US</a></li>
          <li><a class="no-cursor">ADDITIONAL INFORMATION FOR CERTAIN JURISDICTIONS</a>
            <ul>
              <li><a class="no-cursor">EEA and the United Kingdom</a></li>
              <li><a class="no-cursor">California Residents</a></li>
              <li><a class="no-cursor">Residents of Other U.S. States</a></li>
            </ul>
          </li>
        </ol>
        <h5>1. SCOPE OF THIS PRIVACY POLICY</h5>
        <p>This Privacy Policy applies to our collection, use, disclosure, and other processing of personal information related to:</p>
        <p><br></p>
        <p>Our websites (each, a “Website ”) and mobile applications (each, an “ App ”) that display or link to this Privacy Policy and our other services, products, and technology solutions (collectively, the “ Services ”).</p>
        <p>Former, current, and prospective clients, brokers, independent agents, and customers.</p>
        <p>Other individuals who use our Services, whose personal information we receive related to the Services, or who otherwise interact or communicate with us related to our Services or our business.</p>
        <p>Our collection, use, disclosure, and processing of personal information about individuals will vary depending upon the circumstances. This Privacy Policy is intended to describe our overall privacy and data protection practices.</p>
        <p><br></p>
        <p>Not covered by this Privacy Policy. This Privacy Policy does not apply to our employees and non-employee workers.</p>
        <p><br></p>
        <p>Additional privacy notices. In some cases, different or additional notices about our data collection and processing practices may be provided and apply to our processing of certain personal information. The additional notice will control to the extent there is a conflict with this Privacy Policy, with respect to your personal information that is subject to that notice.</p>
        <p><br></p>
  
        <h5>2. OUR COLLECTION OF PERSONAL INFORMATION</h5>
        <p>Generally, we collect your personal information on a voluntary basis. However, if you decline to provide certain personal information that is marked mandatory, you may not be able to access certain Services or we may be unable to fully respond to your inquiry.</p>
        <p><br></p>
        <p>We may collect personal information directly from individuals, automatically related to the use of the Services, and in some cases, from third parties (such as social networks, platform providers, payment processors, data providers, and operators of certain third- party services that we use). In some cases (such as where required by law), we ask for your consent or give you certain choices prior to collecting or using certain personal information.</p>
        <p><br></p>
        <p>Information we collect directly. We collect information that you provide to us, such as:</p>
        <p><br></p>
        <p>When you create an account, provide contact information, or send other personal information to us.</p>
        <p>When you complete or submit a form through our Services, for example, “Contact Us” or “More Information” forms.</p>
        <p>When you participate in surveys or contests, submit orders or requests, or register for or use our Services, or otherwise contact us.</p>
        <p>Information you submit or provide such as real estate searches and preferences, whether you plan on buying or selling real estate, home search criteria, information about financing for the purchase of real estate, and other information related to the purchase or sale of real estate.</p>
        <p>Information we collect from third parties. We may collect or receive personal information about you and your property from third party sources, such as public databases, joint marketing partners, social media platforms (including from people with whom you are friends or otherwise connected), brokers and affiliated agents, and other real estate professionals, and third parties. For example:</p>
        <p><br></p>
        <p>Social media. If you choose to link, create, or log in to your Services account with a social media service (e.g., Twitter, Facebook, Instagram, etc.), or if you engage with a separate app or website that communicates with the Services, we may receive personal information about you or your connections from that service. In many cases you can control what personal information you share with us through privacy settings available on those third-party social media services.</p>
        <p>Real estate professionals. If you interact or list a property with a broker, independent agent, or other real estate professional associated with an Anywhere brand, we may receive personal information about you and your property from them, such as your name and property address, listing and contact information, among other things. These real estate professionals may be affiliated with independently owned and operated brokerages, or with an Anywhere company.</p>
        <p>Property records. We may collect information about individuals and real estate property through public databases, such as property ownership and tax records maintained by state, local and county agencies.</p>
        <p><br></p>
  
        <h5>3. PURPOSES AND LEGAL BASIS FOR OUR PROCESSING</h5>
        <p>Certain laws, including the EU General Data Protection Regulation (“GDPR”), UK and Brazil data protection law, and similar laws require that we inform applicable individuals of the legal basis for our use and other processing of their personal information. In this section, we described the purposes for which will collect, use, disclose and otherwise process personal information, as well as the legal bases for such processing, generally, under such privacy laws.</p>
        <p><br></p>
        <p>Legal bases under certain privacy laws. Pursuant to the GDPR, UK and Brazil data protection law, and similar laws we collect, use and otherwise process personal information for the following legal bases:</p>
        <p><br></p>
        <p>Performance of contract: as necessary to enter into or carry out the performance of our contract with you.</p>
        <p>Compliance with laws: for compliance with legal obligations and/or defense against legal claims, including those in the area of labor and employment law, social security, data protection, tax, and corporate compliance laws.</p>
        <p>Our legitimate interests: in furtherance of our legitimate business interests including, for example:</p>
        <p>Performance of contracts with franchisees and other parties</p>
        <p>Implementation and operation of global support (e.g., IT) services for our business operations</p>
        <p>Customer relationship management and improving our Services, including marketing and analytics</p>
        <p>Marketing and advertising</p>
        <p>Fraud prevention, including misuse of company IT systems or money laundering</p>
        <p>Physical, IT, and network perimeter security</p>
        <p>Internal investigations</p>
        <p>Mergers, acquisitions, and reorganization, and other business transactions</p>
        <p>With your consent: in some cases, we rely on your consent to collect and process your personal information. (See Section 17A below for information about withdrawing your consent.)</p>
        <p>In addition, we may process your personal information where necessary to protect the vital interests of any individual.</p>
        <p><br></p>
        <p>Purposes of processing. While the purposes for which we may process personal information will vary depending upon the circumstances, in general we collect, use, disclose and otherwise process personal information for the purposes set forth below, as well as the legal basis for such processing, as applicable under relevant laws (see above for further explanation of these legal bases):</p>
        <p><br></p>
        <p>Providing support and services: including, for example, to send or facilitate communications among you, independent real estate professionals and our affiliated entities, and other users of the Services, provide products and services you request (and send related information), operate our Services; to communicate with you about your access to and use of our Services; to respond to your inquiries; to provide troubleshooting, fulfill your requests and provide technical support; and for other customer service and support purposes. (Legal basis: performance of our contract with you; and our legitimate interests)</p>
        <p>Analyzing and improving our business: including to better understand how users access and use our Services, to evaluate and improve our Services and business operations, and to develop new features, offerings, and services; to conduct surveys, and other evaluations, such as customer satisfaction surveys; and for other research and analytical purposes. (Legal basis: our legitimate interests)</p>
        <p>Personalizing content and experiences: including to provide or recommend features, content, social connections, and referrals; tailor content we send or display on our Services; to offer location customization and personalized help and instructions; and to otherwise personalize your experiences. (Legal basis: our legitimate interests and/or with your consent)</p>
        <p>Advertising, marketing and promotional purposes: including to reach you with more relevant ads and to evaluate, measure, and improve the effectiveness of our ad campaigns; to send you newsletters, offers, or other information we think may interest you; to contact you about our Services or information we think may interest you; and to administer promotions and contests. (Legal basis: our legitimate interests and/or with your consent)</p>
        <p>Securing and protecting our business: including to protect and secure our business operations, assets, Services, network and information and technology resources; to investigate, prevent, detect and take action regarding fraud, unauthorized access, situations involving potential threats to the rights or safety of any person or third party, or other unauthorized activities or misconduct. (Legal basis: our legitimate interests and/or compliance with laws)</p>
        <p>Defending our legal rights: including to manage and respond to actual and potential legal disputes and claims, and to otherwise establish, defend or protect our rights or interests, including in the context of anticipated or actual litigation with third parties. (Legal basis: our legitimate interests and/or compliance with laws)</p>
        <p>Planning and facilitating business transactions: related to any actual or contemplated merger, acquisition, asset sale or transfer, financing, bankruptcy or restructuring of all or part of our business. (Legal basis: our legitimate interests and/or compliance with laws)</p>
        <p>Auditing, reporting, corporate governance, and internal operations: including relating to financial, tax and accounting audits; audits and assessments of our operations, privacy, securityand financial controls, risk, and compliance with legal obligations; and our general business, accounting, record keeping and legal functions. (Legal basis: our legitimate interests and/or compliance with laws)</p>
        <p>Complying with legal obligations: including to comply with the law, our legal obligations and legal process, such warrants, subpoenas, court orders, and regulatory or law enforcement requests. (Legal basis: our legitimate interests and/or compliance with laws)</p>
        <p><br></p>
        <h5>4. DISCLOSURE OF PERSONAL INFORMATION</h5>
        <p>We may disclose the personal information we collect to third parties, as reasonably necessary for the purposes described above and as otherwise directed or consented to by you, including:</p>
        <p><br></p>
        <p>Service providers to third-party service providers who use this information to perform services for us, such as hosting providers, auditors, advisors, consultants, and customer service and support providers.</p>
        <p>Subsidiaries and affiliates: to our subsidiary and affiliated companies (i.e., companies under common ownership, control or management with us), such as Anywhere Real Estate Inc., Guaranteed Rate Affinity, Anywhere Integrated Services, Anywhere Advisors, Realogy Insurance Agency, and Anywhere Leads Inc., who may process your personal information in accordance with the principles of this Privacy Policy, including to share relevant news, information and offers with you. Where required by applicable law, we will obtain your consent to such sharing.</p>
        <p>Third parties: to third parties in order to provide our Services, respond to or carry out your requests, and/or with your consent, including to:</p>
        <p>Independent real estate professionals and owners and operators of real estate brokerage companies to enable them to provide services you request through the Services.</p>
        <p>Third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us.</p>
        <p>The public if you submit to a public part of the Services, such as comments, social media posts, or other features viewable by the public or generally by registered users of the Services.</p>
        <p>Third parties with whom you choose to let us share information, for example other apps or websites that integrate with the Services, or those with Services with which we integrate.</p>
        <p>Third parties we work with to provide you with more personalized content and ads, and to track the effectiveness of campaigns.</p>
        <p>Business transfers: as part of any actual or contemplated merger, sale, and transfer of our assets, acquisition, financing or restructuring of all or part of our business, bankruptcy or similar event; and prior to the completion of such a transfer, where necessary for due diligence or to plan for the transfer, such as to lenders, auditors, and third-party advisors, such attorneys and consultants, where permitted by law.</p>
        <p>Legally required: where we are required to do so by law or legal process (e.g., to law enforcement, courts or others, or in response to a subpoena or court order).</p>
        <p>Protect our rights: where we believe it necessary to respond to claims asserted against us or, comply with legal process (e.g., subpoenas or warrants), enforce or administer our agreements and terms, for fraud prevention, risk assessment, investigation, and to protect the rights, property, or safety of us, our clients and customers or others. including personal information, related to litigation and other legal claims or proceedings in which we are involved, as well as for our internal accounting, auditing, compliance, recordkeeping, and legal functions.</p>
        <p><br></p>
        <h5>5. AGGREGATE AND NON-IDENTIFIABLE DATA</h5>
        <p>We may also use and disclose aggregate, anonymous, and other non-identifiable data related to our business and the Services for quality control, analytics, research, development and other purposes. Where we use, disclose or process de-identified data (data that is no longer reasonably linked or linkable to an identified or identifiable natural person, household, or personal or household device) we will maintain and use the information in deidentified form and not to attempt to reidentify the information, except as permitted by applicable privacy laws (such as to confirm whether our deidentification processes are reasonable and adequate).</p>
        <p><br></p>
        <h5>6. COOKIES, TRACKING, AND ANALYTICS</h5>
        <p>Our Services may use first party and third-party cookies, pixel tags, plugins, and other tools to gather device, usage and browsing information when users visit our Services. For instance, when you visit our Services, our server may record your IP address (and associated location information) and other information such as the type of your internet browser, your Media Access Control (MAC) address, computer type (Windows or Macintosh), screen resolution, operating system name and version, device manufacturer and model, language, and the pages you view and links you select on the Services, as well as date and time stamps associated with your usage of and activities on our Services.</p>
        <p><br></p>
        <p>We use the information for security purposes, to facilitate navigation, to improve, analyze and optimize our Services and their functionality, to personalize and improve your experience while using the Services, to improve and measure our advertising campaigns, and to better reach users with relevant advertising both on our Services and on third party websites.</p>
        <p><br></p>
        <p>Click “Cookie Preferences” link in the footer of our Website for more information about cookies on that Website, and to review and update your preferences for most types of cookies (other than those that are necessary to the functionality of that Website).</p>
        <p><br></p>
        <p>Cookies. Cookies are small text files that a website transfers to your hard drive to store and sometimes collect information about your usage of websites, such as time spent on the websites, pages visited, language preferences, and other anonymous traffic data. You can control the way in which cookies are used by altering your browser settings. You may refuse to accept cookies by activating the setting on your browser that allows you to reject cookies. However, if you select such a setting, this may affect the functioning of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you access or log on to our Service.</p>
        <p><br></p>
        <p>If you have disabled one or more cookies, we may still use information collected from cookies before your disabled preference was set. However, we will stop using the disabled cookie to collect any further information.</p>
        <p><br></p>
        <p>Pixel tags and other similar technologies. Pixel tags (also known as web beacons and clear GIFs) may be used in connection with some Websites to, among other things, track the actions of users of the Websites (including email recipients), measure the success of our marketing campaigns and compile statistics about usage of the Websites and response rates. We and our service providers may also use pixel tags in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, track whether our emails are forwarded, and conduct analytics.</p>
        <p><br></p>
        <p>Log files. Most browsers collect certain information, such as your IP address, device type, screen resolution, operating system version and internet browser type and version. This information is gathered automatically and stored in log files.</p>
        <p><br></p>
        <p>Third party analytics tools. Our Websites use automated devices and applications operated by third parties, such as Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the Websites and report on activities and trends. This service may also collect information regarding the use of other websites, apps and online resources. You can learn about Google’s practices and opt-out of collection of your browsing data by Google Analytics at https://tools.google.com/dlpage/gaoptout.</p>
        <p><br></p>
        <p>Browser signals. Please note that our Websites do not recognize or respond to browser “do-not-track” requests or other browser privacy signals. However, you can review and update your cookie preferences on our Websites as discussed above.</p>
        <p><br></p>
        <h5>7. INTEREST-BASED ADVERTISING</h5>
        <p>On some of our Websites, we may work with third-party ad networks, analytics companies, measurement services and others (“third-party ad companies”) to display advertising on our Services and to manage our advertising on third-party sites, mobile apps and online services. We and these third-party ad companies may use cookies, pixels tags and other tools to collect information on our Websites (and on third-party sites and services), such as browsing history, IP address, device ID, cookie and advertising IDs, and other identifiers, general location information and, with your consent, your device’s geolocation information; we and these third-party ad companies use this information to provide you more relevant ads and content and to evaluate the success of such ads and content.</p>
        <p><br></p>
        <p>You can review and manage your preferences for targeting cookies and get information about to targeting cookies in use on one of our Websites by clicking “Cookie Settings” in the footer of our website.</p>
        <p><br></p>
        <p>You may also obtain more information about targeted or “interest-based advertising” and opt-out of many ad networks at the industry websites below:</p>
        <p><br></p>
        <p>Canada: youradchoices.ca</p>
        <p>EU: youronlinechoices.eu</p>
        <p>U.S: youradchoices.com</p>
        <p><br></p>
        <h5>8. SOCIAL FEATURES AND THIRD-PARTY PLUG INS</h5>
        <p>The Services may integrate with social sharing features and other related tools which let you log in to the Services or share actions you take on the Services. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share through them.</p>
        <p><br></p>
        <h5>9. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</h5>
        <p>SIRA is headquartered in the United States and has affiliates and service providers in multiple jurisdictions. As such your personal information may be transferred to the United States and other jurisdictions where we or our service providers have operations, and may be subject to disclosure to the governments, courts, or law enforcement or regulatory agencies of such of these jurisdictions upon transfer. Some of these jurisdictions—including the United States—may not provide the same level of data protection as your home country. In such cases, we rely on suitable personal information transfer safeguards. You may request a copy or information about those safeguards by contacting us as detailed below.</p>
        <p><br></p>
        <h5>10. SECURITY</h5>
        <p>We use technical, administrative, and physical controls in place to help protect personal information from unauthorized access, use, and disclosure. Even so, despite our efforts, no security measure is ever perfect or impenetrable. In the event that the security of your account has been compromised, please immediately notify us in accordance with Section 16. Contact Us.</p>
        <p><br></p>
        <h5>11. DATA RETENTION</h5>
        <p>We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law. We may retain personal information for longer where required by our legal and regulatory obligations, professional indemnity obligations, or where we believe it is necessary to establish, defend or protect our legal rights and interests or those of others. With respect to the data and files we handle as a processor, we retain this personal information in accordance with our clients’ instructions.</p>
        <p><br></p>
  
        <h5>12. YOUR RIGHTS AND CHOICES</h5>
        <p>We provide several ways for you to exercise certain privacy choices regarding your personal information processed by us, as described in this section. We are committed to respecting the privacy rights of individuals under all privacy laws applicable to us. Some privacy laws require that we provide specific information about individual rights to applicable consumers, which we have set forth in Section 17 of this Privacy Policy. California residents, please review Section 17B. California Residents for information about your California privacy rights.</p>
        <p><br></p>
        <p>Marketing. You may opt out from receiving marketing-related communications from us on a going-forward basis by contacting us or by using the unsubscribe mechanism contained in each email. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt out of receiving marketing-related emails from us, we may still send you important administrative messages, from which you cannot opt out.</p>
        <p><br></p>
        <p>Cookies preferences. To review and update your preferences for most types of cookies (other than those that are necessary) for one of our Websites, Click “Cookie Preferences” link in the footer of that Website.</p>
        <p><br></p>
        <p>Deactivation. You can deactivate your account at any time by contacting us at the email address at the bottom of this page. However, you will not be able to access many of the services to deactivate your account. Please note that even if you request deactivation of your account, it may take some time to fulfill this request.</p>
        <p><br></p>
        <p>Access, amendment and deletion. Under applicable privacy laws, you may have the right to request to review, make amendments, have deleted or otherwise exercise your rights over your personal information that we hold, subject to certain legal limitations and requirements. If you are subject to such a privacy law, you may submit a request to us related to your personal information:</p>
        <p><br></p>
        <p>By submitting an email request to us at dataprivacy@anywhere.re</p>
        <p>By contacting us at (888) 778-6995 (toll free)</p>
        <p>We will process in accordance with and where required by applicable law, and with respect to your personal information that is linked or linkable to the email that you use to send us your request and the verifiable information provided by you as a part of your request. We will take steps to verify your identity before implementing your request.</p>
        <p><br></p>
        <p>Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion. There may also be residual information that will remain within our databases and other records, which will not be removed. We may not always be able to fully address your request, for example if it would impact the duty of confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way.</p>
        <p><br></p>
        <h5>13. CHILDREN</h5>
        <p>The Services are not intended or directed to children under the age of 16, and we do not knowingly collect any personal information, or knowingly track the use of our Services, from children. If we have actual knowledge that personal information about a child under 16 years old has been collected, then we will take appropriate steps to try and delete such personal information.</p>
        <p><br></p>
        <h5>14. THIRD-PARTY WEBSITES</h5>
        <p>The Services may contain links to third-party services. We do not own, operate, or control the websites of third parties, including those of independently owned and operated franchisees. Accordingly, this Privacy Policy does not apply to any services maintained or operated by third-parties. When you click on those links, you will go to a third-party website where you will be subject to that service’s privacy policy or similar statement and terms of use, and we encourage you to read that policy statement. We are not responsible for the privacy practices of other services, and we expressly disclaim any liability for their actions, including actions related to the use and disclosure of personal information by those third parties.</p>
        <p><br></p>
  
        <h5>15. CHANGES TO THIS PRIVACY POLICY</h5>
        <p>We may amend this Privacy Policy at any time. If we make any material change in how we collect, use, disclose, or otherwise process personal information, we will prominently post a notice regarding such change on the Services. Any material changes to this Privacy Policy will be effective 10 days after our posting of the updated Privacy Policy or as otherwise required by applicable law. Where required to do so by law, we may seek your prior consent to any material changes we make to this Privacy Policy.</p>
        <p><br></p>
        <h5>16. CONTACT US</h5>
        <p>If you have any questions or concerns about this Notice, please use the following contact information:</p>
        <p><br></p>
        <p>dataprivacy@anywhere.re</p>
        <p>Mailing address: 175 Park Ave., Madison, NJ 07940 Attn: Data Privacy</p>
        <p>Phone number: (888) 778-6995</p>
        <p><br></p>
  
        <h5>17. ADDITIONAL INFORMATION FOR CERTAIN JURISDICTIONS</h5>
        <p>If you are a resident of the EEA or the United Kingdom, please review Section 17.A below for additional information about your privacy rights under applicable privacy laws.</p>
        <p><br></p>
        <p>If you are a resident of the United States, please review Section 17.B below for additional information about the categories of personal information we collect about you and your privacy rights under applicable California privacy laws and Section 17.C below for additional information about privacy rights under other U.S. state privacy laws.</p>
        <p><br></p>
  
        <h6>A. EEA and the United Kingdom</h6>
        <p>The GDPR and UK data protection laws (where applicable), gives data subjects the following rights regarding their personal information:</p>
        <p><br></p>
        <p>Right of access: You have the right to obtain from us confirmation as to whether or not personal information concerning you is being processed, and where that is the case, to request access to the personal information. The accessed information includes – among others – the purposes of the processing, the categories of personal information concerned, and the recipients or categories of recipient to whom the personal information have been or will be disclosed. You have the right to obtain a copy of the personal information undergoing processing. For further copies requested by you, we may charge a reasonable fee based on administrative costs.</p>
        <p>Right to rectify and complete personal information:you can request the rectification of inaccurate data and the completion of incomplete data. We will inform relevant third parties to whom we have transferred your data about the rectification and completion if we are legally obliged to do so.</p>
        <p>Right to erasure (right to be forgotten): You have the right to obtain from us the erasure of personal information concerning you in limited circumstances where:</p>
        <p>it is no longer needed for the purposes for which it was collected;</p>
        <p>you have withdrawn your consent (where the data processing was based on consent);</p>
        <p>following a successful right to object;</p>
        <p>it has been processed unlawfully; or</p>
        <p>the data has to be erased in order to comply with a legal obligation to which we are subject.</p>
        <p>We are not required to comply with your request to erase personal information if the processing of your personal information is necessary for compliance with a legal obligation, or the establishment, exercise or defense of legal claims.</p>
        <p><br></p>
        <p>Right to restriction of processing: You have the right to obtain from us restriction of processing your personal information. In this case, the respective data will be marked and only be processed by us for certain purposes. This right can only be exercised, subject to certain limitations, where: (i) the accuracy of your personal information is contested; (ii) the processing is unlawful, but you do not want the personal information erased; (iii) it is no longer needed for the purposes for which it was collected, but you still need it to establish, exercise or defend legal claims; or (iv) you have exercised the right to object, and verification of overriding grounds is pending.</p>
        <p>Right to data portability: You have the right to receive the personal information concerning you, which you have provided to us, in a structured, commonly used and machine-readable format and you have the right to transmit those data to another entity without hindrance from us, but in each case only where the processing is (a) based on your consent or on the performance of a contract with you, and (b) also carried out by automated means.</p>
        <p>Right to object: You have the right to object at any time to any processing of your personal information which has our legitimate interests as its legal basis. You may exercise this right without incurring any costs. If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms. The right to object does not exist, in particular, if the processing of your personal information is necessary to take steps prior to entering into a contract or to perform a contract already concluded.</p>
        <p>Right to withdraw consent: if you have given us your consent for the processing of your personal information, you have the right to withdraw your consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.</p>
        <p>Right to object to our use of your personal information for direct marketing: You can request that we change the manner in which we contact you for marketing purposes. You can request that we not transfer your personal information to unaffiliated third parties for the purposes of direct marketing or any other purposes.</p>
        <p>Right to obtain a copy of safeguards: you can ask to obtain a copy of, or reference to, the safeguards under which your personal information is transferred outside the EU/EEA. We may redact data transfer agreements to protect commercial terms.</p>
        <p>Right to lodge a complaint with your local supervisory authority: You have a right to lodge a complaint with your local supervisory authority if you have concerns about how we are processing your personal information. We ask that you please attempt to resolve any issue with us first, although you have a right to contact your supervisory authority at any time.</p>
        <p>You may submit your GDPR request to us via:</p>
        <p><br></p>
        <p>Our online webform</p>
        <p>Phone at (888) 778-6995 (toll free)</p>
        <p><br></p>
  
        <h6>B. California Residents.</h6>
        <p>This section provides California residents with additional information regarding our collection, use and disclosure of their personal information, as well as their privacy rights, under California privacy laws.</p>
        <p><br></p>
        <p>In this section, we provide additional information to California residents about how we handle their personal information, as required by the California Consumer Privacy Act (“CCPA”). This section does not address or apply to our handling of personal information that is exempt under the CCPA.</p>
        <p><br></p>
        <p>Categories of personal information that we collect and disclose. Our collection, use and disclosure of personal information about a California resident will vary depending upon the circumstances and nature of our interactions or relationship with such resident. Below we describe the categories of personal information we may collect about California residents (including the categories of personal information that we have collected about California residents in the past 12 months):</p>
        <p><br></p>
        <p>Identifiers : includes direct identifiers such as a real name, alias, address, unique personal identifier, online identifier, Internet Protocol (IP) address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers.</p>
        <p>Customer records : paper and electronic customer records containing personal information, which you provide to us in order to access or use our Services, such as name, digital signature, address, telephone number, email and other contact information, government identifiers, financial and payment information.</p>
        <p>Characteristics of protected classifications : such as disability and health information (e.g., which we may collect in order to, for example, make accommodations available to you at events we host).</p>
        <p>Commercial information : including records of real property, products or services purchased, obtained, or considered, or other purchasing or use histories or tendencies.</p>
        <p>Usage data : internet or other electronic network activity Information including without limitation browsing history, search history, and information regarding a consumer’s interaction with an Internet website, application, or advertisement.</p>
        <p>Location data : location information about a particular individual or device.</p>
        <p>Audio, Video and other Electronic Data : audio, electronic, visual, thermal, olfactory, or similar information such as, CCTV footage and thermal screenings (e.g., collected from visitors to our offices), photographs, and call recordings.</p>
        <p>Employment history: professional or employment-related information.</p>
        <p>Education information : education history and background.</p>
        <p>Profiles and inferences : Inferences drawn from any of the information identified above to create a profile reflecting a resident’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, or aptitudes.</p>
        <p>Sensitive personal information : in limited circumstances, we may collect certain information considered to be sensitive personal information under the CCPA, including Social Security number and tax ID; driver’s license, state identification card, passport number, or other government identifier; financial account number and payment card data; and geolocation data.</p>
        <p>Categories of third parties. Below we describe the third parties to whom we may disclose each category of personal information for a business or commercial purpose:</p>
        <p><br></p>
        <p class="f-w-500">Identifiers:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
          <li>Advertising networks</li>
          <li>Data analytics providers</li>
          <li>Social networks</li>
          <li>Operating systems and platforms</li>
        </ul>
  
        <p class="f-w-500">Customer records:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
          <li>Operating systems and platforms</li>
        </ul>
  
        <p class="f-w-500">Characteristics of protected classifications:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
        </ul>
  
        <p class="f-w-500">Commercial information:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
          <li>Data analytics providers</li>
          <li>Social networks</li>
          <li>Operating systems and platforms</li>
        </ul>
  
        <p class="f-w-500">Usage data:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
          <li>Advertising networks</li>
          <li>Data analytics providers</li>
          <li>Social networks</li>
          <li>Operating systems and platforms</li>
        </ul>
  
        <p class="f-w-500">Location data:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
          <li>Operating systems and platforms</li>
        </ul>
  
        <p class="f-w-500">Audio, video and other electronic data:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
        </ul>
  
        <p class="f-w-500">Employment History:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
        </ul>
  
        <p class="f-w-500">Education information:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
        </ul>
  
        <p class="f-w-500">Profiles and inferences:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
          <li>Advertising networks</li>
          <li>Data analytics providers</li>
          <li>Social networks</li>
        </ul>
  
        <p class="f-w-500">Sensitive personal information:</p>
        <ul>
          <li>Service providers</li>
          <li>Advisors and agents</li>
          <li>Government entities and law enforcement</li>
          <li>Affiliates and subsidiaries</li>
          <li>Real estate professionals</li>
        </ul>
  
        <p>In addition, we may disclose any of the categories of personal information we collect about you to other third parties as directed by or consented to by you, or where necessary to fulfill your specific requests.</p>
        <p><br></p>
        <p>Sales and sharing. The CCPA defines a “sale” as disclosing or making available personal information to a third-party in exchange for monetary or other valuable consideration, and “sharing” broadly includes disclosing or making available personal information to a third party for purposes of cross-context behavioral advertising. While we do not disclose personal information to third parties in exchange for monetary compensation, we may “sell” or “share” (as defined by the CCPA): (i) identifiers, usage data, customer records, commercial information and profiles with our affiliates and subsidiary companies (e.g., so that they may improve or enhance their own records and for other purposes); and (ii) identifiers, usage data and commercial information to ad networks, social media platforms and data analytics providers (e.g., in order to improve and measure our ad and marketing campaigns).</p>
        <p><br></p>
  
        <p>Sources of Personal Information. We may collect personal information from the following sources:</p>
        <ul>
          <li>Directly from the individual</li>
          <li>Real estate professionals, including brokers and independent agents of Anywhere brands</li>
          <li>Property records, public records and other publicly available sources</li>
          <li>Advertising networks</li>
          <li>Data analytics providers</li>
          <li>Social networks</li>
          <li>Government entities</li>
          <li>Data brokers</li>
          <li>Affiliate and subsidiary companies</li>
          <li>Service providers</li>
        </ul>
        <p>Purposes of Collection, Use and Disclosure. As described in more detail in Section 3. Use of Personal Information and Section 4. Disclosures of Personal Information, we collect, use, disclose and otherwise process personal information (including sensitive personal information) for the following business or commercial purposes or as otherwise directed or consented to by you:</p>
        <ul>
          <li>Providing support and services</li>
          <li>Analyzing and improving our business</li>
          <li>Personalizing content and experiences</li>
          <li>Advertising, marketing and promotional purposes</li>
          <li>Securing and protecting our business</li>
          <li>Defending our legal rights</li>
          <li>Planning and facilitating business transactions</li>
          <li>Auditing, reporting, corporate governance, and internal operations</li>
          <li>Complying with legal obligations</li>
        </ul>
  
        <p>Retention. We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy or otherwise disclosed to you at the time of collection unless a longer retention period is required or permitted by law. We may retain personal information for longer where required by our legal and regulatory obligations, professional indemnity obligations, or where we believe it is necessary to establish, defend or protect our legal rights and interests or those of others.</p>
        <p><br></p>
        <p>Rights of California Residents. In general, California residents have the following rights under the CCPA:</p>
        <p><br></p>
        <p>California residents’ rights. Under the CCPA, California residents have the following rights (subject to certain limitations) with respect to the personal information we collect and maintain about them:</p>
        <p><br></p>
        <p>Opt out of sales and sharing: to opt-out of our sale and sharing of their personal information.</p>
        <p>Limit uses and disclosures of sensitive personal information : to limit certain uses or disclosures of sensitive personal information to those uses authorized by the CCPA.</p>
        <p>Deletion: to request deletion of their personal information, subject to certain exceptions.</p>
        <p>To know/access : to know what personal information we have collected about them, including the categories of personal information, the categories of sources from which the personal information is collected, the business or commercial purpose for collecting, selling, or sharing personal information, the categories of third parties to whom we disclose personal information, and the specific pieces of personal information we have collected about them.</p>
        <p>Correction : to request correction of inaccurate personal information.</p>
        <p>Non-discrimination : not to be subject to discriminatory treatment for exercising their rights under the CCPA.</p>
        <p>Submitting CCPA Requests. California residents may exercise their CCPA privacy rights to know/access, delate, correct, and limit use/disclosure of sensitive personal information by submitting a verifiable request to us via:</p>
        <ul>
          <li>Our online webform</li>
          <li>Phone at (888) 778-6995 (toll free)</li>
        </ul>
        <p>We will respond to verifiable requests received from California residents as required by law. When you submit a request to know or a request to delete, we will take steps to verify your request by matching the information provided by you with the information we have in our records. You must complete all required fields on our webform (or otherwise provide us with this information via the above toll-free number) so that we can process and verify your request. We will process your request based upon the personal information in our records that is linked or reasonably linkable to the information provided in your request. In some cases, we may request additional information in order to verify your request or where necessary to process your request. If we are unable to adequately verify a request, we will notify the requestor. Authorized agents may initiate a request on behalf of another individual by contacting us via email or phone; authorized agents will be required to provide proof of their authorization and we may also require that the relevant consumer directly verify their identity and the authority of the authorized agent.</p>
        <p><br></p>
        <p>Requests to Opt Out. Our website responds to global privacy control—or “GPC”—signals, which means that if we detect that your browser is communicating a GPC signal, we will process that as a request to opt that particular browser and device out of sales and sharing (i.e., via cookies and tracking tools) on our website. Note that if you come back to our website from a different device or use a different browser on the same device, you will need to opt out (or set GPC for) that browser and device as well. (You can also manage your preferences for targeting and other cookies on one of our Websites through the “Cookie Settings” link in the footer of the Website.) More information about GPC is available at: https://globalprivacycontrol.org/. You may also submit a request to opt out of other sharing and sales by submitting a request via:</p>
        <ul>
          <li>Our online webform</li>
          <li>Phone at (888) 778-6995 (toll free)</li>
        </ul>
        <p>Financial Incentives. We may make available certain programs or offerings that are considered “financial incentives” under the CCPA, and we will provide notice of the material terms of such incentives and obtain consent from individuals before including them in such incentive programs. You can opt out of such programs at any time through the opt out mechanism provided in the program information.</p>
        <p><br></p>
        <p>Rights Under California Shine the Light Law. California residents may request from businesses with whom they have an established business relationship (1) a list of categories of personal information, such as name, address, e-mail address, and the type of services provided to the customer, that a business has disclosed to third parties during the immediately preceding calendar year for the third parties’ direct marketing purposes and (2) the names and addresses of all such third parties. To request the above information please contact us by writing to us under the “Contact Us” section above. We will respond to such requests within 30 days of receipt.</p>
        <p><br></p>
        <p>For more information about our privacy practices, contact us as set forth in the Section 16. above.</p>
        <p><br></p>
        <h6>C. Residents of Other U.S. States</h6>
        <p>Residents of certain U.S. states (including Virginia) may have additional rights under applicable privacy laws, subject to certain limitations. These rights may include:</p>
        <p><br></p>
        <p>Correction: to correct inaccuracies in their personal information, taking into account the nature and purposes of the processing of the personal information.</p>
        <p>Deletion : to delete their personal information provided to or obtained by us.</p>
        <p>Access: to confirm whether we are processing their personal information and to obtain a copy of their personal information in a portable and, to the extent technically feasible, readily usable format.</p>
        <p>Opt-Out: to opt out of certain types of processing, including:</p>
        <ul>
          <li>to opt out of the “sale” of their personal information.</li>
          <li>to opt out of targeted advertising by us.</li>
          <li>to opt out of any processing of personal information for purposes of making decisions that produce legal or similarly significant effects.</li>
        </ul>
        <p>You may submit a request to exercise your privacy rights via:</p>
        <ul>
          <li>Our online webform</li>
          <li>Phone at (888) 778-6995 (toll free)</li>
        </ul>
        <p>You may also opt out of targeted advertising via on one of our Websites, by clicking the “Cookie Settings” link on our website and turning off targeting (advertising) cookies. (See Section 12. Your Rights and Choices, for additional information about the privacy choices we provide and how to exercise them.) We will respond to your request as required under the applicable privacy law(s). If we deny your request, you may appeal our decision by submitting a request through our webform</p>
      </div>
  
      <!-- Español (con traductor online) -->
      <div v-if="$i18n.locale == 'es'">
        <h1 class="mb-2 page-title txt-black">Aviso de Privacidad</h1>
  
        <p>La política establecida a continuación se proporciona en inglés únicamente para evitar cualquier mala interpretación causada por una traducción de idioma. Si es necesario, busque el asesoramiento adecuado o no utilice este sitio web.</p>
  
         <p>Fecha de entrada en vigor: 1 de enero de 2023</p>
         <p><br></p>
         <p>Declaración de privacidad en línea de Sotheby's International Realty®</p>
         <p><br></p>
         <p>Sotheby's International Realty Affiliates LLC (“SIRA”) y nuestras compañías afiliadas y subsidiarias (como Anywhere Real Estate Inc., Guaranteed Rate Affinity, Anywhere Integrated Services, Anywhere Advisors, Realogy Insurance Agency y Anywhere Leads Inc.) ( colectivamente, “SIRA”, “nosotros”, “nuestro” o “nos”) estamos comprometidos con la privacidad y la transparencia en nuestras prácticas de información. Esta Política de Privacidad describe cómo recopilamos, usamos, divulgamos y procesamos de otro modo la información personal descrita en esta Política de Privacidad, así como los derechos y opciones que tienen las personas con respecto a dicha información personal. Esta Política de Privacidad se aplica en la medida en que procesamos datos personales en nuestro propio nombre, como controlador o empresa.</p>
         <p><br></p>
         <p>Si es residente de California, asegúrese de revisar la Sección 17B. Residentes de California para obtener información importante sobre las categorías de información personal que recopilamos y divulgamos, así como sus derechos según las leyes de privacidad de California.</p>
         <p><br></p>
         <!-- <p>Puede hacer clic en los enlaces a continuación para ir directamente a una sección particular de nuestra Política de Privacidad.</p>
         <p><br></p> -->
         <ol>
           <li><a class="no-cursor">ALCANCE DE ESTA POLÍTICA DE PRIVACIDAD</a></li>
           <li><a class="no-cursor">NUESTRA RECOPILACIÓN DE INFORMACIÓN PERSONAL</a></li>
           <li><a class="no-cursor">FINALES Y BASE LEGAL DE NUESTRO TRATAMIENTO</a></li>
           <li><a class="no-cursor">DIVULGACIÓN DE INFORMACIÓN PERSONAL</a></li>
           <li><a class="no-cursor">DATOS AGREGADOS Y NO IDENTIFICABLES</a></li>
           <li><a class="no-cursor">COOKIES, SEGUIMIENTO Y ANÁLISIS</a></li>
           <li><a class="no-cursor">PUBLICIDAD BASADA EN INTERESES</a></li>
           <li><a class="no-cursor">CARACTERÍSTICAS SOCIALES Y PLUG INS DE TERCEROS</a></li>
           <li><a class="no-cursor">TRANSFERENCIAS INTERNACIONALES DE INFORMACIÓN PERSONAL</a></li>
           <li><a class="no-cursor">SEGURIDAD</a></li>
           <li><a class="no-cursor">RETENCIÓN DE DATOS</a></li>
           <li><a class="no-cursor">SUS DERECHOS Y OPCIONES</a></li>
           <li><a class="no-cursor">NIÑOS</a></li>
           <li><a class="no-cursor">SITIOS WEB DE TERCEROS</a></li>
           <li><a class="no-cursor">CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD</a></li>
           <li><a class="no-cursor">CONTÁCTENOS</a></li>
           <li><a class="no-cursor">INFORMACIÓN ADICIONAL PARA DETERMINADAS JURISDICCIONES</a>
             <ul>
               <li><a class="no-cursor">EEE y Reino Unido</a></li>
               <li><a class="no-cursor">Residentes de California</a></li>
               <li><a class="no-cursor">Residentes de otros estados de EE. UU.</a></li>
             </ul>
           </li>
         </ol>
         <h5>1. ALCANCE DE ESTA POLÍTICA DE PRIVACIDAD</h5>
         <p>Esta Política de Privacidad se aplica a nuestra recopilación, uso, divulgación y otro procesamiento de información personal relacionada con:</p>
         <p><br></p>
         <p>Nuestros sitios web (cada uno, un "Sitio web") y aplicaciones móviles (cada uno, una "Aplicación") que muestran o vinculan a esta Política de privacidad y nuestros otros servicios, productos y soluciones tecnológicas (colectivamente, los "Servicios") .</p>
         <p>Clientes, corredores, agentes independientes y clientes anteriores, actuales y potenciales.</p>
         <p>Otras personas que utilizan nuestros Servicios, cuya información personal recibimos en relación con los Servicios, o que de otro modo interactúan o se comunican con nosotros en relación con nuestros Servicios o nuestro negocio.</p>
         <p>Nuestra recopilación, uso, divulgación y procesamiento de información personal sobre individuos variará según las circunstancias. Esta Política de Privacidad tiene como objetivo describir nuestras prácticas generales de privacidad y protección de datos.</p>
         <p><br></p>
         <p>No cubierto por esta Política de Privacidad. Esta Política de Privacidad no se aplica a nuestros empleados y trabajadores no empleados.</p>
         <p><br></p>
         <p>Avisos de privacidad adicionales. En algunos casos, es posible que se proporcionen avisos diferentes o adicionales sobre nuestras prácticas de recopilación y procesamiento de datos y se apliquen a nuestro procesamiento de cierta información personal. El aviso adicional prevalecerá en la medida en que exista un conflicto con esta Política de Privacidad, con respecto a su información personal que esté sujeta a ese aviso.</p>
         <p><br></p>
  
         <h5>2. NUESTRA RECOPILACIÓN DE INFORMACIÓN PERSONAL</h5>
         <p>Generalmente, recopilamos su información personal de forma voluntaria. Sin embargo, si se niega a proporcionar cierta información personal marcada como obligatoria, es posible que no pueda acceder a ciertos Servicios o que no podamos responder completamente a su consulta.</p>
         <p><br></p>
         <p>Podemos recopilar información personal directamente de individuos, automáticamente relacionada con el uso de los Servicios y, en algunos casos, de terceros (como redes sociales, proveedores de plataformas, procesadores de pagos, proveedores de datos y operadores de ciertos terceros). servicios de terceros que utilizamos). En algunos casos (como cuando lo exige la ley), le solicitamos su consentimiento o le damos ciertas opciones antes de recopilar o utilizar cierta información personal.</p>
         <p><br></p>
         <p>Información que recopilamos directamente. Recopilamos la información que usted nos proporciona, como por ejemplo:</p>
         <p><br></p>
         <p>Cuando crea una cuenta, proporciona información de contacto o nos envía otra información personal.</p>
         <p>Cuando completa o envía un formulario a través de nuestros Servicios, por ejemplo, los formularios "Contáctenos" o "Más información".</p>
         <p>Cuando participa en encuestas o concursos, envía pedidos o solicitudes, se registra o utiliza nuestros Servicios, o se comunica con nosotros de otro modo.</p>
         <p>Información que usted envía o proporciona, como búsquedas y preferencias de bienes raíces, si planea comprar o vender bienes raíces, criterios de búsqueda de viviendas, información sobre financiamiento para la compra de bienes raíces y otra información relacionada con la compra o venta de bienes raíces.</p>
         <p>Información que recopilamos de terceros. Podemos recopilar o recibir información personal sobre usted y su propiedad de fuentes de terceros, como bases de datos públicas, socios de marketing conjunto, plataformas de redes sociales (incluso de personas con las que es amigo o está conectado de otro modo), corredores y agentes afiliados, y otros. profesionales inmobiliarios y terceros. Por ejemplo:</p>
         <p><br></p>
         <p>Redes sociales. Si elige vincular, crear o iniciar sesión en su cuenta de Servicios con un servicio de redes sociales (por ejemplo, Twitter, Facebook, Instagram, etc.), o si interactúa con una aplicación o sitio web independiente que se comunica con los Servicios, nosotros puede recibir información personal sobre usted o sus conexiones de ese servicio. En muchos casos, puedes controlar qué información personal compartes con nosotros a través de la configuración de privacidad disponible en esos servicios de redes sociales de terceros.</p>
         <p>Profesionales inmobiliarios. Si interactúa o publica una propiedad con un corredor, agente independiente u otro profesional de bienes raíces asociado con una marca Anywhere, podemos recibir información personal sobre usted y su propiedad de ellos, como su nombre y dirección de la propiedad, listado e información de contacto. , entre otras cosas. Estos profesionales de bienes raíces pueden estar afiliados a corredurías operadas y de propiedad independiente, o a una empresa Anywhere.</p>
         <p>Registros de propiedad. Podemos recopilar información sobre personas y bienes raíces a través de bases de datos públicas, como registros de propiedad e impuestos mantenidos por agencias estatales, locales y del condado.</p>
         <p><br></p>
  
         <h5>3. FINALIDADES Y BASE JURÍDICA DE NUESTRO TRATAMIENTO</h5>
         <p>Ciertas leyes, incluido el Reglamento General de Protección de Datos de la UE ("GDPR"), las leyes de protección de datos del Reino Unido y Brasil, y leyes similares, exigen que informemos a las personas correspondientes sobre la base legal para nuestro uso y otro procesamiento de su información personal. En esta sección, describimos los propósitos para los cuales recopilaremos, usaremos, divulgaremos y procesaremos información personal, así como las bases legales para dicho procesamiento, en general, según dichas leyes de privacidad.</p>
         <p><br></p>
         <p>Bases legales bajo ciertas leyes de privacidad. De conformidad con el RGPD, las leyes de protección de datos del Reino Unido y Brasil, y leyes similares, recopilamos, utilizamos y procesamos información personal para las siguientes bases legales:</p>
         <p><br></p>
         <p>Cumplimiento del contrato: según sea necesario para celebrar o llevar a cabo el cumplimiento de nuestro contrato con usted.</p>
         <p>Cumplimiento de leyes: para el cumplimiento de obligaciones legales y/o defensa contra reclamaciones legales, incluidas aquellas en el área de derecho laboral, seguridad social, protección de datos, impuestos y cumplimiento corporativo.</p>
         <p>Nuestros intereses legítimos: para promover nuestros intereses comerciales legítimos, incluidos, por ejemplo:</p>
         <p>Cumplimiento de contratos con franquiciados y otras partes</p>
         <p>Implementación y operación de servicios de soporte global (por ejemplo, TI) para nuestras operaciones comerciales</p>
         <p>Gestión de relaciones con los clientes y mejora de nuestros Servicios, incluido marketing y análisis</p>
         <p>Marketing y publicidad</p>
         <p>Prevención del fraude, incluido el uso indebido de los sistemas informáticos de la empresa o el blanqueo de dinero</p>
         <p>Seguridad perimetral física, informática y de red</p>
         <p>Investigaciones internas</p>
         <p>Fusiones, adquisiciones y reorganizaciones, y otras transacciones comerciales</p>
         <p>Con su consentimiento: en algunos casos, nos basamos en su consentimiento para recopilar y procesar su información personal. (Consulte la Sección 17A a continuación para obtener información sobre cómo retirar su consentimiento).</p>
         <p>Además, podemos procesar su información personal cuando sea necesario para proteger los intereses vitales de cualquier individuo.</p>
         <p><br></p>
         <p>Finalidades del tratamiento. Si bien los fines para los cuales podemos procesar información personal variarán según las circunstancias, en general recopilamos, usamos, divulgamos y procesamos información personal para los fines establecidos a continuación, así como la base legal para dicho procesamiento, según corresponda según leyes relevantes (ver arriba para mayor explicación de estas bases legales):</p>
         <p><br></p>
         <p>Proporcionar soporte y servicios: incluyendo, por ejemplo, enviar o facilitar comunicaciones entre usted, profesionales inmobiliarios independientes y nuestras entidades afiliadas, y otros usuarios de los Servicios, proporcionar productos y servicios que usted solicite (y enviar información relacionada), operar nuestros Servicios; para comunicarnos con usted sobre su acceso y uso de nuestros Servicios; para responder a sus consultas; para brindar solución de problemas, cumplir con sus solicitudes y brindar soporte técnico; y para otros fines de soporte y servicio al cliente. (Base jurídica: ejecución de nuestro contrato con usted; y nuestros intereses legítimos)</p>
         <p>Analizar y mejorar nuestro negocio: incluso comprender mejor cómo los usuarios acceden y utilizan nuestros Servicios, evaluar y mejorar nuestros Servicios y operaciones comerciales, y desarrollar nuevas características, ofertas y servicios; realizar encuestas y otras evaluaciones, como encuestas de satisfacción del cliente; y para otros fines analíticos y de investigación. (Base jurídica: nuestros intereses legítimos)</p>
         <p>Personalizar contenido y experiencias: incluso proporcionar o recomendar funciones, contenido, conexiones sociales y referencias; personalizar el contenido que enviamos o mostramos en nuestros Servicios; ofrecer personalización de ubicación y ayuda e instrucciones personalizadas; y personalizar sus experiencias de otro modo. (Base legal: nuestros intereses legítimos y/o con su consentimiento)</p>
         <p>Fines publicitarios, de marketing y promocionales: incluido llegar a usted con anuncios más relevantes y evaluar, medir y mejorar la eficacia de nuestras campañas publicitarias; para enviarle boletines informativos, ofertas u otra información que creemos que puede interesarle; para comunicarnos con usted sobre nuestros Servicios o información que creemos que puede interesarle; y administrar promociones y concursos. (Base legal: nuestros intereses legítimos y/o con su consentimiento)</p>
         <p>Asegurar y proteger nuestro negocio: incluso para proteger y asegurar nuestras operaciones comerciales, activos, Servicios, redes y recursos de información y tecnología; investigar, prevenir, detectar y tomar medidas respecto de fraudes, accesos no autorizados, situaciones que impliquen amenazas potenciales a los derechos o la seguridad de cualquier persona o de terceros, u otras actividades o malas conductas no autorizadas. (Base legal: nuestros intereses legítimos y/o cumplimiento de las leyes)</p>
         <p>Defender nuestros derechos legales: incluso para gestionar y responder a disputas y reclamaciones legales reales y potenciales, y para establecer, defender o proteger de otro modo nuestros derechos o intereses, incluso en el contexto de litigios previstos o reales con terceros. (Base legal: nuestros intereses legítimos y/o cumplimiento de las leyes)</p>
         <p>Planificación y facilitación de transacciones comerciales: relacionadas con cualquier fusión, adquisición, venta o transferencia de activos, financiación, quiebra o reestructuración real o prevista de todo o parte de nuestro negocio. (Base legal: nuestros intereses legítimos y/o cumplimiento de las leyes)</p>
         <p>Auditoría, presentación de informes, gobierno corporativo y operaciones internas: incluidas las relacionadas con auditorías financieras, fiscales y contables; auditorías y evaluaciones de nuestras operaciones, privacidad, seguridad y controles financieros, riesgos y cumplimiento de obligaciones legales; y nuestras funciones comerciales, contables, de mantenimiento de registros y legales en general. (Base legal: nuestros intereses legítimos y/o cumplimiento de las leyes)</p>
         <p>Cumplir con obligaciones legales: incluido el cumplimiento de la ley, nuestras obligaciones legales y procesos legales, tales como órdenes judiciales, citaciones, órdenes judiciales y solicitudes regulatorias o de aplicación de la ley. (Base legal: nuestros intereses legítimos y/o cumplimiento de las leyes)</p>
         <p><br></p>
         <h5>4. DIVULGACIÓN DE INFORMACIÓN PERSONAL</h5>
         <p>Podemos divulgar la información personal que recopilamos a terceros, según sea razonablemente necesario para los fines descritos anteriormente y según lo indique o consienta usted, incluyendo:</p>
         <p><br></p>
         <p>Proveedores de servicios a proveedores de servicios externos que utilizan esta información para prestarnos servicios, como proveedores de hosting, auditores, asesores, consultores y proveedores de soporte y servicio al cliente.</p>
         <p>Subsidiarias y afiliadas: a nuestras compañías subsidiarias y afiliadas (es decir, compañías bajo propiedad, control o administración común con nosotros), como Anywhere Real Estate Inc., Guaranteed Rate Affinity, Anywhere Integrated Services, Anywhere Advisors, Realogy Insurance Agency y Anywhere Leads Inc., quienes pueden procesar su información personal de acuerdo con los principios de esta Política de Privacidad, incluso para compartir noticias, información y ofertas relevantes con usted. Cuando lo exija la ley aplicable, obtendremos su consentimiento para dicha divulgación.</p>
         <p>Terceros: a terceros con el fin de proporcionar nuestros Servicios, responder o llevar a cabo sus solicitudes, y/o con su consentimiento, incluyendo a:</p>
         <p>Profesionales inmobiliarios independientes y propietarios y operadores de empresas de corretaje de bienes raíces para permitirles brindar los servicios que usted solicita a través de los Servicios.</p>
         <p>Terceros para proporcionarle un servicio que usted solicitó a través de una asociación u oferta promocional realizada por un tercero o por nosotros.</p>
         <p>El público si envía una parte pública de los Servicios, como comentarios, publicaciones en redes sociales u otras funciones visibles para el público o, en general, para usuarios registrados de los Servicios.</p>
         <p>Terceros con quienes usted elige permitirnos compartir información, por ejemplo, otras aplicaciones o sitios web que se integran con los Servicios, o aquellos con Servicios con los que nos integramos.</p>
         <p>Terceros con los que trabajamos para ofrecerle contenido y anuncios más personalizados y realizar un seguimiento de la eficacia de las campañas.</p>
         <p>Transferencias comerciales: como parte de cualquier fusión, venta y transferencia real o prevista de nuestros activos, adquisición, financiamiento o reestructuración de todo o parte de nuestro negocio, quiebra o evento similar; y antes de completar dicha transferencia, cuando sea necesario para la debida diligencia o para planificar la transferencia, como a prestamistas, auditores y asesores externos, tales como abogados y consultores, cuando lo permita la ley.</p>
         <p>Requerido legalmente: cuando estamos obligados a hacerlo por ley o proceso legal (por ejemplo, ante las autoridades, tribunales u otros, o en respuesta a una citación u orden judicial).</p>
         <p>Proteger nuestros derechos: cuando creamos que es necesario responder a reclamos presentados contra nosotros o cumplir con procesos legales (por ejemplo, citaciones o órdenes judiciales), hacer cumplir o administrar nuestros acuerdos y términos, para la prevención de fraude, evaluación de riesgos, investigación, y para proteger los derechos, la propiedad o la seguridad de nosotros, nuestros clientes y clientes u otros. incluida información personal, relacionada con litigios y otros reclamos o procedimientos legales en los que estamos involucrados, así como para nuestras funciones internas de contabilidad, auditoría, cumplimiento, mantenimiento de registros y legales.</p>
         <p><br></p>
         <h5>5. DATOS AGREGADOS Y NO IDENTIFICABLES</h5>
         <p>También podemos usar y divulgar datos agregados, anónimos y otros datos no identificables relacionados con nuestro negocio y los Servicios para control de calidad, análisis, investigación, desarrollo y otros fines. Cuando usemos, divulguemos o procesemos datos no identificados (datos que ya no están razonablemente vinculados o vinculables a una persona física, hogar o dispositivo personal o doméstico identificado o identificable), mantendremos y usaremos la información en forma no identificada y no para Intentar reidentificar la información, excepto según lo permitan las leyes de privacidad aplicables (como para confirmar si nuestros procesos de desidentificación son razonables y adecuados).</p>
         <p><br></p>
         <h5>6. COOKIES, SEGUIMIENTO Y ANÁLISIS</h5>
         <p>Nuestros Servicios pueden utilizar cookies, etiquetas de píxeles, complementos y otras herramientas propias y de terceros para recopilar información sobre el dispositivo, el uso y la navegación cuando los usuarios visitan nuestros Servicios. Por ejemplo, cuando visita nuestros Servicios, nuestro servidor puede registrar su dirección IP (e información de ubicación asociada) y otra información como el tipo de navegador de Internet, su dirección de control de acceso a medios (MAC), tipo de computadora (Windows o Macintosh). , resolución de pantalla, nombre y versión del sistema operativo, fabricante y modelo del dispositivo, idioma y las páginas que ve y los enlaces que selecciona en los Servicios, así como las marcas de fecha y hora asociadas con su uso y actividades en nuestros Servicios.</ p>
         <p><br></p>
         <p>Utilizamos la información con fines de seguridad, para facilitar la navegación, para mejorar, analizar y optimizar nuestros Servicios y su funcionalidad, para personalizar y mejorar su experiencia mientras utiliza los Servicios, para mejorar y medir nuestras campañas publicitarias y para alcanzar mejor usuarios con publicidad relevante tanto en nuestros Servicios como en sitios web de terceros.</p>
         <p><br></p>
         <p>Haga clic en el enlace "Preferencias de cookies" en el pie de página de nuestro sitio web para obtener más información sobre las cookies en ese sitio web y para revisar y actualizar sus preferencias para la mayoría de los tipos de cookies (aparte de las que son necesarias para la funcionalidad de ese sitio web). .</p>
         <p><br></p>
         <p>Galletas. Las cookies son pequeños archivos de texto que un sitio web transfiere a su disco duro para almacenar y, a veces, recopilar información sobre su uso de los sitios web, como el tiempo que pasa en los sitios web, las páginas visitadas, las preferencias de idioma y otros datos de tráfico anónimos. Puede controlar la forma en que se utilizan las cookies modificando la configuración de su navegador. Puede negarse a aceptar cookies activando la configuración en su navegador que le permite rechazar las cookies. Sin embargo, si selecciona dicha configuración, esto puede afectar el funcionamiento de nuestros Servicios. A menos que haya ajustado la configuración de su navegador para que rechace las cookies, nuestro sistema emitirá cookies cuando acceda o inicie sesión en nuestro Servicio.</p>
         <p><br></p>
         <p>Si ha desactivado una o más cookies, aún podemos utilizar la información recopilada de las cookies antes de que se estableciera su preferencia de desactivación. Sin embargo, dejaremos de utilizar la cookie deshabilitada para recopilar más información.</p>
         <p><br></p>
         <p>Etiquetas de píxeles y otras tecnologías similares. Las etiquetas de píxeles (también conocidas como balizas web y GIF transparentes) se pueden utilizar en relación con algunos sitios web para, entre otras cosas, rastrear las acciones de los usuarios de los sitios web (incluidos los destinatarios de correo electrónico), medir el éxito de nuestras campañas de marketing y compilar estadísticas. sobre el uso de los sitios web y las tasas de respuesta. Nosotros y nuestros proveedores de servicios también podemos usar etiquetas de píxeles en los correos electrónicos HTML enviados a nuestros clientes, para ayudarnos a realizar un seguimiento de las tasas de respuesta de los correos electrónicos, identificar cuándo se ven nuestros correos electrónicos, realizar un seguimiento de si nuestros correos electrónicos se reenvían y realizar análisis.</p>
         <p><br></p>
         <p>Archivos de registro. La mayoría de los navegadores recopilan cierta información, como su dirección IP, tipo de dispositivo, resolución de pantalla, versión del sistema operativo y tipo y versión del navegador de Internet. Esta información se recopila automáticamente y se almacena en archivos de registro.</p>
         <p><br></p>
         <p>Herramientas de análisis de terceros. Nuestros sitios web utilizan dispositivos y aplicaciones automatizados operados por terceros, como Google Analytics, que utiliza cookies y tecnologías similares para recopilar y analizar información sobre el uso de los sitios web e informar sobre actividades y tendencias. Este servicio también puede recopilar información sobre el uso de otros sitios web, aplicaciones y recursos en línea. Puede conocer las prácticas de Google y cancelar la recopilación de sus datos de navegación por parte de Google Analytics en https://tools.google.com/dlpage/gaoptout.</p>
         <p><br></p>
         <p>Señales del navegador. Tenga en cuenta que nuestros sitios web no reconocen ni responden a las solicitudes de “no seguimiento” del navegador ni a otras señales de privacidad del navegador. Sin embargo, puede revisar y actualizar sus preferencias de cookies en nuestros sitios web como se explicó anteriormente.</p>
         <p><br></p>
         <h5>7. PUBLICIDAD BASADA EN INTERESES</h5>
         <p>En algunos de nuestros sitios web, podemos trabajar con redes publicitarias de terceros, empresas de análisis, servicios de medición y otros ("empresas publicitarias de terceros") para mostrar publicidad en nuestros Servicios y gestionar nuestra publicidad en sitios web de terceros. sitios, aplicaciones móviles y servicios en línea. Nosotros y estas empresas publicitarias de terceros podemos utilizar cookies, etiquetas de píxeles y otras herramientas para recopilar información en nuestros sitios web (y en sitios y servicios de terceros), como el historial de navegación, la dirección IP, la identificación del dispositivo, las cookies y las identificaciones de publicidad. y otros identificadores, información general de ubicación y, con su consentimiento, información de geolocalización de su dispositivo; Nosotros y estas empresas publicitarias de terceros utilizamos esta información para ofrecerle anuncios y contenido más relevantes y para evaluar el éxito de dichos anuncios y contenidos.</p>
         <p><br></p>
         <p>Puede revisar y administrar sus preferencias para las cookies de orientación y obtener información sobre las cookies de orientación que se utilizan en uno de nuestros sitios web haciendo clic en "Configuración de cookies" en el pie de página de nuestro sitio web.</p>
         <p><br></p>
         <p>También puede obtener más información sobre publicidad dirigida o "basada en intereses" y darse de baja de muchas redes publicitarias en los sitios web de la industria a continuación:</p>
         <p><br></p>
         <p>Canadá: youradchoices.ca</p>
         <p>UE: youronlinechoices.eu</p>
         <p>EE.UU.: youradchoices.com</p>
         <p><br></p>
         <h5>8. FUNCIONES SOCIALES Y ENCHUFES DE TERCEROS</h5>
         <p>Los Servicios pueden integrarse con funciones para compartir en redes sociales y otras herramientas relacionadas que le permiten iniciar sesión en los Servicios o compartir las acciones que realiza en los Servicios. El uso de dichas funciones permite compartir información con sus amigos o el público, dependiendo de la configuración que establezca con el servicio de intercambio social. Consulte las políticas de privacidad de esos servicios de intercambio social para obtener más información sobre cómo manejan los datos que usted les proporciona o comparte a través de ellos.</p>
         <p><br></p>
         <h5>9. TRANSFERENCIAS INTERNACIONALES DE INFORMACIÓN PERSONAL</h5>
         <p>SIRA tiene su sede en los Estados Unidos y tiene afiliados y proveedores de servicios en múltiples jurisdicciones. Como tal, su información personal puede transferirse a los Estados Unidos y otras jurisdicciones donde nosotros o nuestros proveedores de servicios tenemos operaciones, y puede estar sujeta a divulgación a los gobiernos, tribunales o agencias reguladoras o de cumplimiento de la ley de dichas jurisdicciones al momento de la transferencia. Es posible que algunas de estas jurisdicciones, incluido Estados Unidos, no proporcionen el mismo nivel de protección de datos que su país de origen. En tales casos, confiamos en medidas de seguridad adecuadas para la transferencia de información personal. Puede solicitar una copia o información sobre dichas salvaguardas comunicándose con nosotros como se detalla a continuación.</p>
         <p><br></p>
         <h5>10. SEGURIDAD</h5>
         <p>Utilizamos controles técnicos, administrativos y físicos establecidos para ayudar a proteger la información personal del acceso, uso y divulgación no autorizados. Aun así, a pesar de nuestros esfuerzos, ninguna medida de seguridad es perfecta o impenetrable. En caso de que la seguridad de su cuenta se haya visto comprometida, notifíquenos inmediatamente de acuerdo con la Sección 16. Contáctenos.</p>
         <p><br></p>
         <h5>11. CONSERVACIÓN DE DATOS</h5>
         <p>Conservaremos su información personal durante el período necesario para cumplir con los propósitos descritos en esta Política de Privacidad, a menos que la ley requiera o permita un período de retención más largo. Podemos conservar información personal durante más tiempo cuando así lo requieran nuestras obligaciones legales y reglamentarias, obligaciones de indemnización profesional o cuando creamos que es necesario para establecer, defender o proteger nuestros derechos e intereses legales o los de otros. Con respecto a los datos y archivos que manejamos como procesador, conservamos esta información personal de acuerdo con las instrucciones de nuestros clientes.</p>
         <p><br></p>
         <h5>12. TUS DERECHOS Y ELECCIONES</h5>
         <p>Le ofrecemos varias formas de ejercer ciertas opciones de privacidad con respecto a su información personal procesada por nosotros, como se describe en esta sección. Estamos comprometidos a respetar los derechos de privacidad de las personas según todas las leyes de privacidad que nos aplican. Algunas leyes de privacidad requieren que proporcionemos información específica sobre los derechos individuales a los consumidores correspondientes, lo cual hemos establecido en la Sección 17 de esta Política de Privacidad. Residentes de California, revisen la Sección 17B. Residentes de California para obtener información sobre sus derechos de privacidad en California.</p>
         <p><br></p>
         <p>Mercadotecnia. Puede optar por no recibir comunicaciones nuestras relacionadas con marketing en el futuro comunicándose con nosotros o utilizando el mecanismo de cancelación de suscripción contenido en cada correo electrónico. Intentaremos cumplir con su(s) solicitud(es) tan pronto como sea razonablemente posible. Tenga en cuenta que si opta por no recibir nuestros correos electrónicos relacionados con marketing, es posible que aún le enviemos mensajes administrativos importantes, de los cuales no puede optar por no recibirlos.</p>
         <p><br></p>
         <p>Preferencias de cookies. Para revisar y actualizar sus preferencias para la mayoría de los tipos de cookies (distintas de las necesarias) para uno de nuestros sitios web, haga clic en el enlace "Preferencias de cookies" en el pie de página de ese sitio web.</p>
         <p><br></p>
         <p>Desactivación. Puede desactivar su cuenta en cualquier momento contactándonos a la dirección de correo electrónico que se encuentra al final de esta página. Sin embargo, no podrá acceder a muchos de los servicios para desactivar su cuenta. Tenga en cuenta que incluso si solicita la desactivación de su cuenta, puede llevar algún tiempo cumplir con esta solicitud.</p>
         <p><br></p>
         <p>Acceso, modificación y supresión. Según las leyes de privacidad aplicables, es posible que tenga derecho a solicitar revisar, realizar modificaciones, eliminar o ejercer sus derechos sobre la información personal que tenemos, sujeto a ciertas limitaciones y requisitos legales. Si está sujeto a dicha ley de privacidad, puede enviarnos una solicitud relacionada con su información personal:</p>
         <p><br></p>
         <p>Enviándonos una solicitud por correo electrónico a dataprivacy@anywhere.re</p>
         <p>Contactando con nosotros al (888) 778-6995 (llamada gratuita)</p>
         <p>Procesaremos de acuerdo con y cuando lo exija la ley aplicable, y con respecto a su información personal que esté vinculada o pueda vincularse al correo electrónico que utiliza para enviarnos su solicitud y la información verificable proporcionada por usted como parte de tu petición. Tomaremos medidas para verificar su identidad antes de implementar su solicitud.</p>
         <p><br></p>
         <p>Tenga en cuenta que es posible que necesitemos conservar cierta información para fines de mantenimiento de registros y/o para completar cualquier transacción que haya iniciado antes de solicitar un cambio o eliminación. También puede haber información residual que permanecerá dentro de nuestras bases de datos y otros registros, que no será eliminada. Es posible que no siempre podamos atender completamente su solicitud, por ejemplo, si esto afectaría el deber de confidencialidad que debemos a otros, o si tenemos el derecho legal de manejar la solicitud de una manera diferente.</p>
         <p><br></p>
         <h5>13. NIÑOS</h5>
         <p>Los Servicios no están destinados ni dirigidos a niños menores de 16 años, y no recopilamos conscientemente ninguna información personal ni rastreamos conscientemente el uso de nuestros Servicios de los niños. Si tenemos conocimiento real de que se ha recopilado información personal sobre un niño menor de 16 años, tomaremos las medidas adecuadas para intentar eliminar dicha información personal.</p>
         <p><br></p>
         <h5>14. SITIOS WEB DE TERCEROS</h5>
         <p>Los Servicios pueden contener enlaces a servicios de terceros. No somos propietarios, operamos ni controlamos los sitios web de terceros, incluidos aquellos de franquiciados operados y de propiedad independiente. En consecuencia, esta Política de Privacidad no se aplica a ningún servicio mantenido u operado por terceros. Cuando haga clic en esos enlaces, irá a un sitio web de terceros donde estará sujeto a la política de privacidad de ese servicio o a una declaración y términos de uso similares, y le recomendamos que lea esa declaración de política. No somos responsables de las prácticas de privacidad de otros servicios y renunciamos expresamente a cualquier responsabilidad por sus acciones, incluidas las acciones relacionadas con el uso y la divulgación de información personal por parte de dichos terceros.</p>
         <p><br></p>
         <h5>15. CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD</h5>
         <p>Podemos modificar esta Política de Privacidad en cualquier momento. Si realizamos algún cambio sustancial en la forma en que recopilamos, usamos, divulgamos o procesamos información personal, publicaremos de manera destacada un aviso sobre dicho cambio en los Servicios. Cualquier cambio sustancial a esta Política de Privacidad entrará en vigencia 10 días después de nuestra publicación de la Política de Privacidad actualizada o según lo exija la ley aplicable. Cuando así lo exija la ley, podremos solicitar su consentimiento previo para cualquier cambio importante que realicemos en esta Política de Privacidad.</p>
         <p><br></p>
         <h5>16. CONTÁCTENOS</h5>
         <p>Si tiene alguna pregunta o inquietud sobre este Aviso, utilice la siguiente información de contacto:</p>
         <p><br></p>
         <p>privacidad de datos@anywhere.re</p>
         <p>Dirección postal: 175 Park Ave., Madison, NJ 07940 A la atención de: Privacidad de datos</p>
         <p>Número de teléfono: (888) 778-6995</p>
         <p><br></p>
  
         <h5>17. INFORMACIÓN ADICIONAL PARA DETERMINADAS JURISDICCIONES</h5>
         <p>Si es residente del EEE o del Reino Unido, consulte la Sección 17.A a continuación para obtener información adicional sobre sus derechos de privacidad según las leyes de privacidad aplicables.</p>
         <p><br></p>
         <p>Si es residente de los Estados Unidos, revise la Sección 17.B a continuación para obtener información adicional sobre las categorías de información personal que recopilamos sobre usted y sus derechos de privacidad según las leyes de privacidad de California aplicables y la Sección 17.C a continuación para obtener información adicional. información sobre los derechos de privacidad según otras leyes de privacidad estatales de EE. UU.</p>
         <p><br></p>
         <h6>A. EEE y Reino Unido</h6>
         <p>El RGPD y las leyes de protección de datos del Reino Unido (cuando corresponda) otorgan a los interesados los siguientes derechos con respecto a su información personal:</p>
         <p><br></p>
         <p>Derecho de acceso: Tiene derecho a obtener de nosotros confirmación sobre si se están procesando o no datos personales que le conciernen y, en tal caso, a solicitar acceso a dichos datos. La información a la que se accede incluye, entre otros, los fines del procesamiento, las categorías de información personal en cuestión y los destinatarios o categorías de destinatarios a quienes la información personal ha sido o será revelada. Tiene derecho a obtener una copia de la información personal objeto de procesamiento. Para copias adicionales que usted solicite, podemos cobrar una tarifa razonable basada en los costos administrativos.</p>
         <p>Derecho a rectificar y completar los datos personales: podrá solicitar la rectificación de los datos inexactos y la finalización de los datos incompletos. Informaremos a los terceros pertinentes a quienes hayamos transferido sus datos sobre la rectificación y finalización si estamos legalmente obligados a hacerlo.</p>
         <p>Derecho de eliminación (derecho al olvido): tiene derecho a obtener de nosotros la eliminación de su información personal en circunstancias limitadas donde:</p>
         <p>ya no es necesario para los fines para los que fue recopilado;</p>
         <p>ha retirado su consentimiento (cuando el procesamiento de datos se basó en el consentimiento);</p>
         <p>después de un derecho de oposición exitoso;</p>
         <p>ha sido procesado ilegalmente; o</p>
         <p>los datos tienen que ser suprimidos para cumplir con una obligación legal a la que estamos sujetos.</p>
         <p>No estamos obligados a cumplir con su solicitud de borrar información personal si el procesamiento de su información personal es necesario para el cumplimiento de una obligación legal, o el establecimiento, ejercicio o defensa de reclamos legales.</p>
         <p><br></p>
         <p>Derecho a la restricción del procesamiento: Usted tiene derecho a obtener de nuestra parte la restricción del procesamiento de su información personal. En este caso, los datos respectivos serán marcados y solo serán procesados por nosotros para determinados fines. Este derecho sólo puede ejercerse, sujeto a ciertas limitaciones, cuando: (i) se impugna la exactitud de su información personal; (ii) el procesamiento es ilegal, pero usted no desea que se borre la información personal; (iii) ya no es necesario para los fines para los que fue recopilado, pero aún lo necesita para establecer, ejercer o defender reclamos legales; o (iv) ha ejercido el derecho de oposición y está pendiente de verificación de motivos imperiosos.</p>
         <p>Derecho a la portabilidad de los datos: tiene derecho a recibir la información personal que le concierne, que nos ha proporcionado, en un formato estructurado, de uso común y legible por máquina y tiene derecho a transmitir esos datos a otra entidad. sin obstáculos por nuestra parte, pero en cada caso solo cuando el procesamiento (a) se base en su consentimiento o en la ejecución de un contrato con usted, y (b) también se lleve a cabo por medios automatizados.</p>
         <p>Derecho a oponerse: tiene derecho a oponerse en cualquier momento a cualquier procesamiento de su información personal que tenga como base legal nuestros intereses legítimos. Podrá ejercer este derecho sin incurrir en ningún coste. Si presenta una objeción, tenemos la oportunidad de demostrar que tenemos intereses legítimos imperiosos que anulan sus derechos y libertades. El derecho de oposición no existe, en particular, si el procesamiento de su información personal es necesario para tomar medidas antes de celebrar un contrato o para ejecutar un contrato ya celebrado.</p>
         <p>Derecho a retirar el consentimiento: si nos has dado tu consentimiento para el tratamiento de tu información personal, tienes derecho a retirar tu consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento antes de su retirada.</ p>
         <p>Derecho a oponerse a nuestro uso de su información personal para marketing directo: puede solicitar que cambiemos la forma en que nos comunicamos con usted con fines de marketing. Puede solicitar que no transfiramos su información personal a terceros no afiliados con fines de marketing directo o cualquier otro fin.</p>
         <p>Derecho a obtener una copia de las salvaguardas: puede solicitar obtener una copia o una referencia a las salvaguardias bajo las cuales se transfiere su información personal fuera de la UE/EEE. Podemos redactar acuerdos de transferencia de datos para proteger los términos comerciales.</p>
         <p>Derecho a presentar una queja ante su autoridad supervisora local: tiene derecho a presentar una queja ante su autoridad supervisora local si tiene dudas sobre cómo procesamos su información personal. Le pedimos que primero intente resolver cualquier problema con nosotros, aunque tiene derecho a comunicarse con su autoridad supervisora en cualquier momento.</p>
         <p>Puede enviarnos su solicitud de RGPD a través de:</p>
         <p><br></p>
         <p>Nuestro formulario web en línea</p>
         <p>Llame al (888) 778-6995 (llamada gratuita)</p>
         <p><br></p>
         <h6>B. Residentes de California.</h6>
         <p>Esta sección proporciona a los residentes de California información adicional sobre nuestra recopilación, uso y divulgación de su información personal, así como sus derechos de privacidad, según las leyes de privacidad de California.</p>
         <p><br></p>
         <p>En esta sección, brindamos información adicional a los residentes de California sobre cómo manejamos su información personal, según lo exige la Ley de Privacidad del Consumidor de California (“CCPA”). Esta sección no aborda ni se aplica a nuestro manejo de información personal que está exenta según la CCPA.</p>
         <p><br></p>
         <p>Categorías de información personal que recopilamos y divulgamos. Nuestra recopilación, uso y divulgación de información personal sobre un residente de California variará según las circunstancias y la naturaleza de nuestras interacciones o relación con dicho residente. A continuación describimos las categorías de información personal que podemos recopilar sobre los residentes de California (incluidas las categorías de información personal que hemos recopilado sobre los residentes de California en los últimos 12 meses):</p>
         <p><br></p>
         <p>Identificadores: incluye identificadores directos como un nombre real, alias, dirección, identificador personal único, identificador en línea, dirección de Protocolo de Internet (IP), dirección de correo electrónico, nombre de cuenta, número de seguro social, número de licencia de conducir, número de pasaporte o otros identificadores similares.</p>
         <p>Registros de clientes: registros de clientes en papel y electrónicos que contienen información personal, que usted nos proporciona para acceder o utilizar nuestros Servicios, como nombre, firma digital, dirección, número de teléfono, correo electrónico y otra información de contacto, identificadores gubernamentales, información financiera y de pagos.</p>
         <p>Características de las clasificaciones protegidas: como información sobre discapacidad y salud (por ejemplo, que podemos recopilar para, por ejemplo, poner a su disposición alojamiento en los eventos que organizamos).</p>
         <p>Información comercial: incluidos registros de bienes inmuebles, productos o servicios comprados, obtenidos o considerados, u otros historiales o tendencias de compra o uso.</p>
         <p>Datos de uso: Internet u otra actividad de red electrónica Información que incluye, entre otros, historial de navegación, historial de búsqueda e información sobre la interacción de un consumidor con un sitio web, una aplicación o un anuncio de Internet.</p>
         <p>Datos de ubicación: información de ubicación sobre un individuo o dispositivo en particular.</p>
         <p>Audio, vídeo y otros datos electrónicos: información de audio, electrónica, visual, térmica, olfativa o similar, como imágenes de CCTV y proyecciones térmicas (por ejemplo, recopiladas de visitantes a nuestras oficinas), fotografías y grabaciones de llamadas.< /p>
         <p>Historia laboral: información profesional o relacionada con el empleo.</p>
         <p>Información educativa: historia y antecedentes de la educación.</p>
         <p>Perfiles e inferencias: inferencias extraídas de cualquier información identificada anteriormente para crear un perfil que refleje las preferencias, características, tendencias psicológicas, predisposiciones, comportamiento, actitudes, inteligencia, habilidades o aptitudes de un residente.</p>
         <p>Información personal confidencial: en circunstancias limitadas, podemos recopilar cierta información que se considera información personal confidencial según la CCPA, incluido el número de Seguro Social y la identificación fiscal; licencia de conducir, tarjeta de identificación estatal, número de pasaporte u otro identificador gubernamental; número de cuenta financiera y datos de la tarjeta de pago; y datos de geolocalización.</p>
         <p>Categorías de terceros. A continuación describimos los terceros a quienes podemos revelar cada categoría de información personal para fines comerciales o de negocios:</p>
         <p><br></p>
         <p class="f-w-500">Identificadores:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
           <li>Redes publicitarias</li>
           <li>Proveedores de análisis de datos</li>
           <li>Redes sociales</li>
           <li>Sistemas operativos y plataformas</li>
         </ul>
  
         <p class="f-w-500">Registros de clientes:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
           <li>Sistemas operativos y plataformas</li>
         </ul>
  
         <p class="f-w-500">Características de las clasificaciones protegidas:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
         </ul>
         <p class="f-w-500">Información comercial:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
           <li>Proveedores de análisis de datos</li>
           <li>Redes sociales</li>
           <li>Sistemas operativos y plataformas</li>
         </ul>
  
         <p class="f-w-500">Datos de uso:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
           <li>Redes publicitarias</li>
           <li>Proveedores de análisis de datos</li>
           <li>Redes sociales</li>
           <li>Sistemas operativos y plataformas</li>
         </ul>
  
         <p class="f-w-500">Datos de ubicación:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
           <li>Sistemas operativos y plataformas</li>
         </ul>
         <p class="f-w-500">Audio, vídeo y otros datos electrónicos:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
         </ul>
  
         <p class="f-w-500">Historial laboral:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
         </ul>
  
         <p class="f-w-500">Información educativa:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
         </ul>
         <p class="f-w-500">Perfiles e inferencias:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
           <li>Redes publicitarias</li>
           <li>Proveedores de análisis de datos</li>
           <li>Redes sociales</li>
         </ul>
  
         <p class="f-w-500">Información personal confidencial:</p>
         <ul>
           <li>Proveedores de servicios</li>
           <li>Asesores y agentes</li>
           <li>Entidades gubernamentales y autoridades policiales</li>
           <li>Afiliadas y subsidiarias</li>
           <li>Profesionales inmobiliarios</li>
         </ul>
  
         <p>Además, podemos revelar cualquiera de las categorías de información personal que recopilamos sobre usted a otros terceros según sus indicaciones o su consentimiento, o cuando sea necesario para cumplir con sus solicitudes específicas.</p>
         <p><br></p>
         <p>Ventas y uso compartido. La CCPA define una "venta" como revelar o poner a disposición información personal a un tercero a cambio de una contraprestación monetaria u otra contraprestación valiosa, y "compartir" incluye en términos generales revelar o poner a disposición información personal a un tercero con fines de contexto cruzado. publicidad comportamental. Si bien no divulgamos información personal a terceros a cambio de una compensación monetaria, podemos "vender" o "compartir" (según lo define la CCPA): (i) identificadores, datos de uso, registros de clientes, información comercial y perfiles con nuestros filiales y empresas subsidiarias (por ejemplo, para que puedan mejorar o perfeccionar sus propios registros y para otros fines); y (ii) identificadores, datos de uso e información comercial para redes publicitarias, plataformas de redes sociales y proveedores de análisis de datos (por ejemplo, para mejorar y medir nuestras campañas publicitarias y de marketing).</p>
         <p><br></p>
  
         <p>Fuentes de información personal. Podemos recopilar información personal de las siguientes fuentes:</p>
         <ul>
           <li>Directamente del individuo</li>
           <li>Profesionales inmobiliarios, incluidos corredores y agentes independientes de las marcas Anywhere</li>
           <li>Registros de propiedad, registros públicos y otras fuentes disponibles públicamente</li>
           <li>Redes publicitarias</li>
           <li>Proveedores de análisis de datos</li>
           <li>Redes sociales</li>
           <li>Entidades gubernamentales</li>
           <li>Corredores de datos</li>
           <li>Empresas afiliadas y subsidiarias</li>
           <li>Proveedores de servicios</li>
         </ul>
         <p>Propósitos de recopilación, uso y divulgación. Como se describe con más detalle en la Sección 3. Uso de información personal y la Sección 4. Divulgaciones de información personal, recopilamos, usamos, divulgamos y procesamos información personal (incluida información personal confidencial) para los siguientes fines comerciales o comerciales o según se indique lo contrario. o con su consentimiento:</p>
         <ul>
           <li>Brindar soporte y servicios</li>
           <li>Analizar y mejorar nuestro negocio</li>
           <li>Personalizar contenidos y experiencias</li>
           <li>Fines publicitarios, de marketing y promocionales</li>
           <li>Asegurar y proteger nuestro negocio</li>
           <li>Defendiendo nuestros derechos legales</li>
           <li>Planificación y facilitación de transacciones comerciales</li>
           <li>Auditoría, presentación de informes, gobierno corporativo y operaciones internas</li>
           <li>Cumplir con las obligaciones legales</li>
         </ul>
  
         <p>Retención. Conservaremos su información personal durante el período necesario para cumplir con los propósitos descritos en esta Política de Privacidad o que se le revelarán de otro modo en el momento de la recopilación, a menos que la ley requiera o permita un período de retención más largo. Podemos conservar información personal durante más tiempo cuando así lo requieran nuestras obligaciones legales y reglamentarias, obligaciones de indemnización profesional o cuando creamos que es necesario para establecer, defender o proteger nuestros derechos e intereses legales o los de otros.</p>
         <p><br></p>
         <p>Derechos de los residentes de California. En general, los residentes de California tienen los siguientes derechos según la CCPA:</p>
         <p><br></p>
         <p>Derechos de los residentes de California. Según la CCPA, los residentes de California tienen los siguientes derechos (sujetos a ciertas limitaciones) con respecto a la información personal que recopilamos y mantenemos sobre ellos:</p>
         <p><br></p>
         <p>Optar por no participar en ventas y compartir: para optar por no participar en nuestras ventas y compartir su información personal.</p>
         <p>Limitar usos y divulgaciones de información personal confidencial: para limitar ciertos usos o divulgaciones de información personal confidencial a aquellos usos autorizados por la CCPA.</p>
         <p>Eliminación: para solicitar la eliminación de su información personal, sujeto a ciertas excepciones.</p>
         <p>Para saber/acceder: para saber qué información personal hemos recopilado sobre ellos, incluidas las categorías de información personal, las categorías de fuentes de las cuales se recopila la información personal, el propósito comercial o empresarial para recopilar, vender o compartir. información personal, las categorías de terceros a quienes revelamos información personal y los datos personales específicos que hemos recopilado sobre ellos.</p>
         <p>Corrección: para solicitar la corrección de información personal inexacta.</p>
         <p>No discriminación: no ser objeto de un trato discriminatorio por el ejercicio de sus derechos bajo la CCPA.</p>
         <p>Envío de solicitudes CCPA. Los residentes de California pueden ejercer sus derechos de privacidad CCPA para conocer/acceder, eliminar, corregir y limitar el uso/divulgación de información personal confidencial enviándonos una solicitud verificable a través de:</p>
         <ul>
           <li>Nuestro formulario web en línea</li>
           <li>Llame al (888) 778-6995 (llamada gratuita)</li>
         </ul>
         <p>Responderemos a solicitudes verificables recibidas de residentes de California según lo exige la ley. Cuando envía una solicitud para saber o para eliminar, tomaremos medidas para verificar su solicitud haciendo coincidir la información proporcionada por usted con la información que tenemos en nuestros registros. Debe completar todos los campos obligatorios en nuestro formulario web (o proporcionarnos esta información a través del número gratuito anterior) para que podamos procesar y verificar su solicitud. Procesaremos su solicitud en función de la información personal de nuestros registros que esté vinculada o razonablemente vinculable a la información proporcionada en su solicitud. En algunos casos, podemos solicitar información adicional para verificar su solicitud o cuando sea necesario para procesar su solicitud. Si no podemos verificar adecuadamente una solicitud, se lo notificaremos al solicitante. Los agentes autorizados pueden iniciar una solicitud en nombre de otra persona comunicándose con nosotros por correo electrónico o por teléfono; Los agentes autorizados deberán proporcionar prueba de su autorización y también podremos exigir que el consumidor correspondiente verifique directamente su identidad y la autoridad del agente autorizado.</p>
         <p><br></p>
         <p>Solicitudes de exclusión voluntaria. Nuestro sitio web responde a señales de control de privacidad global, o "GPC", lo que significa que si detectamos que su navegador está comunicando una señal de GPC, lo procesaremos como una solicitud para excluir ese navegador y dispositivo en particular de las ventas y el intercambio ( es decir, a través de cookies y herramientas de seguimiento) en nuestro sitio web. Tenga en cuenta que si regresa a nuestro sitio web desde un dispositivo diferente o utiliza un navegador diferente en el mismo dispositivo, también deberá optar por no participar (o configurar GPC para) ese navegador y dispositivo. (También puede administrar sus preferencias de orientación y otras cookies en uno de nuestros sitios web a través del enlace "Configuración de cookies" en el pie de página del sitio web). Más información sobre GPC está disponible en: https://globalprivacycontrol.org/. También puede enviar una solicitud para excluirse de otras actividades compartidas y ventas enviando una solicitud a través de:</p>
         <ul>
           <li>Nuestro formulario web en línea</li>
           <li>Llame al (888) 778-6995 (llamada gratuita)</li>
         </ul>
         <p>Incentivos financieros. Podemos poner a disposición ciertos programas u ofertas que se consideran "incentivos financieros" según la CCPA, y notificaremos los términos materiales de dichos incentivos y obtendremos el consentimiento de las personas antes de incluirlos en dichos programas de incentivos. Puede optar por no participar en dichos programas en cualquier momento a través del mecanismo de exclusión proporcionado en la información del programa.</p>
         <p><br></p>
         <p>Derechos bajo la Ley Shine the Light de California. Los residentes de California pueden solicitar a las empresas con las que tienen una relación comercial establecida (1) una lista de categorías de información personal, como nombre, dirección, dirección de correo electrónico y el tipo de servicios proporcionados al cliente, que una empresa tiene divulgado a terceros durante el año calendario inmediatamente anterior para fines de marketing directo de terceros y (2) los nombres y direcciones de todos dichos terceros. Para solicitar la información anterior, comuníquese con nosotros escribiéndonos en la sección "Contáctenos" anterior. Responderemos a dichas solicitudes dentro de los 30 días siguientes a su recepción.</p>
         <p><br></p>
         <p>Para obtener más información sobre nuestras prácticas de privacidad, contáctenos como se establece en la Sección 16 anterior.</p>
         <p><br></p>
         <h6>C. Residentes de otros estados de EE. UU.</h6>
         <p>Los residentes de ciertos estados de EE. UU. (incluido Virginia) pueden tener derechos adicionales según las leyes de privacidad aplicables, sujetos a ciertas limitaciones. Estos derechos pueden incluir:</p>
         <p><br></p>
         <p>Corrección: corregir inexactitudes en sus datos personales, teniendo en cuenta la naturaleza y finalidades del tratamiento de los datos personales.</p>
         <p>Eliminación: para eliminar su información personal proporcionada u obtenida por nosotros.</p>
         <p>Acceso: para confirmar si estamos procesando su información personal y para obtener una copia de su información personal en un formato portátil y, en la medida de lo técnicamente posible, fácilmente utilizable.</p>
         <p>Exclusión voluntaria: para excluirse de ciertos tipos de procesamiento, que incluyen:</p>
         <ul>
           <li>optar por no participar en la “venta” de su información personal.</li>
           <li>optar por no recibir publicidad dirigida por nuestra parte.</li>
           <li>optar por no participar en ningún procesamiento de información personal con el fin de tomar decisiones que produzcan efectos legales o igualmente significativos.</li>
         </ul>
         <p>Puede enviar una solicitud para ejercer sus derechos de privacidad a través de:</p>
         <ul>
           <li>Nuestro formulario web en línea</li>
           <li>Llame al (888) 778-6995 (llamada gratuita)</li>
         </ul>
         <p>También puede optar por no recibir publicidad dirigida en uno de nuestros sitios web, haciendo clic en el enlace "Configuración de cookies" en nuestro sitio web y desactivando las cookies dirigidas (publicitarias). (Consulte la Sección 12. Sus derechos y opciones, para obtener información adicional sobre las opciones de privacidad que brindamos y cómo ejercerlas). Responderemos a su solicitud según lo exigen las leyes de privacidad aplicables. Si rechazamos su solicitud, puede apelar nuestra decisión enviando una solicitud a través de nuestro formulario web</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
  
      }
    },
  
    methods: {
      scrollMeTo(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;
  
        window.scrollTo(0, top);
      }
    }
  }
  </script>
  