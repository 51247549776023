<template>
  <div>
    <ol class="breadcrumb 2">
            <li><router-link to="/home"><i class="entypo-home"></i>Home</router-link></li>
            <li class="active"><strong>Propiedades</strong></li>
        </ol>
        <h2 class="margin-bottom">Propiedades</h2>

        <div class="row">
          <div class="col-md-12">
              <div class="row">
                  <div class="col-sm-3">
                      <div class="tile-stats tile-white tile-white-primary">
                          <div class="icon"><i class="entypo-chart-area"></i></div>
                          <div class="num">{{ totals.active  }}</div>
                          <h3>Propiedades Activas</h3>
                          
                      </div>   
                  </div>
                  <div class="col-sm-3">
                      <div class="tile-stats tile-white tile-white-primary">
                          <div class="icon"><i class="entypo-chart-area"></i></div>
                          <div class="num">{{ totals.desactive  }}</div>
                          <h3>Propiedades Desactivadas</h3>
                
                      </div>   
                  </div>
                  
                  <div class="col-sm-3">
                      <div class="tile-stats tile-white tile-white-primary">
                          <div class="icon"><i class="entypo-chart-area"></i></div>
                          <div class="num">{{ totals.syncs_active  }}</div>
                          <h3>Propiedades Sincronizandas<br>Activas del dia</h3>
                      </div>   
                  </div>
                  <div class="col-sm-3">
                      <div class="tile-stats tile-white tile-white-primary">
                          <div class="icon"><i class="entypo-chart-area"></i></div>
                          <div class="num">{{ totals.syncs_desactive  }}</div>
                          <h3>Propiedades Sincronizandas<br>Desactivadas del dia</h3>
                      </div>   
                  </div>
                  <div class="col-sm-3">
                      <div class="tile-stats tile-white tile-white-primary">
                          <div class="icon"><i class="entypo-chart-area"></i></div>
                          <div class="num">{{ totals.total  }}</div>
                          <h3>Total de propiedades</h3>
                      </div>   
                  </div>
              </div>
          </div>

            <div class="col-md-12">
                <div id="toolbar">
                    <router-link to="/properties/edit">
                        <button class="btn btn-success btn-sm">
                            <i class="fa fa-plus"></i> Nuevo
                        </button>
                    </router-link>
                    <button class="btn btn-danger btn-sm" @click="deleteRows()">
                        <i class="fa fa-trash"></i> Borrar
                    </button>

                    <!-- <button class="btn btn-info btn-sm" @click="syncData()">
                        <i class="fa fa-sync"></i> Sincronizar propiedades
                    </button> -->
                </div>
                <table id="table"></table>
            </div>
        </div>
        <sweet-modal ref="modalloading">
          <div class="fa-3x"><i class="fas fa-spinner fa-pulse"></i></div><br>
          <p>Sincronizando propiedades...  <br>Esto puede tardar varios minutos</p>
        </sweet-modal>
    </div>
</template>
<script type="text/javascript">
  export default {
  data(){
    return {
      rows:[],
      totals:{
          active:0,
          desactive:0,
          total:0,
      }
    }
  },
  methods:{
    mounthTable(){
      jQuery('#table').bootstrapTable({
        columns: [
          {
            field:"check",
            checkbox:true,
            align: 'center',
          },          
          {
            field: 'id_property',
            title: 'ID',
            sortable:true,
            switchable:true,
          },
          {
            field: 'rfg_id',
            title: 'ID',
            sortable:true,
            switchable:true,
          },
          {
            field: 'image',
            title: ' ',
            sortable:false,
            width:"52px",
          },
          {
            field: 'name',
            title: 'Nombre',
            sortable:true,
            switchable:true,
          },
          {
            field: 'type',
            title: 'Tipo',
            sortable:true,
            switchable:true,
          },
          {
            field: 'type_operation',
            title: 'Tipo de operacion',
            sortable:true,
            switchable:true,
          },
          {
            field: 'price',
            title: 'Precio (MX)',
            sortable:true,
            switchable:true,
          },
          {
            field: 'status',
            title: 'Estatus',
            sortable:true,
            switchable:true,
          }
        ],
        showRefresh:true,
      });

      jQuery('#table').on('refresh.bs.table',()=>{
        this.getContent();
      });

      jQuery('#table').on('click-row.bs.table',(row,data)=>{
        this.$router.push('/properties/edit/'+data.id);
      });

      this.getContent();

    },

    getContent(){
      this.$parent.inPetition=true;
      axios.get(tools.url("/api/admin/properties")).then((response)=>{
          this.rows = response.data.rows;
          this.totals = response.data.totals;
          jQuery('#table').bootstrapTable('removeAll');
          jQuery('#table').bootstrapTable('append',this.rows);
          this.$parent.inPetition=false;
        }).catch((error)=>{
            this.$parent.handleErrors(error);
            this.$parent.inPetition=false;
        });
    },

    deleteRows:function(){
      var rows=jQuery('#table').bootstrapTable('getSelections');
      if(rows.length==0){
        return false;
      }
      alertify.confirm("Alerta!","¿Seguro que deseas borrar "+rows.length+" registros?",()=>{
        this.$parent.inPetition=true;
        var params={};
        params.ids=jQuery.map(rows,(row)=>{
          return row.id;
        });

        axios.delete(tools.url('/api/admin/properties'),{data:params})
        .then((response)=>{
          this.$parent.showMessage(response.data.msg,"success");
          this.getContent();
          this.$parent.inPetition=false;
        })
        .catch((error)=>{
          this.$parent.handleErrors(error);
              this.$parent.inPetition=false;
        });
      },
      ()=>{
      });
    },
    syncData(){
      this.$refs.modalloading.open();
      this.$parent.inPetition=true;
      axios.get(tools.url("/api/admin/syncproperties")).then((response)=>{
        this.$parent.showMessage(response.data.msg,"success");
          this.getContent();
          this.$parent.inPetition=false;
          this.$refs.modalloading.close();
        }).catch((error)=>{
            this.$parent.handleErrors(error);
            this.$parent.inPetition=false;
            this.$refs.modalloading.close();
        });
    }
  },
      mounted() {
          this.mounthTable();
      }
  }
</script>
