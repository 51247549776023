<template lang="html">
  <div id="properties-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{row.name}} </h1>

            
          </div>
          <!-- <div class="col-lg-5 col-xl-4 col-info">
          <p>
            Discover the most extraordinary houses and apartments for sale in Costa Rica. Let yourself be guided by the most exclusive real estate agency in Costa Rica, and find your dream home.
          </p>
        </div> -->
          

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="properties-section only-list">
      <div class="container oversized-container">
        <div class="row">
          <!-- Properties -->
          <div class="col-lg-5 col-xl-6 col-properties">
            <div class="row row-top">
              <div class="col-12 col-top">
                <div class="col-7 col-title">
                  <h5>
                      <p>
                          {{row.description}}
                      </p>
                      <span v-if="!this.$route.query.buyrent"></span>
                  <span v-if="this.$route.query.buyrent == 1"> {{ $t("result.properties_forsale") }}</span>
                  <span v-if="this.$route.query.buyrent == 2"> {{ $t("result.properties_forrent") }}</span>
                  </h5>
                </div>

              

                
              </div>

              <div class="col-12 col-filters">
                <div class="col col-order">
                  <b-form-group
                    label="Filter by">
                    <b-form-select v-model="form.order">
                      <b-form-select-option value="1">{{ $t("result.filter_pricelow") }}</b-form-select-option>
                      <b-form-select-option value="2">{{ $t("result.filter_pricehigh") }}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div class="row row-middle">
              <div class="col-12 col-results-info">
                <h5>{{pagination.total_properties}} {{ $t("result.filter_propeties") }}</h5>
              </div>

              <div class="col-md-6 col-lg-12 col-xl-6 col-property-sample-1" v-for="(prop, prInx) in properties" :key="'prInx-'+prInx">
                <router-link class="box-link" :to="'/property/'+prop.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+prop.imageUrl+')' }">
                    <img src="public/images/pages/properties/property.png">
                  </div>

                  <div class="box-text">
                    <h5 class="name">{{ prop.name }}</h5>
                    <h6 class="price" v-if="$root.currency == 'usd'">$ {{ formatNum(prop.price_usd) }} USD</h6>
                  <h6 class="price" v-else-if="$root.currency == 'mxn'">$ {{ formatNum(prop.price_mxn) }}<span v-if="prop.price == 0 || prop.price == null"> USD </span> <span v-else> MXN</span></h6>
                

                    <div class="box-prop-info">
                      <!-- <span>SIZE: {{ prop.meters_ground}} FT</span> -->
                      <span style="padding-right: 0px">{{ prop.toilets}} {{ $t("result.result_bath") }}</span><span style="padding:0px" v-if="prop.toilets_partial != null && prop.toilets_partial != 0">, {{prop.toilets_partial}} {{ $t("result.result_bath_partial") }}</span>
              
                    <span>{{ prop.bedrooms}} {{ $t("result.result_bed") }}</span>
                    <!-- <span>{{ prop.size_interior}} </span> -->
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row" v-if="properties.length > 0">
              <div class="col-12 text-center">
                <div class="d-inline-block mx-0 col-pagination-sample-1">
                  <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
                </div>
              </div>
          </div>

          </div>
          <!--  -->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return {
      properties: [
        /*{ id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },

        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        */
      ],

      form: {
        order: 1,
      },
      row:{
          name:null,
          description:null
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_properties:1,
      },
    }
  },

  methods: {
    getProperties() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.post(tools.url("/api/propertiesoffice?page=" + this.pagination.currentpage),{keywords:this.$route.params.id}).then((response)=>{
        this.properties = response.data.data;
        this.pagination.total = response.data.last_page;
        this.pagination.total_properties = response.data.total;

        var sortedproperties = this.properties.sort(
          (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
        this.properties = sortedproperties;

        this.$root.modal_loading = false;
      });
    },

    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },
    getRow(){
      axios.get(tools.url("/api/office/"+this.$route.params.id)).then((response)=>{
          this.row = response.data;
          
          this.getProperties();
        
      }).catch((error)=>{
          console.error('Error getting data');
      });
    },
    linkGen(pageNum) {
       return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },

  watch: {
    'form.order':function(val){
        if (this.form.order == 1) {
          var sortedproperties = this.properties.sort(
            (p1, p2) => (p1.price > p2.price) ? 1 : (p1.price < p2.price) ? -1 : 0);
        }
        else if(this.form.order == 2){
            var sortedproperties = this.properties.sort(
            (p1, p2) => (p1.price < p2.price) ? 1 : (p1.price > p2.price) ? -1 : 0);
        }
        this.properties = sortedproperties;
    },
    '$route.query.page':function(val){
      if (val) {
          this.pagination.currentpage = val;
      }
      else{
          this.pagination.currentpage = 1;
      }
      this.getProperties();
      
  },
  },

  mounted(){
    
    this.getRow();
    //this.getProperties();
  },
}
</script>
