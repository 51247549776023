<template lang="html">
  <div id="lifestyles-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("projects.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("projects.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p>{{ $t("projects.description") }}</p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container gallery-section">
      <div class="row">
        <div class="col-sm-6 col-lg-4 col-image" v-for="(l, lInx) in projects" :key="'lInx-'+lInx">
          <router-link class="placed-backg btn-image" v-bind:style="{ backgroundImage: 'url('+ l.imageUrl + ')' }" :to="'/projects/'+l.id">
            <img src="public/images/pages/lifestyles/image.png" alt="Image">

            <div class="inside">
              <div class="box">{{ l.name }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [
        /*{ name: 'Beach' },
        { name: 'Country living' },
        { name: 'Eco Friendly' },
        { name: 'Equastrian' },
        { name: 'Lakefront' },
        { name: 'Metropolitan' },*/
      ]
    }
  },
  methods: {
    getProjects() { // Obtener la info de la BD
      axios.get(tools.url("/api/projects")).then((response)=>{
        this.projects = response.data;
      });
    },
  },
  mounted(){
    this.getProjects();
  },
}
</script>

