<template lang="html">
  <div id="lifestyles-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("destinations.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("destinations.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p>{{ $t("destinations.description") }}</p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container gallery-section">
      <div class="row">
        <div class="col-sm-6 col-lg-3 col-image" v-for="(l, lInx) in destinations" :key="'lInx-'+lInx">
          <router-link class="placed-backg btn-image" v-bind:style="{ backgroundImage: 'url('+ l.imageUrl + ')' }" :to="'/destinations/'+l.id">
            <img src="public/images/pages/lifestyles/image.png" alt="Image">

            <div class="inside">
              <div class="box">{{ $i18n.locale == 'en' ? l.name_en  : l.name }} </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      destinations: [
        /*{ name: 'Name example' },
        { name: 'Name example' },
        { name: 'Name example' },
        { name: 'Name example' },
        { name: 'Name example' },
        { name: 'Name example' },*/
      ]
    }
  },
  methods: {
    getDestinations() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.get(tools.url("/api/destinations")).then((response)=>{
        this.destinations = response.data;
        this.$root.modal_loading = false;
      });
    },
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.destinations.meta_title},
        { name: 'og:description', content: this.$root.seoadata.destinations.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.destinations.meta_keywords},
      ]
    }
  },
  mounted(){
    this.getDestinations();
  },
}
</script>
