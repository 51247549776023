<template lang="html">
  <!-- Preferences Menu -->
  <div class="toggleable-box-menu" v-bind:class="{ 'active' : $root.isPrefActive }">
    <div class="bg-menu" @click="$root.isPrefActive = false"></div>

    <b-form class="box-menu" @submit="onSubmit">
      <!-- Options -->
      <div class="box">
        <div class="row row-menu row-title-close">
          <div class="col col-sm-6 col-label">
            <h5 class="f-w-700 title">{{ $t("header.menu_preferencers.preferencers") }}</h5>
          </div>

          <div class="col col-sm-6 col-option">
            <a class="btn-close" @click="$root.isPrefActive = false"><i class="fal fa-times"></i></a>
          </div>
        </div>

        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>{{ $t("header.menu_preferencers.language") }}</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-select v-model="lang">
                <b-form-select-option value="en">{{ $t("header.menu_preferencers.en") }}</b-form-select-option>
                <b-form-select-option value="es">{{ $t("header.menu_preferencers.es") }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>

        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>{{ $t("header.menu_preferencers.currency") }}</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-select v-model="$root.currency">
                <b-form-select-option value="usd">USD</b-form-select-option>
                <b-form-select-option value="mxn">MXN</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>

        <div class="row row-menu">
          <div class="col-lg-6 col-label">
            <h5>{{ $t("header.menu_preferencers.unit_measure") }}</h5>
          </div>

          <div class="col-lg-6 col-option">
            <div class="box-select">
              <b-form-select v-model="$root.unit">
                <b-form-select-option value="us">U.S</b-form-select-option>
                <b-form-select-option value="metric">METRIC</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <!-- Form Buttons -->
      <!-- <div class="box-bottom">
        <b-button type="submit" class="btn-s1 white btn-bg">{{ $t("header.menu_preferencers.btn_aply") }}</b-button>
      </div> -->
    </b-form>
  </div>
  <!--  -->
</template>

<script>
import { mounted } from 'vue-social-sharing';

export default {
  data(){
    return{
      lang: 'en',
      currency: 'usd',
      unit: 'us',
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      console.log('Preferences...');
    },
  },

  watch: {
    '$route' (to, from){
      this.$root.isPrefActive = false;
    },
    'lang':function(val){
      this.$root.changeLangBTN(val);
      if(val == 'en'){
          this.$root.unit = 'us';
      }
      else if(val == 'es'){
        this.$root.unit = 'metric';
      }
    }
  },
  mounted(){
        this.lang =  this.$i18n.locale;
  }
}
</script>
