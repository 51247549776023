<template lang="html">
  <div id="about-us-2-page">

    <section class="info-1-section">
      <div class="container">
        <div class="box-intro">
          <h1 class="title">About Us</h1>
          <h4 class="sub" v-if="$i18n.locale == 'en'">{{ row.title }}</h4>
          <h4 class="sub" v-if="$i18n.locale == 'es'">{{ row.title_es }}</h4>
        </div>

        <div class="box-video">
          <img :src="row.imageUrl" alt="">
        </div>
      </div>
    </section>

    <section class="container info-2-section">
      <div class="row row-1" v-if="$i18n.locale == 'en'" ><div class="col-lg-12 text-justify pt-0 box-1-info" v-html="row.body"></div></div>
      <div class="row row-1" v-if="$i18n.locale == 'es'" ><div class="col-lg-12 text-justify pt-0 box-1-info" v-html="row.body_es"></div></div>
        
          <!-- <h2 class="title-sty-2">Expertise wherever you are and wherever you want to be.</h2>
        </div>

        <div class="col-lg-12 text-justify pt-0 box-1-info"> </div>-->

        
      
      <div class="row row-1">
        <div class="col-lg-12 text-justify pt-0 box-1-info">
          <p class="text-center mt-4">
            <a v-if="$i18n.locale == 'en'" class="btn btn-s1 blue btn-bg" target="_blank" style="width: 260px; letter-spacing: 2px;" href="https://goo.gl/maps/JVszWv5rBcr7StfJ9">VISIT US!</a>
            <a v-if="$i18n.locale == 'es'" class="btn btn-s1 blue btn-bg" target="_blank" style="width: 260px; letter-spacing: 2px;" href="https://goo.gl/maps/JVszWv5rBcr7StfJ9">VISITANOS!</a>
          </p>
        </div>
      </div>
    </section>

    <section class="info-3-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-image">
            <img :src="row.imageMiddleUrl">
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-connect">
            <hr />

            <h5 v-if="$i18n.locale == 'en'">{{ row.title_middle }}</h5>
            <h5 v-if="$i18n.locale == 'es'">{{ row.title_middle_es }}</h5>
            <a v-if="$i18n.locale == 'en'" @click="goToFooter()"> Connect with us</a>
            <a v-if="$i18n.locale == 'es'" @click="goToFooter()"> Conecta con nosotros</a>
          </div>

          <div class="col-lg-7 col-text" v-if="$i18n.locale == 'en'" v-html="row.body_middle"></div>
          <div class="col-lg-7 col-text" v-if="$i18n.locale == 'es'" v-html="row.body_middle_es"></div>
        </div>
      </div>
    </section>
    

    <section class="container info-4-section">
      <div class="row row-1" v-if="$i18n.locale == 'en'"><div class="col-lg-8 col-top" v-html="row.body_inferior"></div></div>
      <div class="row row-1" v-if="$i18n.locale == 'es'"><div class="col-lg-8 col-top" v-html="row.body_inferior_es"></div></div>

     

      <div class="row row-2">
        <div class="col-lg-4 col-inf" v-if="$i18n.locale == 'en'" v-html="row.square_one"></div>
        <div class="col-lg-4 col-inf" v-if="$i18n.locale == 'es'" v-html="row.square_one_es"></div>

        <div class="col-lg-4 col-inf" v-if="$i18n.locale == 'en'" v-html="row.square_two"></div>
        <div class="col-lg-4 col-inf" v-if="$i18n.locale == 'es'" v-html="row.square_two_es"></div>

        <div class="col-lg-4 col-inf" v-if="$i18n.locale == 'en'" v-html="row.square_three"></div>
        <div class="col-lg-4 col-inf" v-if="$i18n.locale == 'es'" v-html="row.square_three_es"></div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
        comments: null,
      },

      recaptchaVerified:false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      row:{

      }
    }
  },

  methods: {
    goToSection(idx) {
      document.querySelector('#info-6-section').scrollIntoView({behavior: 'smooth'});
    },

    goToFooter(idx) {
      document.querySelector('#footer').scrollIntoView({behavior: 'smooth'});
    },

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      if(this.recaptchaVerified == true){

        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        var params = this.form;
        var apiURL = tools.url("/api/contact");

        axios.post( apiURL,params )
        .then( (response) => {
          // alert(response.data.response);
          this.makeToast('success', response.data.response, 'Message sent');
          // alert(response.data.response);

          for (let prop in this.form) {
            this.form[prop] = '';
          }
          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
        })
        .catch( (error) => {
          // console.log(error.response.data);
          let errors = error.response.data;

          for (let prop in errors) {
            // console.log(errors[prop]);
            this.makeToast('danger', errors[prop], '¡Error!');
          }
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";
        });
      }else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },

    handleSuccess(response) {
      this.recaptchaVerified = true;
    },
    getRow() { // Obtener la info de la BD
      
      axios.get(tools.url("/api/aboutus")).then((response)=>{
        this.row = response.data;
        
      });
    },
  },
  mounted(){
      this.getRow();
  }
}
</script>
