<template lang="html">
  <div id="lifestyles-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("projects.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("projects.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p>{{ $t("projects.description") }}</p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container gallery-section">
      <div class="row">
        <div class="col-12 mb-2 mx-auto col-g-info" style="text-align: left;">
          <p>
            {{ $t("projects.description1") }}
          </p>
          <!-- <p>
            {{ $t("projects.description2") }}
          </p>
          <p>
            {{ $t("projects.description3") }}
          </p> -->
        </div>

        <div class="col-sm-6 col-lg-4 col-image" v-for="(l, lInx) in projects" :key="'lInx-'+lInx"  @click="goToFooter()">
          <!-- <router-link class="placed-backg btn-image" v-bind:style="{ backgroundImage: 'url('+ l.imageUrl + ')' }" :to=" l.sold ? '#' : '/projects/'+l.id"> -->
          <router-link class="placed-backg btn-image" v-bind:style="{ backgroundImage: 'url('+ l.imageUrl + ')' }" to="">
            <img src="public/images/pages/lifestyles/image.png" alt="Image">

            <div class="inside">
              <span class="ribon" v-if="l.sold == 1" style="
                position: absolute;
                display: block;
                width: 25%;
                text-align: center;
                z-index: 2;
                background: #f00;
                color: #fff;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 2;
                -webkit-transform: rotate(-35deg);
                -moz-transform: rotate(-35deg);
                -ms-transform: rotate(-35deg);
                -o-transform: rotate(-35deg);
                transform: rotate(-35deg);
                -webkit-transform-origin: center;
                -moz-transform-origin: center;
                -ms-transform-origin: center;
                -o-transform-origin: center;
                transform-origin: center;
                left: 1%;
                top: 12%;
            ">Vendido</span>
              <div class="box">{{ l.name }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <section class="container oversized-container contact-section">
      <div class="anchor" ref="contactSection"></div>

      <b-form class="row justify-content-center" @submit="onSubmit">
        <div class="col-12 col-title">
          <h2 class="title">{{ $t("projects.detail.contact_us") }}</h2>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
            :label='$t("agents.form.name")'>
            <b-form-input
              v-model="form.name"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.lastname")'>
            <b-form-input
              v-model="form.lastname"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.phone")'>
            <b-form-input
              v-model="form.phone"
              type="number"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.email")'>
            <b-form-input
              v-model="form.email"
              type="email"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-8 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.comentarios")'>
            <b-form-textarea
              v-model="form.comments"
              placeholder=""
              rows="3"
              max-rows="3"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-8" style="background-color: black;"></div>
        <div class="col-5 col-captcha">

          <vue-recaptcha
            sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
            ref="recaptcha"
            @verify="handleSuccess"
            >

          </vue-recaptcha>
        </div>

        <div class="col-12 mt-3 text-center">
          <b-button type="submit" class="btn-s1 blue btn-bg">{{ $t("agents.form.submit") }}</b-button>
        </div>
      </b-form>
      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>

      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [
        /*{ name: 'Beach' },
        { name: 'Country living' },
        { name: 'Eco Friendly' },
        { name: 'Equastrian' },
        { name: 'Lakefront' },
        { name: 'Metropolitan' },*/
      ],

      form: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        comments: null,
        agents_id:null,
        type:'Proyectos',
        purpose:null,
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      recaptchaVerified:false,
    }
  },
  methods: {
    getProjects() { // Obtener la info de la BD
      axios.get(tools.url("/api/projects")).then((response)=>{
        this.projects = response.data;
      });
    },

    onSubmit(evt) {
      evt.preventDefault();

      if(this.recaptchaVerified == true){
        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

          
          var params = this.form;
          var apiURL = tools.url("/api/contact");

          axios.post( apiURL,params )
          .then( (response) => {
            // alert(response.data.response);

            if(this.$i18n.locale == 'es'){
              this.modal.block = false;
              this.modal.icon = "success";
              this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
              this.$refs.recaptcha.reset();
            }
            else{
              this.modal.block = false;
              this.modal.icon = "success";
              this.modal.msg = "Gracias por enviarnos su mensaje, nos pondremos en contacto con usted lo antes posible";
              this.$refs.recaptcha.reset();
            }


            for (let prop in this.form) {
              this.form[prop] = '';
            }
          })
          .catch( (error) => {
            // console.log(error.response.data);
            let errors = error.response.data;

            for (let prop in errors) {
              // console.log(errors[prop]);
              this.makeToast('danger', errors[prop], '¡Error!');
            }
          });
      }
      else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;

    },
    goToFooter(idx) {
      document.querySelector('#footer').scrollIntoView({behavior: 'smooth'});
    },
  },
   //insert the following code for vue-meta to work
   metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.projects.meta_title},
        { name: 'og:description', content: this.$root.seoadata.projects.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.projects.meta_keywords},
      ]
    }
  },
  mounted(){
    this.getProjects();
  },
}
</script>
