<template lang="html">
  <div id="sell-with-us-page">

    <section class="info-1-section">
      <div class="container">
        <div class="box-intro">
          <h1 class="title">{{ $t("sell_with_us.title1") }}</h1>
          <h4 class="sub">{{ $t("sell_with_us.text1") }}</h4>
        </div>

        <div class="box-video">
          <video controls poster="public/videos/preview.jpg">
            <source src="public/videos/sothebys-mexico.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>

    <section class="container info-2-section">
      <div class="row row-1">
        <div class="col-xl-8 box-1-info">
          <div>
            <h2 class="ql-align-center" v-html='$t("sell_with_us.title2")'></h2>
            <p class="ql-align-center">
              {{ $t("sell_with_us.text2") }}
            </p>
            <p>
              <a class="_link-s1" @click="goToSection()">{{ $t("sell_with_us.linkList") }}</a>
            </p>
          </div>
        </div>

      </div>

      <div class="row row-2">
        <div class="col-lg-6 col-image">
          <img src="public/images/pages/sell-w-us/image-0.jpg">
        </div>

        <div class="col-lg-5 ml-lg-3 col-text">
          <h2>{{ $t("sell_with_us.title3") }}</h2>
          <p>
            {{ $t("sell_with_us.text3") }}
          </p>
        </div>
      </div>

      <div class="row row-3">
        <div class="col-lg-6 offset-lg-2 col-image">
          <img src="public/images/pages/sell-w-us/image-2.jpg">
        </div>

        <div class="col-lg-4 col-text">
          <h3 class="t">{{ $t("sell_with_us.title4") }}</h3>

          <div class="row">
            <div class="col-sm-6 col-benefit">
              <h6>{{ $t("sell_with_us.list1") }}</h6>
            </div>
            <div class="col-sm-6 col-benefit">
              <h6>{{ $t("sell_with_us.list2") }}</h6>
            </div>

            <!-- <div class="col-sm-6 col-benefit">
              <h6>{{ $t("sell_with_us.list3") }}</h6>
            </div>
            <div class="col-sm-6 col-benefit">
              <h6>{{ $t("sell_with_us.list4") }}</h6>
            </div>

            <div class="col-sm-6 col-benefit">
              <h6>{{ $t("sell_with_us.list5") }}</h6>
            </div>
            <div class="col-sm-6 col-benefit">
              <h6>{{ $t("sell_with_us.list6") }}</h6>
            </div> -->

            <div class="col-12 mt-2 mt-sm-3">
              <p @click="goToFooter()">
                <router-link class="_link-s1" to="" >{{ $t("sell_with_us.linkList") }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info-3-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-video">
            <div class="box-video">
              <video controls poster="public/videos/sell-w-us-video-2-prev.jpg">
                <source src="public/videos/sell-w-us-video-2.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div class="col-12"></div>

          <div class="col-lg-4 col-xl-3 col-connect">
            <h5>{{ $t("sell_with_us.title5") }}</h5>
            <div>
              <a @click="goToFooter()"> {{ $t("sell_with_us.linkConnect") }}</a>
            </div>
          </div>

          <div class="col-lg-7 offset-lg-1 col-text">
            <h2>{{ $t("sell_with_us.title6") }}</h2>
            <p>
              {{ $t("sell_with_us.text4") }}
            </p>

            <p class="mt-3">
              <router-link class="_link-s1 c-white" to="/agents">{{ $t("sell_with_us.linkAgents") }}</router-link>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="container info-4-section">
      <div class="row row-1">
        <div class="col-lg-8 col-top">
          <h6 class="ql-align-center"><strong>{{ $t("sell_with_us.title7") }}</strong></h6>
          <h2 class="ql-align-center">{{ $t("sell_with_us.title8") }}</h2>
          <p>
            {{ $t("sell_with_us.text5") }}
          </p>
        </div>
        <!-- <div class="col-lg-8 col-top" v-if="$i18n.locale == 'es'" v-html="row.body_bottom_es"></div> -->
      </div>

      <div class="row row-2">
        <div class="col-lg-4 col-inf">
          <h2 class="ql-align-center">1.1M</h2>
          <h6 class="ql-align-center">{{ $t("sell_with_us.list7") }}</h6>
        </div>

        <div class="col-lg-4 col-inf">
          <h2 class="ql-align-center">#1</h2>
          <h6 class="ql-align-center">{{ $t("sell_with_us.list8") }}</h6>
        </div>

        <div class="col-lg-4 col-inf">
          <h2 class="ql-align-center">52M</h2>
          <h6 class="ql-align-center">{{ $t("sell_with_us.list9") }}</h6>
        </div>
      </div>
    </section>

    <section class="info-5-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-top">
            <div class="col-lg-9 col-xl-7 px-0 mx-auto">
              <h2 class="title-sty-2">{{ $t("sell_with_us.title9") }}</h2>
              <p>
                {{ $t("sell_with_us.text9") }}
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-info">
            <div class="box-image">
              <img src="public/images/pages/sell-w-us/image-3.jpg">
            </div>

            <div class="box-text">
              <h5 class="title">{{ $t("sell_with_us.title10") }}</h5>
              <p class="ql-align-justify">
                {{ $t("sell_with_us.text6") }}
              </p>
              <p class="mt-4">
                <router-link class="t-150" to="/agents">{{ $t("sell_with_us.linkFindAgent") }}</router-link>
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-info">
            <div class="box-image">
              <img src="public/images/pages/sell-w-us/image-4.jpg">
            </div>

            <div class="box-text">
              <h5 class="title">{{ $t("sell_with_us.title11") }}</h5>
              <p class="ql-align-justify">
                {{ $t("sell_with_us.text7") }}
              </p>
              <p class="mt-4">
                <router-link class="t-150" to="/agents">{{ $t("sell_with_us.linkSellProp") }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container info-6-section">
      <i id="info-6-section"></i>

      <div class="row">
        <div class="col-12 col-top">
          <div class="col-lg-7 px-0 mx-auto" v-if="$i18n.locale == 'en'" v-html="row.form">
          </div>
          <div class="col-lg-7 px-0 mx-auto" v-if="$i18n.locale == 'es'" v-html="row.form_es">
          </div>
        </div>

        <div class="col-12 col-form">
          <h6 class="title" v-if="$i18n.locale == 'en'">Let’s get in touch</h6>
          <h6 class="title" v-if="$i18n.locale == 'es'">Mantengámonos en contacto</h6>
          <br>

          <b-form class="row justify-content-center" @submit="onSubmit">
            <div class="col-lg-5 mb-2">
              <b-form-group class="custom-f-group-2"
              :label='$t("contact.form.name")'>
                <b-form-input
                  v-model="form.name"
                  type="text"
                  size="lg"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-5 mb-2">
              <b-form-group class="custom-f-group-2"
              :label='$t("contact.form.lastname")'>
                <b-form-input
                  v-model="form.lastname"
                  type="text"
                  size="lg"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-5 mb-2">
              <b-form-group class="custom-f-group-2"
              :label='$t("contact.form.phone")'>
                <b-form-input
                  v-model="form.phone"
                  type="number"
                  size="lg"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-5 mb-2">
              <b-form-group class="custom-f-group-2"
              :label='$t("contact.form.email")'>
                <b-form-input
                  v-model="form.email"
                  type="email"
                  size="lg"
                  placeholder=""
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-lg-10 mb-2">
              <b-form-group class="custom-f-group-2"
              :label='$t("contact.form.message")'>
                <b-form-textarea
                  v-model="form.comments"
                  placeholder=""
                  rows="4"
                  max-rows="4"
                  
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div class="col-5" style="background-color: black;"></div>

            <div class="col-12">
              <vue-recaptcha class="box-recaptcha"
                sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
                ref="recaptcha"
                @verify="handleSuccess"
                >
              </vue-recaptcha>
            </div>

            <div class="col-12 mt-1 text-center">
              <b-button type="submit" class="btn-s1 blue btn-bg">{{$t("contact.form.submit")}}</b-button>
            </div>
          </b-form>

          <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
            <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
            <div v-html="modal.msg"></div>
          </sweet-modal>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        comments: null,
        type:'Sell with us',
        purpose:null,
      },

      recaptchaVerified:false,

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      row:{

      }
    }
  },

  methods: {
    goToSection(idx) {
      document.querySelector('#info-6-section').scrollIntoView({behavior: 'smooth'});
    },

    goToFooter(idx) {
      document.querySelector('#footer').scrollIntoView({behavior: 'smooth'});
    },

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    

    onSubmit(evt) {
      evt.preventDefault();
      if(this.recaptchaVerified == true){

        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        var params = this.form;
        var apiURL = tools.url("/api/contact");

        axios.post( apiURL,params )
        .then( (response) => {
          // alert(response.data.response);
          this.makeToast('success', response.data.response, 'Message sent');
          // alert(response.data.response);

          for (let prop in this.form) {
            this.form[prop] = '';
          }
          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
        })
        .catch( (error) => {
          // console.log(error.response.data);
          let errors = error.response.data;

          for (let prop in errors) {
            // console.log(errors[prop]);
            this.makeToast('danger', errors[prop], '¡Error!');
          }
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";
        });
      }else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },
    getRow() { // Obtener la info de la BD

      axios.get(tools.url("/api/sellwithus")).then((response)=>{
        this.row = response.data;

      });
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;
    }
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.$root.seoadata.sellwithus.meta_title},
        { name: 'og:description', content: this.$root.seoadata.sellwithus.meta_description	},
        { name: 'og:keywords', content: this.$root.seoadata.sellwithus.meta_keywords},
      ]
    }
  },
  mounted(){
      this.getRow();
  }
}
</script>
