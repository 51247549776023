<template lang="html">
  <div id="article-page">

    <section class="placed-backg banner-section-s1" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.png)' }">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 col-titles">
            <h1 class="mb-3 title">{{ $t("blog.title") }}</h1>

            <h6 class="title-sl-1"><span>{{ $t("blog.subtitle") }}</span></h6>
          </div>

          <div class="col-lg-5 col-xl-4 col-info">
            <p></p>
          </div>

          <div class="col-lg-1 col-xl-2 col-bg">
            <div class="bg-color"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="container article-content-section">
      <div class="row">
        <div class="col-12 col-top">
          <p class="mb-2 mb-sm-3">
            <router-link class="btn-back" to="/blog">{{ $t("blog.detail.back") }}</router-link>
          </p>

          <h1 class="mb-2 title" v-if="$i18n.locale == 'es'">{{blog.title_es}}</h1>
          <h1 class="mb-2 title" v-if="$i18n.locale == 'en'">{{blog.title}}</h1>

          <h5 class="by-n-date">{{ $t("blog.detail.by_sothebys") }} <span class="sep">|</span> {{blog.created}}</h5>
        </div>
        <div class="col-12 col-image">
          <div  v-if="$i18n.locale == 'es'" class="placed-backg image">
            <img :src="blog.imageUrl_es">
          </div>
          <div  v-if="$i18n.locale == 'en'" class="placed-backg image">
            <img :src="blog.imageUrl">
          </div>
        </div>

        <div class="col-12 col-content" v-html="blog.body_es" v-if="$i18n.locale == 'es'"></div>
        <div class="col-12 col-content" v-html="blog.body" v-if="$i18n.locale == 'en'"></div>
        
      </div>
    </section>

    <section class="container oversized-container form-section">
      <h2 style="color: #002349;
        font-family: Gelasio,serif!important;
        font-size: 3.2rem;
        font-weight: 400;
        letter-spacing: 7px;
        line-height: 1.1;
        margin-bottom: 5px;
        text-align: center">{{ $t("header.nav.contact_us") }}</h2>

      <b-form class="row justify-content-center" @submit="onSubmit">
        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("contact.form.name")'>
            <b-form-input
              v-model="form.name"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("contact.form.lastname")'>
            <b-form-input
              v-model="form.lastname"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("contact.form.phone")'>
            <b-form-input
              v-model="form.phone"
              type="number"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("contact.form.email")'>
            <b-form-input
              v-model="form.email"
              type="email"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-8 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("contact.form.comments")'>
            <b-form-textarea
              v-model="form.comments"
              placeholder=""
              rows="3"
              max-rows="3"

            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-12" style="background-color: black;"></div>
        <div class="col-lg-8 mb-2" style="text-align: center;">

          <vue-recaptcha
            sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
            ref="recaptcha"
            @verify="handleSuccess"
            >
          </vue-recaptcha>
        </div>

        <div class="col-12 mt-3 text-center">
          <b-button type="submit" class="btn-s1 blue btn-bg">{{ $t("contact.form.submit") }}</b-button>
          <br><br>
        </div>
        
      </b-form>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
      </sweet-modal>
    </section>


  </div>
</template>


<script>
export default {
  data() {
    return {

      blog:{},
      id:null,
      form: {
        name: null,
        lastname: null,
        phone: null,
        email: null,
        comments: null,
        type:'Contáctenos'
      },
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      // == ==
    }
  },
  methods: {
    getBlog() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.get(tools.url("/api/blogs/"+this.id)).then((response)=>{
        this.blog = response.data;
        this.$root.modal_loading = false;
      });
    },
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },
    onSubmit(evt) {
      evt.preventDefault();
      if(this.recaptchaVerified == true){

        this.modal.block = true;
        this.modal.icon = "";
        this.modal.msg = "Loading...";
        this.$refs.modal.open();

        var params = this.form;
        var apiURL = tools.url("/api/contact");

        axios.post( apiURL,params )
        .then( (response) => {
          // alert(response.data.response);
          this.makeToast('success', response.data.response, 'Message sent');
          // alert(response.data.response);

          for (let prop in this.form) {
            this.form[prop] = '';
          }
          this.modal.block = false;
          this.$router.push('/thankyou-page');
          //this.modal.icon = "success";
          //this.modal.msg = "Thank you for contacting us! We will get back to you as soon as possible.";
        })
        .catch( (error) => {
          // console.log(error.response.data);
          let errors = error.response.data;

          for (let prop in errors) {
            // console.log(errors[prop]);
            this.makeToast('danger', errors[prop], '¡Error!');
          }
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = "Error saving information";
        });
      }else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;
    }
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: 'og:title', content: this.blog.meta_title},
        { name: 'og:description', content: this.blog.meta_description	},
        { name: 'og:keywords', content: this.blog.meta_keywords},
      ]
    }
  },
  mounted(){
    if(this.$route.params.id){
      this.id = this.$root._getURLID(this.$route.params.id);
        this.getBlog();
    }
  },
}
</script>

<style>
.col-content img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>