require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

// == Lenguajes del sitio web ==
import VueI18n from 'vue-i18n';
import messages from './langs.js';

Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es',
  messages: messages // set locale messages
})
// == ==

var SocialSharing = require('vue-social-sharing');
Vue.use(SocialSharing);
import { VueRecaptcha } from 'vue-recaptcha';
Vue.component('vue-recaptcha', VueRecaptcha);
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
  });

  import VueGtag from 'vue-gtag';
  Vue.use(VueGtag, {
    config: {
        //id: 'G-X24X7QYHCE',
        id:'G-WGSEMQQX99'
    }
  })
window.Vue=Vue;

//Instancia principal
const app = new Vue({
  el: '#app',
  router:Router,

  data() {
    return {
      isPrefActive: false,
      isSearchActive: false,

      // == Values for filters ==
      locationProps: [
        /*{ text: 'Mexico City', value: 1 },
        { text: 'Puerto Vallarta', value: 2 },
        { text: 'Guadalajara', value: 3 },*/
      ],
      buyRentOpts: [
        { text: 'BUY', value: 1 },
        { text: 'RENT', value: 2 },
      ],
      buyRentOptsEs: [
        { text: 'COMPRA', value: 1 },
        { text: 'RENTA', value: 2 },
      ],


      propTypeOpts :[
          {value:'Residences',text:'Residences',text_es:'Residencias'},
          {value:'Condominium',text:'Condominium',text_es:'Condominio'},
          {value:'Apartment / Tower',text:'Apartment / Tower',text_es:'Apartamento / Torre'},
          {value:'Lot / Land',text:'Lot / Land',text_es:'Lote / Terreno'},
          {value:'Commercial / Hotel',text:'Commercial / Hotel',text_es:'Comercial / Hotel'},
          {value:'Hacienda / Estate',text:'Hacienda / Estate',text_es:'Hacienda / Finca'},
          {value:'Penthouses',text:'Penthouses',text_es:'Penthouses'},  
          
      ],

      // == ==

      // == Search form (filters) ==
      searchForm: {
        location: null,
        propType: null,
        keywords: null,
        buyRent: 1,

        for: null,
        type: [],
        price: [],
        location: null,
        
        lifestyle: null,
        destination:null,
        lifestyle_name:null,
        bedrooms:[],
        toilets:[],
        interior: [],
        exterior:[],

      },
      modal_loading:false,

      currency:'usd',
      unit:'us',
      lifestyles:[],
      seoadata:{
        aboutus:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        agents:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        blogs:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        cotactus:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        destinations:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        home:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        lifestyles:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        projects:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        rent:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        sale:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        search:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        sellwithus:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
        privacy:{
          'meta_title':null,
          'meta_description': null, 
          'meta_keywords': null
        },
      },
      // == ==
    }
  },
  watch:{
      'searchForm.lifestyle':function(val){
          var lifestylename = null;
          for (let x = 0; x < this.lifestyles.length; x++) {
              if (val == this.lifestyles[x]['id']) {
                lifestylename = this.lifestyles[x]['name'];
              }
          }
          this.searchForm.lifestyle_name = lifestylename;
      }
  },
  methods: {
    getStates(){
      axios.get(tools.url("/api/states")).then((response)=>{
        this.locationProps = response.data;

      }).catch((error)=>{

      });
    },

    subDomain(){
      return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    },

    selectLang(){
      var subDomain = this.subDomain();
      // console.log(subDomain);

      if(subDomain){ // Solo cuando está ONLINE
        if(subDomain == 'es'){
          this.$i18n.locale = 'es';
        }else{
          this.$i18n.locale = 'en';
        }
      }
    },

    changeLocale(lang = null){
      if(lang == 'es'){
        this.$i18n.locale = 'es';
      }else{
        this.$i18n.locale = 'en';
      }
    },

    changeLangBTN(lang){ // Cambiar la URL (recargar la pagina). El metodo "selectLang()" se encargará de seleccionar el idioma a cargar, a menos que sea local.
      // console.log(lang);
      var subDomain = this.subDomain();
      var protocol = window.location.protocol + '//';

      if(subDomain != false){ // Solo cuando está ONLINE
        var fullURL = window.location.href.split(protocol);
        var arrayURL = fullURL[1].split("/#/");

        // console.log(arrayURL[0] + '/#/' + arrayURL[1]);
        if(lang == 'es' && this.$i18n.locale != 'es'){
          window.location.replace( protocol + 'es.guadalajarasir.com/#/' + arrayURL[1] );
        }
        else if(lang == 'en' && this.$i18n.locale != 'en'){
          window.location.replace( protocol + arrayURL[0].substring(3) + '/#/' + arrayURL[1] );
        }
      }
      else{ // Solo cuando está en LOCAL
        // console.log('ES LOCAL');
        this.changeLocale(lang);
      }
    },
    getLifestyles() { // Obtener la info de la BD
      axios.get(tools.url("/api/lifestylesOpcs")).then((response)=>{
        this.lifestyles = response.data;
      });
    },
    // == functions para la modificación de urls amigables ==
    _clearString(str){
      if(str != null){
      var newStr =  str.trim()            // Quitar espacios al inicio y final
                      .toLowerCase()          // Convertir a minusculas
                      .replace(/\s/g, '-')    // Convertir espacios a "-"
                      .normalize('NFD').replace(/[\u0300-\u036f]/g, "") // Vocales sin acento
                                        .replace(/[^a-z0-9-]+/gi, '')   // Quitar todo lo que no es del a-z, A-Z o 0-9 (excepto el simbolo "-")
                                        .replace(/--+/g, '-')   // Convertir multiples "-" en uno solo
                                        .replace(/^-/, '')      // Quita el simbolo "-" al inicio
                                        .replace(/-$/, '');     // Quita el simbolo "-" al final
          // console.log(newStr);
      return newStr;
      }else{
      return 'error';
      }
    },

    _converToURL(name, id){
      var url = '';
      var newName = this._clearString(name);

      if( /^\d+$/.test(id) ){ // Si es un numero
        url = newName + '-' + id;
      }else{
        url = '/error';
      }

      return url;
    },

    _getURLID(url){
      var num = url.lastIndexOf('-');
      var idx = url.substring(num + 1);
      var idx = ( /^\d+$/.test(idx) ) ? parseInt(idx) : 'error';

      return idx;
    },

    _getURLName(url)
    {
      var explode = url.split('-');
      return explode[0];
    },
    getSeo() { // Obtener la info de la BD
      axios.get(tools.url("/api/seo")).then((response)=>{
        this.seoadata = response.data;
      });
    },

    
  },

  beforeMount(){
    this.selectLang();
  },

  mounted(){
      //this.getStates();
      this.getSeo();
      this.getLifestyles();
  },

  mode: 'history',
  i18n,
});
