require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

// == Lenguajes del sitio web ==
import VueI18n from 'vue-i18n';
import messages from './langs.js';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es',
  messages: messages // set locale messages
})
// == ==

window.Vue=Vue;

//Instancia principal
const app = new Vue({
  el: '#app',
  router:Router,

  data() {
    return {
      isPrefActive: false,
      isSearchActive: false,

      // == Values for filters ==
      locationProps: [
        /*{ text: 'Mexico City', value: 1 },
        { text: 'Puerto Vallarta', value: 2 },
        { text: 'Guadalajara', value: 3 },*/
      ],
      buyRentOpts: [
        { text: 'BUY', value: 1 },
        { text: 'RENT', value: 2 },
      ],
      buyRentOptsEs: [
        { text: 'COMPRA', value: 1 },
        { text: 'VENTA', value: 2 },
      ],

      propTypeOpts :[
          {value:'Apartment',text:'Apartments'},
          {value:'Bed And Breakfast Homes',text:'Bed And Breakfast Homes'},
					{value:'Condominium',text:'Condominiums'},
					{value:'Co-Op Properties',text:'Co-Op Properties'},
					{value:'Duplex',text:'Duplex Homes'},
					{value:'Farm/Ranch',text:'Farm And Ranch Properties'},
					{value:'Fractional Ownership Properties',text:'Fractional Ownership Properties'},
					{value:'Hacienda',text:'Hacienda And Estancia Homes'},
					{value:'Land Multi-Family Homes',text:'Land Multi-Family Homes'},
					{value:'Other Residential Homes',text:'Other Residential Homes'},
					{value:'Private Islands',text:'Private Islands'},
					{value:'Single Family Detached',text:'Single Family Homes'},
					{value:'Townhouse',text:'Townhouse'},
					{value:'Winery Real Estate',text:'Winery Real Estate'},
					{value:'Vineyard Real Estate',text:'Vineyard Real Estate'},
          {value:'Commercial',text:'Commercial'},
      ],
      // == ==

      // == Search form (filters) ==
      searchForm: {
        location: null,
        propType: null,
        keywords: null,
        buyRent: 1,

        for: null,
        type: [],
        price: [],
        location: null,
        acreage: [],
        lifestyle: null,
        destination:null,
        lifestyle_name:null,

      },
      modal_loading:false,

      currency:'usd',
      unit:'us',
      lifestyles:[]
      // == ==
    }
  },
  watch:{
      'searchForm.lifestyle':function(val){
          var lifestylename = null;
          for (let x = 0; x < this.lifestyles.length; x++) {
              if (val == this.lifestyles[x]['id']) {
                lifestylename = this.lifestyles[x]['name'];
              }
          }
          this.searchForm.lifestyle_name = lifestylename;
      }
  },
  methods: {
    getStates(){
      axios.get(tools.url("/api/states")).then((response)=>{
        this.locationProps = response.data;

      }).catch((error)=>{

      });
    },

    selectLang(){
      var cookieLang = sessionStorage.getItem('language');
      // console.log(cookieLang);

      if(cookieLang){
        if(cookieLang == 'en'){
          this.$i18n.locale = 'en';
        }else{
          this.$i18n.locale = 'es';
        }
      }
      else{
        this.$i18n.locale = 'en';
        sessionStorage.setItem('language', 'en');
      }
    },

    changeLocale(lang = 'es'){
      if(lang == 'en'){
        this.$i18n.locale = 'en';
        sessionStorage.setItem('language', 'en');
      }else{
        this.$i18n.locale = 'es';
        sessionStorage.setItem('language', 'es');
      }
    },
    getLifestyles() { // Obtener la info de la BD
      axios.get(tools.url("/api/lifestyles")).then((response)=>{
        this.lifestyles = response.data;
      });
    },
    // == functions para la modificación de urls amigables ==
    _clearString(str){
      if(str != null){
      var newStr =  str.trim()            // Quitar espacios al inicio y final
                      .toLowerCase()          // Convertir a minusculas
                      .replace(/\s/g, '-')    // Convertir espacios a "-"
                      .normalize('NFD').replace(/[\u0300-\u036f]/g, "") // Vocales sin acento
                                        .replace(/[^a-z0-9-]+/gi, '')   // Quitar todo lo que no es del a-z, A-Z o 0-9 (excepto el simbolo "-")
                                        .replace(/--+/g, '-')   // Convertir multiples "-" en uno solo
                                        .replace(/^-/, '')      // Quita el simbolo "-" al inicio
                                        .replace(/-$/, '');     // Quita el simbolo "-" al final
          // console.log(newStr);
      return newStr;
      }else{
      return 'error';
      }
    },

    _converToURL(name, id){
      var url = '';
      var newName = this._clearString(name);

      if( /^\d+$/.test(id) ){ // Si es un numero
        url = newName + '-' + id;
      }else{
        url = '/error';
      }

      return url;
    },

    _getURLID(url){
      var num = url.lastIndexOf('-');
      var idx = url.substring(num + 1);
      var idx = ( /^\d+$/.test(idx) ) ? parseInt(idx) : 'error';

      return idx;
    },

    _getURLName(url)
    {
      var explode = url.split('-');
      return explode[0];
    }

    
  },

  beforeMount(){
    this.selectLang();
  },

  mounted(){
      this.getStates();
      this.getLifestyles();
  },

  mode: 'history',
  i18n,
});
