<template lang="html">
  <!-- Search -->
  <div class="toggleable-box-menu" v-bind:class="{ 'active' : $root.isSearchActive }">
    <div class="bg-menu" @click="$root.isSearchActive = false"></div>

    <b-form class="box-menu" @submit="onSubmit">
      <!-- Title -->
      <div class="box">
        <div class="row row-menu row-title-close">
          <div class="col col-sm-6 col-label">
            <h5 class="title">{{ $t("search_component.search") }} <strong>{{ $t("search_component.property") }}</strong></h5>
          </div>
          
          <div class="col col-sm-6 col-option">
            <a class="btn-close" @click="$root.isSearchActive = false"><i class="fal fa-times"></i></a>
          </div>
        </div>
      </div>

     

      <!-- Filters -->
      <div class="box box-filters">
         <!-- KEYWORDS -->
      <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>{{ $t("search_component.keywords") }}</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-input v-model="$root.searchForm.keywords" type="text" size="lg" placeholder=""></b-form-input>
            </div>
           
          </div>
        </div>
        <!--  -->
        
        <!-- Listing type -->
        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>{{ $t("search_component.listing_type") }}</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-btn-filter" v-if="$root.searchForm.buyRent == '1'">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.type }" @click="flOpts.type = !flOpts.type"> {{ $t("search_component.properties_for_sale") }}</a>
            </div>
            <div class="box-btn-filter" v-else-if="$root.searchForm.buyRent == '2'">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.type }" @click="flOpts.type = !flOpts.type"> {{ $t("search_component.properties_for_rent") }}</a>
            </div>

           
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.type">
              <div class="_box_">
                <b-form-radio-group class="mb-2 custom-radios-group-s1"
                  v-model="$root.searchForm.buyRent"
                  name="radios-for">
                  <b-form-radio value="1"><span>{{ $t("search_component.for_sale") }}</span></b-form-radio>
                  <b-form-radio value="2"><span>{{ $t("search_component.for_rent") }}</span></b-form-radio>
                </b-form-radio-group>

                <!-- <b-form-checkbox-group class="custom-checkboxes-group-s1"
                  v-model="$root.searchForm.type"
                  name="checkboxes-type">
                  <b-form-checkbox value="1">Sotheby’s Exclusive</b-form-checkbox>
                  <b-form-checkbox value="2">Open Houses</b-form-checkbox>
                  <b-form-checkbox value="3">Virtual Tour</b-form-checkbox>
                  <b-form-checkbox value="4">In Person Tour</b-form-checkbox>
                </b-form-checkbox-group> -->
              </div>
            </b-collapse>
          </div>
        </div>
        <!--  -->

        <!-- Price -->
        <div class="row row-menu">
          <div class="col-sm-4 col-label">
            <h5>{{ $t("search_component.price") }}</h5>
          </div>

          <div class="col-sm-8 col-option">
            <div class="box-btn-filter" v-if="$root.searchForm.buyRent == 1">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.price }" @click="flOpts.price = !flOpts.price">
                <span v-if="(minVal($root.searchForm.price[0], 0)) && (maxVal($root.searchForm.price[1], 5000000))">{{ $t("search_component.any") }}</span>
                <span v-else>
                  $ {{ Intl.NumberFormat("en-US",).format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> - $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small>
                </span>
              </a>
            </div>
            <div class="box-btn-filter" v-if="$root.searchForm.buyRent == 2">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.price }" @click="flOpts.price = !flOpts.price">
                <span v-if="(minVal($root.searchForm.price[0], 0)) && (maxVal($root.searchForm.price[1], 10000))">{{ $t("search_component.any") }}</span>
                <span v-else>
                  $ {{ Intl.NumberFormat("en-US",).format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> - $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small>
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible" v-if="$root.searchForm.buyRent == 1">
            <b-collapse class="collapse-filter-content" v-model="flOpts.price">
              <div class="_box_" v-if="flOpts.pricePlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="50000" :min="0" :max="5000000" v-model="$root.searchForm.price"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.price[0], 0)">No min</span> <span v-else>Min: $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> </span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.price[1], 5000000)">No Max</span> <span v-else>Max: $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small></span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="col-12 col-collapsible" v-else-if="$root.searchForm.buyRent == 2">
            <b-collapse class="collapse-filter-content" v-model="flOpts.price">
              <div class="_box_" v-if="flOpts.pricePlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="500" :min="0" :max="10000" v-model="$root.searchForm.price"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.price[0], 0)">No min</span> <span v-else>Min: $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[0]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small> </span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.price[1], 10000)">No Max</span> <span v-else>Max: $ {{ Intl.NumberFormat("en-US").format($root.searchForm.price[1]) }} <small v-if="$root.currency == 'usd'">USD</small><small v-if="$root.currency == 'mxn'">MXN</small></span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <!--  -->

        <!-- Location -->
        <!-- <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>LOCATION</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select">
              <b-form-select v-model="$root.searchForm.location">
                <b-form-select-option :value="null">Any</b-form-select-option>
                <b-form-select-option :value="loc.value" v-for="(loc, loInx) in $root.locationProps" :key="'loInx-'+loInx">{{ loc.text }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div> -->
        <!--  -->

        <!-- Property type -->
        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>{{ $t("search_component.property_type_filter") }}</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select" v-if="$i18n.locale == 'es'">
              <b-form-select v-model="$root.searchForm.propType">
                <b-form-select-option :value="null">{{ $t("search_component.any") }}</b-form-select-option>
                <b-form-select-option :value="prop.value" v-for="(prop, ptInx) in $root.propTypeOpts" :key="'ptInx-'+ptInx">{{ prop.text_es }}</b-form-select-option>
              </b-form-select>
            </div>
            <div class="box-select" v-else-if="$i18n.locale == 'en'">
              <b-form-select v-model="$root.searchForm.propType">
                <b-form-select-option :value="null">{{ $t("search_component.any") }}</b-form-select-option>
                <b-form-select-option :value="prop.value" v-for="(prop, ptInx) in $root.propTypeOpts" :key="'ptInx-'+ptInx">{{ prop.text }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- Acreage -->
        <!-- <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>ACREAGE</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.acreage }" @click="flOpts.acreage = !flOpts.acreage">
                <span v-if="(minVal($root.searchForm.acreage[0], 0)) && (maxVal($root.searchForm.acreage[1], 90))">Any</span>
                <span v-else>
                  {{ $root.searchForm.acreage[0] }} - {{ $root.searchForm.acreage[1] }}
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.acreage">
              <div class="_box_" v-if="flOpts.acreagePlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="5" :min="0" :max="90" v-model="$root.searchForm.acreage"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.acreage[0], 0)">No min</span> <span v-else>Min: {{ $root.searchForm.acreage[0] }}</span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.acreage[1], 90)">No Max</span> <span v-else>Max: {{ $root.searchForm.acreage[1] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div> -->
        <!--  -->

        <!-- Lifestyles -->
        <div class="row row-menu">
          <div class="col-sm-6 col-label">
            <h5>{{ $t("search_component.lifestyle") }}</h5>
          </div>

          <div class="col-sm-6 col-option">
            <div class="box-select" v-if="$i18n.locale == 'es'">
              <b-form-select v-model="$root.searchForm.lifestyle">
                <b-form-select-option :value="null">{{ $t("search_component.any") }}</b-form-select-option>
                <b-form-select-option :value="prop.id" v-for="(prop, ptInx) in $root.lifestyles" :key="'ptasInx-'+ptInx">{{ prop.name_es }}</b-form-select-option>
              </b-form-select>
            </div>
            <div class="box-select" v-else-if="$i18n.locale == 'en'">
              <b-form-select v-model="$root.searchForm.lifestyle">
                <b-form-select-option :value="null">{{ $t("search_component.any") }}</b-form-select-option>
                <b-form-select-option :value="prop.id" v-for="(prop, ptInx) in $root.lifestyles" :key="'ptasInx-'+ptInx">{{ prop.name }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
        <!--  -->
        <!-- Habitaciones -->
        <div class="row row-menu">
          <div class="col-sm-3 col-label">
            <h5>{{ $t("search_component.bedrooms") }}</h5>
          </div>

          <div class="col-sm-9 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.bedrooms }" @click="flOpts.bedrooms = !flOpts.bedrooms">
                <span v-if="(minVal($root.searchForm.bedrooms[0], 1)) && (maxVal($root.searchForm.bedrooms[1], 10))">Any</span>
                <span v-else>
                  {{ $root.searchForm.bedrooms[0] }} - {{ $root.searchForm.bedrooms[1] }}
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.bedrooms">
              <div class="_box_" v-if="flOpts.bedroomsPlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="1" :min="0" :max="10" v-model="$root.searchForm.bedrooms"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.bedrooms[0], 1)">No min</span> <span v-else>Min: {{ $root.searchForm.bedrooms[0] }}</span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.bedrooms[1], 10)">No Max</span> <span v-else>Max: {{ $root.searchForm.bedrooms[1] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div> 

        <!--  -->
        <!-- Habitaciones -->
        <div class="row row-menu">
          <div class="col-sm-3 col-label">
            <h5>{{ $t("search_component.toilets") }}</h5>
          </div>

          <div class="col-sm-9 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.toilets }" @click="flOpts.toilets = !flOpts.toilets">
                <span v-if="(minVal($root.searchForm.toilets[0], 1)) && (maxVal($root.searchForm.toilets[1], 10))">Any</span>
                <span v-else>
                  {{ $root.searchForm.toilets[0] }} - {{ $root.searchForm.toilets[1] }}
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.toilets">
              <div class="_box_" v-if="flOpts.toiletsPlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="1" :min="0" :max="10" v-model="$root.searchForm.toilets"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.toilets[0], 1)">No min</span> <span v-else>Min: {{ $root.searchForm.toilets[0] }}</span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.toilets[1], 10)">No Max</span> <span v-else>Max: {{ $root.searchForm.toilets[1] }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div> 

        <!--  -->

        <!-- INTERIOR -->
        <div class="row row-menu">
          <div class="col-sm-3 col-label">
            <h5>{{ $t("search_component.interior") }}</h5>
          </div>

          <div class="col-sm-9 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.interior }" @click="flOpts.interior = !flOpts.interior">
                <span v-if="(minVal($root.searchForm.interior[0], 0)) && (maxVal($root.searchForm.interior[1], 90))">Any</span>
                <span v-else>
                  {{ $root.searchForm.interior[0] }} {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}} - {{ $root.searchForm.interior[1] }} {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}}
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.interior">
              <div class="_box_" v-if="flOpts.interiorPlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="500" :min="0" :max="10000" v-model="$root.searchForm.interior"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.interior[0], 0)">No min</span> <span v-else>Min: {{ $root.searchForm.interior[0] }} {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}}</span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.interior[1], 10000)">No Max</span> <span v-else>Max: {{ $root.searchForm.interior[1] }} {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div> 
        <!--  -->
        <!-- EXTERIOR -->
        <div class="row row-menu">
          <div class="col-sm-3 col-label">
            <h5>{{ $t("search_component.exterior") }}</h5>
          </div>

          <div class="col-sm-9 col-option">
            <div class="box-btn-filter">
              <a class="_btn" v-bind:class="{ 'active' : flOpts.exterior }" @click="flOpts.exterior = !flOpts.exterior">
                <span v-if="(minVal($root.searchForm.exterior[0], 0)) && (maxVal($root.searchForm.exterior[1], 90))">Any</span>
                <span v-else>
                  {{ $root.searchForm.exterior[0] }}  {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}} - {{ $root.searchForm.exterior[1] }} {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}}
                </span>
              </a>
            </div>
          </div>

          <div class="col-12 col-collapsible">
            <b-collapse class="collapse-filter-content" v-model="flOpts.exterior">
              <div class="_box_" v-if="flOpts.exteriorPlugin">
                <div class="box-range">
                  <vue-slider :tooltip="false" ref="slider" :height="2" :interval="500" :min="0" :max="10000" v-model="$root.searchForm.exterior"></vue-slider>

                  <div class="range-info">
                    <div class="min">
                      <span v-if="minVal($root.searchForm.exterior[0], 0)">No min</span> <span v-else>Min: {{ $root.searchForm.exterior[0] }}  {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}}</span>
                    </div>
                    <div class="max">
                      <span v-if="maxVal($root.searchForm.exterior[1], 10000)">No Max</span> <span v-else>Max: {{ $root.searchForm.exterior[1] }}  {{ $root.unit == 'us' ? 'SQFT2' : 'M2'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div> 
        <!--  -->
        
      
      </div>

      <!-- Form Buttons -->
      <div class="box-bottom">
        <b-button type="button" class="btn-s1 white btn-outline" @click="clearfilters">{{ $t("search_component.clear") }}</b-button>
        <b-button type="submit" class="btn-s1 white btn-bg">{{ $t("search_component.apply") }}</b-button>
      </div>
    </b-form>
  </div>
  <!--  -->
</template>

<script>
import vueSlider from 'vue-slider-component'; // https://github.com/NightCatSama/vue-slider-component/tree/v2 (para vue 2)
export default {
  components: {
    vueSlider
  },

  data() {
    return {
      flOpts: { // Object usado para algunos filtros que requieren "toggle"
        type: false,

        price: false,
        pricePlugin: false,

        size: false,

        interior: false,
        interiorPlugin: false,
        exterior: false,
        exteriorPlugin: false,
        bedroomsPlugin:false,
        toiletsPlugin:false,
      },

      form: {

      },
    }
  },

  methods: {
    minVal(value, min) {
      if( typeof(value) == 'undefined' || value == min) {
        return true;
      }
    },

    maxVal(value, max) {
      if( typeof(value) == 'undefined' || value == max) {
        return true;
      }
    },

    onSubmit(event) {
      event.preventDefault();
      this.$router.push({ name: 'propsPage', query: { buyrent: this.$root.searchForm.buyRent,
                                                        location: this.$root.searchForm.location,
                                                        propType: this.$root.searchForm.propType,
                                                        minprice: this.$root.searchForm.price[0],
                                                        maxprice: this.$root.searchForm.price[1],
                                                        
                                                        lifestyle: this.$root.searchForm.lifestyle,
                                                      

                                                        minbedrooms: this.$root.searchForm.bedrooms[0],
                                                        maxbedrooms: this.$root.searchForm.bedrooms[1],

                                                        
                                                        mintoilets: this.$root.searchForm.toilets[0],
                                                        maxtoilets: this.$root.searchForm.toilets[1],
                                                        
                                                        keywords: this.$root.searchForm.keywords,
                                                        mininterior: this.$root.searchForm.interior[0],
                                                        maxmininterior: this.$root.searchForm.interior[1],

                                                        minexterior: this.$root.searchForm.exterior[0],
                                                        maxminexterior: this.$root.searchForm.exterior[1],
                              
                                                      } });
    },
    clearfilters(){
        if (this.$root.searchForm.price[0] !== undefined) {
          this.$root.searchForm.price[0] = null;
        }
        if (this.$root.searchForm.price[0] !== undefined) {
          this.$root.searchForm.price[1] = null;
        }

        if (this.$root.searchForm.interior[0] !== undefined) {
            this.$root.searchForm.interior[0] = null;
        }
        if (this.$root.searchForm.interior[0] !== undefined) {
            this.$root.searchForm.interior[1] = null;
        }

        if (this.$root.searchForm.exterior[0] !== undefined) {
          this.$root.searchForm.exterior[0] = null;
        }
        if (this.$root.searchForm.exterior[0] !== undefined) {
          this.$root.searchForm.exterior[1] = null;
        }

        if (this.$root.searchForm.bedrooms[0] !== undefined) {
          this.$root.searchForm.bedrooms[0] = null;
        }
        if (this.$root.searchForm.bedrooms[0] !== undefined) {
          this.$root.searchForm.bedrooms[1] = null;
        }

        if (this.$root.searchForm.toilets[0] !== undefined) {
          this.$root.searchForm.toilets[0] = null;
        }
        if (this.$root.searchForm.toilets[0] !== undefined) {
          this.$root.searchForm.toilets[1] = null;
        }


        this.$root.searchForm.propType = null;
        this.$root.searchForm.price = [];
        this.$root.searchForm.lifestyle = null;
        this.$root.searchForm.bedrooms = null;
        this.$root.searchForm.toilets = null;
        this.$root.searchForm.keywords = null;
        this.$root.searchForm.interior = [];
        this.$root.searchForm.exterior = [];
        this.$root.searchForm.bedrooms = [];
        this.$root.searchForm.toilets = [];
        this.flOpts.price = false;
        this.flOpts.pricePlugin = false;

        this.flOpts.interior = false;
        this.flOpts.interiorPlugin = false;
        this.flOpts.exterior = false;
        this.flOpts.exteriorPlugin = false;
    }
  },

  watch: {
    'flOpts.price'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.pricePlugin = (newValue) ? true : false;
      }, 300);
    },

    'flOpts.interior'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.interiorPlugin = (newValue) ? true : false;
      }, 300);
    },
    'flOpts.exterior'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.exteriorPlugin = (newValue) ? true : false;
      }, 300);
    },
    'flOpts.bedrooms'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.bedroomsPlugin = (newValue) ? true : false;
      }, 300);
    },
    'flOpts.toilets'(newValue, oldValue) {
      setTimeout(() => {
        this.flOpts.toiletsPlugin = (newValue) ? true : false;
      }, 300);
    },
    
    

    '$route' (to, from){
      this.$root.isSearchActive = false;
    }
  },
}
</script>
