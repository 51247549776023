import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', name: 'homePage', component: require(page+'home/index.vue').default , meta:{title:"Extraordinary real estate in Mexico City"}},
      { path: '/contact', component: require(page+'contact/index.vue').default, meta:{title:"Contact"}},
      { path: '/thankyou-page', component: require(page+'contact/thank-you.vue').default, meta:{title:"Contact"}},
      // { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},

      { path: '/sell-with-us', component: require(page+'about/sell-with-us.vue').default, meta:{title:"Sell with us"}},
      { path: '/about-sothebys-mexico', component: require(page+'about/about-sothebys.vue').default, meta:{title:"About Sotheby’s Mexico"}},
      // { path: '/privacy-policy', component: require(page+'about/privacy-policy.vue').default, meta:{title:"Privacy Policy"}},
      { path: '/privacy-policy', component: require(page+'text-pages/privacy-policy.vue').default, meta:{title:"Privacy Policy"}},
      { path: '/terms', component: require(page+'about/terms.vue').default, meta:{title:"Terms & Conditions"}},


      { path: '/agents', component: require(page+'agents/index.vue').default, meta:{title:"Agentss"}},
      { path: '/agents/:id', component: require(page+'agents/detail.vue').default, meta:{title:"Agent"}},

      { path: '/destinations', component: require(page+'discover/destinations.vue').default, meta:{title:"Destinations"}},
      { path: '/destinations/:id', component: require(page+'properties/destinations.vue').default, meta:{title:"Destination"}},

      { path: '/lifestyles', component: require(page+'discover/lifestyles.vue').default, meta:{title:"Lifestyles"}},
      { path: '/lifestyles/:id', component: require(page+'properties/lifestyles.vue').default, meta:{title:"Lifestyle"}},
      { path: '/projects', component: require(page+'discover/projects.vue').default, meta:{title:"Projects"}},
      { path: '/projects/:id', component: require(page+'discover/project-detail.vue').default, meta:{title:"Project"}},

      { path: '/blog', component: require(page+'blog/index.vue').default, meta:{title:"Blogs"}},
      { path: '/blog/:id', component: require(page+'blog/detail.vue').default, meta:{title:"Blog"}},

      { path: '/properties', name: 'propsPage', component: require(page+'properties/index.vue').default, meta:{title:"Properties"}},
      { path: '/properties/sale', name: 'propsSalePage', component: require(page+'properties/sale.vue').default, meta:{title:"Properties for sale"}},
      { path: '/properties/rent', name: 'propsRentPage', component: require(page+'properties/rent.vue').default, meta:{title:"Properties for rent"}},
      { path: '/property/:id', name: 'propDetailPage', component: require(page+'properties/detail.vue').default, meta:{title:"Property Detail",excludeFromGtag: true}},
      { path: '/office/:id', component: require(page+'properties/office.vue').default, meta:{title:"Properties Office"}},
	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}
  // Verifica si la ruta actual debe ser excluida
  if (to.meta.excludeFromGtag) {
    return; // No enviar esta ruta a gtag
  }
  if (Vue.$gtag) {
    Vue.$gtag.pageview({
      page_path: to.fullPath,
      page_title: to.meta.title, // Usa el meta.title aquí
    });
  
  } else {
  }

});


//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Guadalajara Sotheby’s International Realty", filter: (title)=>{ return title+" - Guadalajara Sotheby’s International Realty"; } }
);

// export {routes};
export default MyRouter;
