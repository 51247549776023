const messages = {
    en: {
        shared: {
        },
        header:{
            nav:{
                properties:'Properties',
                    properties_for_sale:'Sale',
                    properties_for_rent:'Rent',
                discover:'Discover',
                    destinations:'Destinations',
                    lifestyles:'Lifestyles',
                    projects:'Projects',
                    blog:'Our blog',

                about_us:'About us',
                    sell_with_us:'Sell my property',
                    about_sothebys_mexico:'About us',
                agents:'Agents',
                contact_us:'Contact us',
            },
            menu_preferencers:{
                preferencers:'PREFERENCES',
                language:'LANGUAGE',
                    en:'English - United States',
                    es:'Spanish - México',
                currency:'CURRENCY',
                unit_measure:'UNITS OF MEASURE',
            },
        },
        footer:{
            properties:'Properties',
            all_listing:'All listing',
            reting:'For rent',
            selling:'For sale',

            discover:'DISCOVER',
            destinations:'Destinations',
            lifestyle:'Lifestyle',
            projects:'Projects',

            about_us:'ABOUT US',
            selling_a_home:'Selling a home',
            about_sothebys_mexico:'About us',
            privacy_policy:"Privacy policy",
            terms:"Terms & conditions",

            more_links:'MORE LINKS',
            agents:'Agents',
            contact_us:'Contact us',
            our_blog:'Our blog',
        },
        home:{
            filter:{
                what_are_you:'What are you',
                looking:'Looking for?',
                location:'Location',
                property_type:'Property type',
                search_by:'Search by',
                keywords:'Location,ID or property',
                search_btn:'Search',
                any_option:'Any',

                for_sale:'For sale',
                for_rent:'For rent',
                filter_by:'Filter by',
                listing_type:'Listing type',
                price:'Price',
                property_type_filter:'Property type',
                lifestyle:'Lifestyle',

                advancedfilters:'Advanced search'
            },
            exclusive_section:{
                our_most_exclusive:'Featured properties',
                our_exclusive:'Our excluisves listing',
                most_breathtaking:'Discover an unparalleled selection of the most extraordinary properties in in Guadalajara. Let yourself be guided by the most exclusive real estate agency in Guadalajara',
                btn_viewall:'View property'
            },
            current_developments:{
                current_developments:'Real Estate Projects',
                our_latest:'Our latest developments',
                discover:'Discover premium real estate developments that have partnered with us',
                btn_seemore:'See more'
            },
            our_locations:{
                our_locations:'Destinations',
                learn_more:'Learn more about in Guadalajara. Let yourself be guided by the most exclusive real estate agency in Guadalajara from the inside out',
                btn_explore:'Explore',
                search:'Search',
            },
            sell_your_property:{
                sell_your_property:'Sell your property',
                sell_your_property_w:'with us',
                let_guide:'Let us guide you through every step of the way, and present your property to an international audience of clients.',
                btn_sell:'SELL A PROPERTY',
            },
            events:{
                news:'',
                events:'Blogs',
                our_latest:'Our latest news',
                btn_read_more:'Read more'
            },
            statistics:{
                local_e:'LOCAL & INTERNATIONAL EXPERIENCE',
                total_listing:'Total listings',
                sold_volumne:'Sold volume',
                happy_customer:'Happy customers',
            },
            form_contact:{
                conect_with_us:'Connect with us',
                name:'Name',
                last_name:'Last name',
                phone:'Phone number',
                email:'Email',
                comments:'Comments',
                submit:'Submit',
            }

        },
        result:{
            title:'Properties for rent',
            title_sale:'Properties for sale',
            subtitle:'OUR BEST PROPERTIES',
            title_description_sale:'Discover the most extraordinary houses and apartments for sale in Guadalajara. Let the most exclusive real estate agency in the area guide you and find the home of your dreams',
            
            title_description:'Discover the most extraordinary houses and apartments for rent in Guadalajara. Let the most exclusive real estate agency in the area guide you and find the home of your dreams',
            
            properties:'Properties',
            subtitleproperties:'Showing listings marketed by all brokers in the search area.',
            sellaproperty:'Sell a property',
            properties_forsale:'for sale',
            properties_forrent:'for rent',

            filter:'Filter by',
            filter_pricelow:'Price Low to High',
            filter_pricehigh:'Price High to Low',
            filter_list:'List',
            filter_map:'Map',
            filter_propeties:'properties',

            result_bath:'Baths',
            result_bath_partial:'Partial',
            result_bed:'Bed',

            
        },
        destinations:{
            title:'Destinations',
            subtitle:'OUR BEST LOCATIONS',
            description:'Contact us so we can help you find your dream home in one of these locations.',
            detail_description:'Discover the most extraordinary houses and apartments for sale in Guadalajara. Let yourself be guided by the most exclusive real estate agency in Guadalajara. ',
        },
        lyfestyles:{
            title:'Lyfestyles',
            subtitle:'OUR BEST LOCATIONS',
            description:'Discover the most beautiful destinations and apartments for sale based on your ideal lifestyle.',
            detail_description:'Discover the most extraordinary houses and apartments for sale in Guadalajara. Let yourself be guided by the most exclusive real estate agency in Guadalajara.  ',
        },
        projects:{
            title:'Projects',
            subtitle:'EXPLORE OUR NEW DEVELOPMENTS',
            description:'Your best life begins with a home that inspires you.',
            download: 'DOWNLOAD BROCHURE',
            description1: "Discover the most exciting new real estate projects in Guadalajara thriving market. From innovative urban residences to exclusive beachfront estates and eco-friendly communities, our curated selection showcases the forefront of high-end living in this paradise. With a focus on excellence and personalized service, we're dedicated to helping you find the perfect new development that aligns with your dreams and lifestyle. Explore Guadalajara newest real estate opportunities with us.",
            description2: "",
            description3: "",

            detail:{
                download_brochure:'Download Brochure',
                masterplan:'Masterplan',
                gallery:'Gallery',
                location:'Location',
                contact_us:'Contact us!'
            },
            form:{
                name:'Name',
                email:'Email',
                phone:'Phone number',
                wheredid:'Where did you hear about us?',
                bestdate:'Best date',
                arlternativedate:'Alternative date',
                horario:'Schedule',
                comentarios:'Comments',
                submit:'Submit',
            }
        },
        blog:{
            title:'Blog',
            subtitle:'OUR RECENT INFORMATION',
            readmore:'READ MORE',
            
            detail:{
                back:'Back to blog',
                by_sothebys:'By Sotheby’s International Realty'
            }
        },
        agents:{
            title:'Guadalajara Sotheby’s International Realty Agents',
            subtitle:'OUR EXCLUSIVE EXPERTS',
            description:'Our local experts will guide you on your journey',

            searchbyname:'SEARCH BY NAME',
            agentsserving:'Agents serving in Guadalajara. Let yourself be guided by the most exclusive real estate agency in Guadalajara',
            results:'results',
            connect:'CONNECT WITH THE CLOSEST OFFICE',
            findyouroffice:'FIND YOUR OFFICE',
            associate:'Associate(s) in Guadalajara. Let yourself be guided by the most exclusive real estate agency in Guadalajara',
            filterby:'Filter by',
            filterorder:'to',
            luxury:'Luxury property specialist',
            phone:'Number',
            email:'Email',
            office:"Guadalajara SOTHEBY'S INTERNATIONAL REALTY OFFICE",
            sendmessage:'SEND A MESSAGE',
            detail:{
                luxury:'Luxury property specialist',
                sendmessage:'SEND A MESSAGE',
                aboutme:'About me',
                office:'Office',
                mobile:'Mobile',
                email:'Email',
                language:'language',
                mylisted:'My listed Properties',
                contactme:'Contact me',
                notpropeties:'There are not properties related to this agent',
            },
            form:{
                name:'Name',
                lastname:'Last name',
                email:'Email',
                phone:'Phone number',
                comentarios:'Comments',
                submit:'Submit',
            }
        },
        contact:{
            title:'Contact us',
            subtitle:'WE WANT TO HEAR FROM YOU',
            description:'Need some help or have some questions?',
            description_two:' Contact us and we will help you as soon as we can.',
            thankyou:"Thank you for contacting",
            brand:"Guadalajara Sotheby's International Realty",
            info_one:'Using the form below, please provide as much detailed information as possible. The information you submit may be shared with our independently owned and operated franchisee so that your concerns can be addressed.',
            info_two:'Please note that by providing your phone number and email address, you are providing consent for us to reach out to you via this method.',
            info_three:'If this is a Data Privacy related matter, please click',
            here:'here',
            from: 'from',
            text_middle:'We invite you to visit our office located in Avenue Ruben Dario 843, Mezanine, Guadalajara, Jalisco, Colonia Prados Povidencia. Sell your property with our tailored solution or find a home that suits your lifestyle. We will be happy to assist you.',

            form:{
                name:'Name',
                lastname:'Last name',
                email:'Email',
                phone:'Phone number',
                comments:'Comments',
                message:'Message',
                submit:'Submit',
                purpose:'Purpose'
            }
            
        },
        detail:{
            home:'Home',
            exclusivelist:'Exclusive list',
            bath:'BATHS',
            bath_partial:'PARTIAL',
            bed:'BED',
            description:'Description',
            sendmessage:'Send a message',
            propetydetails:'PROPERTY DETAILS',
            location:'Location',
            propertytype:'Property type',
            yearbulit:'Year built',
            about:'About',
            city:'Guadalajara',
            bussiest:'The most incredible properties in Guadalajara',
            seemore:'SEE MORE',
            lastnews:'Latest news',
            citynews:'Guadalajara latest news in Guadalajara Sotheby’s International Realty',

            amenities_features:'Amenities & Features',
            listingdetails:'LISTING DETAILS',
            property_id:'Property ID',
            property_type:'Property type',
            marketed_by:'Marketed by',
            listing_price:'Listing price',
            utilities:'Utilities & Building',

            year_bulit:'Year Built',
            lot_interior:'Lot size Interior',
            lot_exterior:'Lot size Exterior',
            parking:'Parking',
            car_garage:'Car Garage',
            interior:'Interior',
            amenities:'Amenities',
            additonal_features:'Additional Features',

            schedule_visit:'SCHEDULE A VISIT',
            luxury:'Luxury property specialist',
            letsget:"Let's get in touch",
            continueyoursearch:'Continue your search',
            form:{
                fullname:'Full name',
                email:'Email',
                schedule_date:'Schedule date',
                select_time:'Select time',
                schedule:'SCHEDULE',

                names:'Name',
                lastnames:'Last name',
                phone:'Phone number',
                comments:'Comments',

                sendmessage:'SEND MESSAGE'

            }

        },
        search_component:{
            search:'SEARCH',
            property:'PROPERTY',
            listing_type:'LISTING TYPE',
            properties_for_sale:'Properties for sale',
            properties_for_rent:'Properties for rent',
            for_sale:'FOR SALE',
            for_rent:'FOR RENT',

            price:'PRICE',
            property_type_filter:'PROPERTY TYPE',
            lifestyle:'LIFESTYLES',
            any:'Any',
            clear:'Clear all',
            apply:'Apply',
            bedrooms:'BEDROOMS',
            toilets:'BATHROOMS',
            interior:'INTERIOR',
            exterior:'EXTERIOR',
            keywords:'KEYWORDS',
            select_option:'Select an option',
        },
        sell_with_us: {
            title1: 'Sell with Us',
            title2: "Don't just get it on the market.<br>Get it the attention it deserves",
            title3: "Be where the world is looking",
            title4: 'What you can get only with us:',
            title5: 'Follow us on social media for highlights on global luxury real estate.',
            title6: 'Expertise that reaches down the block and around the world',
            title7: 'INCOMPARABLE SOCIAL MEDIA',
            title8: 'We drive more eyes to your listing',
            title9: 'List Your Home with Sotheby’s International Realty',
            title10: 'Meet a local expert who can help your journey.',
            title11: 'Access our local expertise and exceptional service.',
  
            text1: 'Exceptional properties deserve extraordinary marketing.',
            text2: 'At Mexico Sotheby’s International Realty, we understand that each property and seller is unique, so our team has designed a strategy by combining our vast experience, innovative technology, and unmatched global reach to attract the attention of the most qualified buyers around the world.',
            text3: 'The homes we represent benefit from the worldwide recognition and prestige of the Sotheby’s name and gain exclusive access to highly qualified global clientele. Our award-winning website — now in 14 languages/dialects – attracts more visitors than any other luxury real estate website. Our relationship with leading media outlets increase traffic to our site and generate exposure for our listings.',
            text4: 'We distribute our listings to the most relevant websites around the globe and measure the impact with top analytic tools. Our worldwide partners include the most authoritative news, lifestyle, and financial voices, as well as real estate-centric websites. Nothing compares to the expertise and exposure we can offer.',
            text5: 'At Sotheby’s International Realty, we create exclusive content to promote your property to those who are passionate about fine living.',
            text6: 'Our network of local real estate professionals apply their local market knowledge to create a comprehensive and customized plan for your home. Find an agent who is closest you and learn their local expertise.',
            text7: 'With experts in every part of the world, we are local everywhere, With innovative technology and unrivaled service, our local brokerage offices can elevate your property and your home selling experience.',
            text9:"If you are considering a move anywhere around the globe, it would be our privilege to help you facilitate your best outcome.",
            linkList: "LIST YOUR HOME",
            linkConnect: 'Connect with us',
            linkAgents: 'Meet our agents',
            linkFindAgent: 'Find your agent',
            linkSellProp: 'Sell your property',
  
            list1: "Unrivaled marketing",
            list2: "Exclusive partnerships",
            list3: "Public relations powerhouse",
            list4: "Brand heritage and reputation",
            list5: "True global reach",
            list6: "Unmatched service",
            list7: "Engaged social followers",
            list8: "Most viewed real estate channel on YouTube",
            list9: "Annual visits to sothebysrealty.com in 2023",
          },
          about_us: {
            title1: 'About Us',
            title2: 'We take the real estate game to the next level',
            title3: 'Let us guide you. Visit our offices.',
            title4: 'Setting the world’s highest standard.',
            title5: 'Follow us on social media for highlights on global luxury real estate.',
            title6: 'Our brand. Your benefit.',
            title7: 'A leader in Mexico luxury real estate',
  
            text1: 'Innovating the luxury real estate industry with a global network of exceptional agents.',
            text2: "The opening of Sotheby's International Realty in Mexico was very natural. The brand treasures extraordinary properties in the world as well as exceptional service. With more than 10 years of experience in the luxury real estate market in Mexico, we are experts in guiding each client during the real estate process.",
            text3: '',
            text4: 'Established in 1976, Sotheby’s International Realty has become the world’s premier luxury residential real estate brokerage. Through our vast global footprint, local expertise, and unequalled referral network, we ensure that the properties we represent receive the far-reaching and unparalleled exposure they deserve.',
            text5: 'The Sotheby’s International Realty® brand is built on the centuries-long prestige of the world’s preeminent auction house. Sotheby’s history and our unmatched reputation give our listings the best-in-class exposure they deserve and assures buyers they are working with a trusted partner.',
            text6: "Mexico's prestigious beaches, beautiful nature, and thriving local economy have led many people to call Mexico their home. It is a country with incredible people, a high standard of living, and an exciting mix of local and foreign cultures.",
  
            linkVisitUs: 'VISIT US!',
            linkAgents: 'MEET OUR AGENTS',
            linkContact: 'Connect with us',
  
            list1a: 'Global<br />Reach',
            list1b: 'Countries & Territories',
            list2a: 'Our<br />Network',
            list2b: 'Offices Worldwide',
            list3a: 'Local<br />expertise',
            list3b: 'Sales Associates',
            list4a: '2023 Global<br />Sales Volume',
            list4b: 'Annual Sales (USD)',
          },
    },
    es: {
        shared: {
        },
        header:{
            nav:{
                properties:'Propiedades',
                    properties_for_sale:'Venta',
                    properties_for_rent:'Renta',
                discover:'Descubrir',
                    destinations:'Destinos',
                    lifestyles:'Estilos de vida',
                    projects:'Proyectos',
                    blog:'Nuestro blog',
                about_us:'Sobre nosotros',
                    sell_with_us:'Vender mi propiedad',
                    about_sothebys_mexico:'Acerca de nosotros',
                agents:'Agentes',
                contact_us:'Contáctanos',
                btn_aply:'Apply'
            },
            menu_preferencers:{
                preferencers:'PREFERENCIAS',
                language:'IDIOMA',
                    en:'Ingles - Estados Unidos',
                    es:'Español - México',
                currency:'MONEDA',
                unit_measure:'UNIDAD DE MEDIDA',
                btn_aply:'Aplicar'
            }
        },
        footer:{
            properties:'Propiedades',
            all_listing:'Todos los listados',
            reting:'En renta',
            selling:'En venta',

            discover:'DESCUBRIR',
            destinations:'Destinos',
            lifestyle:'Estilo de vida',
            projects:'Proyectos',

            about_us:'SOBRE NOSOTROS',
            selling_a_home:'Vender una casa',
            about_sothebys_mexico:"Acerca de nosotros",
            privacy_policy:"Aviso de privacidad",
            terms:"Terminos y condiciones",

            more_links:'MÁS ENLACES',
            agents:'Agentes',
            contact_us:'Contáctenos',
            our_blog:'Nuestro blog',
        },
        home:{
            filter:{
                what_are_you:'¿Qué Está',
                looking:'Buscando?',
                location:'Ubicación',
                property_type:'Tipo de propiedad',
                search_by:'Búsqueda por',
                keywords:'Ubicación, ID o propiedad',
                search_btn:'Buscar',
                any_option:'Cualquiera',

                for_sale:'En venta',
                for_rent:'En renta',
                filter_by:'Filtrado por',
                listing_type:'TIPO DE ANUNCIO',
                price:'PRECIO',
                property_type_filter:'TIPO DE PROPIEDAD',
                lifestyle:'ESTILOS DE VIDA',
                advancedfilters:'Búsqueda avanzada'

            },
            exclusive_section:{
                our_most_exclusive:'Propiedades destacadas',
                our_exclusive:'NUESTRAS EXCLUSIVAS',
                most_breathtaking:'Descubre una selección incomparable de las propiedades más extraordinarias en Guadalajara y Bahía de Banderas.',
                btn_viewall:'Ver propiedad'
            },
            current_developments:{
                current_developments:'Proyectos inmobiliarios',
                our_latest:'Nuestros últimos desarrollos',
                discover:'Descubra desarrollos inmobiliarios premium que se han asociado con nosotros',
                btn_seemore:'VER MÁS'
            },
            our_locations:{
                our_locations:'Destinos',
                learn_more:'Explore la diversidad que Guadalajara ofrece',
                btn_explore:'Explorar',
                search:'Buscar'
            },
            sell_your_property:{
                sell_your_property:'Vende tu propiedad',
                sell_your_property_w:'con nosotros',
                let_guide:'Permítenos guiarte a través de cada paso del proceso y presentar tu propiedad a una audiencia exclusiva de clientes.',
                btn_sell:'VENDER UNA PROPIEDAD',
            },
            events:{
                news:'',
                events:'Blogs',
                our_latest:'Nuestras últimas noticias',
                btn_read_more:'Leer más'

            },
            statistics:{
                local_e:'Experiencia local e internacional',
                total_listing:'Listados totales',
                sold_volumne:'Volumen vendido',
                happy_customer:'Clientes felices',
            },
            form_contact:{
                conect_with_us:'Conecta con nosotros',
                name:'Nombre',
                last_name:'Apellido',
                phone:'Teléfono',
                email:'Correo electrónico',
                comments:'Comentarios',
                submit:'Enviar',
            }
        },
        result:{
            title:'Propiedades en renta',
            title_sale:'Propiedades en venta',
            subtitle:'NUESTRAS MEJORES PROPIEDADES',
            title_description_sale:'Descubre las casas y departamentos en venta más extraordinarios de Guadalajara. Déjate guiar por la agencia inmobiliaria más exclusiva de la zona y encuentra la casa de tus sueños.',
            
            title_description:'Descubre las casas y departamentos en venta más extraordinarios de Guadalajara. Déjate guiar por la agencia inmobiliaria más exclusiva de la zona y encuentra la casa de tus sueños.',
            
            properties:'Propiedades',
            subtitleproperties:'Se muestran las propiedades que se ofrecen en la zona de búsqueda.',
            sellaproperty:'Vender una propiedad',
            properties_forsale:'en venta',
            properties_forrent:'en renta',


            filter:'Filtrado por',
            filter_pricelow:'Precio bajo a alto',
            filter_pricehigh:'Precio alto a bajo',
            filter_list:'Lista',
            filter_map:'Mapa',
            filter_propeties:'propiedades',

            result_bath:'Baños',
            result_bath_partial:'Parcial',
            result_bed:'Recamaras',
        },
        destinations:{
            title:'Destinos',
            subtitle:'NUESTRAS MEJORES UBICACIONES',
            description:'Contáctanos para ayudarte a encontrar la casa de tus sueños en una de estas ubicaciones',
            detail_description:'Descubre las casas y departamentos más extraordinarios en venta en Guadalajara. Déjate guiar por la agencia inmobiliaria más exclusiva de Guadalajara, y encuentra la casa de tus sueños.',
        },
        lyfestyles:{
            title:'Estilos de vida',
            subtitle:'NUESTRAS MEJORES UBICACIONES',
            description:'Descubra los destinos y apartamentos en venta más hermosos según su estilo de vida ideal.',
            detail_description:'Descubre las casas y departamentos más extraordinarios en venta en Guadalajara. Déjate guiar por la agencia inmobiliaria más exclusiva de Guadalajara, y encuentra la casa de tus sueños.',
        },
        projects:{
            title:'Proyectos',
            subtitle:'EXPLORE NUESTROS DESARROLLOS',
            description:'Tu mejor vida comienza con un hogar que te inspire.',
            download: 'DESCARGAR FOLLETO',
            description1: "Descubra los proyectos inmobiliarios más destacados en el creciente mercado de Mexico. Desde innovadoras residencias urbanas, exclusivas propiedades frente al mar hasta comunidades ecológicas, nuestra curada selección muestra la vanguardia de la vida de lujo en este paraíso. Con un enfoque en la excelencia y un servicio personalizado, estamos dedicados a ayudarle a encontrar el nuevo desarrollo perfecto que se alinee con sus sueños y estilo de vida. Explore las más recientes oportunidades inmobiliarias en Guadalajara con nosotros.",
            description2: "",
            description3: "",
            detail:{
                download_brochure:'Descargar Brochure',
                masterplan:'Plan maestro',
                gallery:'Galería',
                location:'Ubicación',
                contact_us:'Contactanos!'
            },
            form:{
                name:'Nombre',
                email:'Correo',
                phone:'Telefono',
                wheredid:'¿Dónde escuchaste acerca de nosotros?',
                bestdate:'Mejor fecha',
                arlternativedate:'Fecha alternativa',
                horario:'Horario',
                comentarios:'Comentarios',
                submit:'Enviar',
            }
        },
        blog:{
            title:'Blog',
            subtitle:'NUESTRA INFORMACIÓN RECIENTE',
            readmore:'LEER MÁS',

            detail:{
                back:'Volver al blog',
                by_sothebys:'Por Sotheby’s International Realty'
            }
        },
        agents:{
            title:'Guadalajara Sotheby’s International Realty Agents',
            subtitle:'NUESTROS EXPERTOS EXCLUSIVOS',
            description:'Nuestros expertos locales te guiarán en tu viaje',

            searchbyname:'BUSCAR POR NOMBRE',
            agentsserving:'Agentes que atienden en Guadalajara',
            results:'resultados',
            connect:'CONÉCTATE CON LA OFICINA MÁS CERCANA',
            findyouroffice:'ENCUENTRA TU OFICINA',
            associate:'Asociado (s) en Guadalajara',
            filterby:'Filtrado por',
            filterorder:'a',
            luxury:'Especialista en propiedades de lujo',
            phone:'Teléfono',
            email:'Correo',
            office:"Guadalajara SOTHEBY'S INTERNATIONAL REALTY OFFICE",
            sendmessage:'ENVIAR UN MENSAJE',

           detail:{
                luxury:'Especialista en propiedades de lujo',
                sendmessage:'ENVIAR UN MENSAJE',
                aboutme:'Acerca de mí',
                office:'Oficina',
                mobile:'Teléfono',
                email:'Correo',
                language:'Idiomas',
                mylisted:'Mis propiedades listadas',
                contactme:'Contáctame',
                notpropeties:'No hay propiedades relacionadas con este agente',
                
            },
            form:{
                name:'Nombre(s)',
                lastname:'Apellidos',
                email:'Correo',
                phone:'Telefono',
                comentarios:'Comentarios',
                submit:'Enviar',
            }
        },
        contact:{
            title:'Contáctanos',
            subtitle:'Nosotros queremos escuchar de ti',
            description:'¿Necesitas ayuda o tienes algunas preguntas?',
            description_two:'Ponte en contacto con nosotros y te ayudaremos lo antes posible.',
            thankyou:"Gracias por contactarse con",
            brand:"Guadalajara Sotheby's International Realty",

            info_one:'Por favor ingresa la siguiente información para poder ayudarte.',
            info_two:'Ten en cuenta que al proporcionar tu número de teléfono y dirección de correo electrónico, estás dando tu consentimiento para que nos comuniquemos contigo a través de ese método.',
            info_three:'Si se trata de un asunto relacionado con la privacidad de datos, da clic',
            here:'aqui',
            from: 'desde',
            text_middle:'Te invitamos a visitar nuestra oficina ubicada en la Avenida Ruben Dario 843, Mezanine, Guadalajara, Jalisco, Colonia Prados Povidencia. Vende tu propiedad con nuestra solución personalizada o encuentra una casa que se adapte a tu estilo de vida. Con gusto te atenderemos.',

            form:{
                name:'Nombre(s)',
                lastname:'Apellidos',
                email:'Correo electrónico',
                phone:'Teléfono',
                comments:'Comentarios',
                message:'Mensajes',
                submit:'Enviar',
                purpose:'Propósito'
            }
            
        },
        detail:{
            home:'Incio',
            exclusivelist:'Lista exclusiva',
            bath:'BAÑOS',
            bath_partial:'PARCIAL',
            bed:'RECAMARA',
            description:'Descripción',
            sendmessage:'Enviar un mensaje',
            propetydetails:'DETALLES DE LA PROPIEDAD',
            location:'Ubicación',
            propertytype:'Tipo de propiedad',
            yearbulit:'Año de construcción',
            about:'Acerca de',
            city:'Guadalajara',
            bussiest:'Las propiedades más increíbles en Guadalajara',
            seemore:'VER MÁS',
            lastnews:'Últimas noticias',
            citynews:'Últimas noticias en Guadalajara Sotheby’s International Realty',

            amenities_features:'Servicios y características',
            listingdetails:'LISTADO DE DETALLES',
            property_id:'Identificación de propiedad',
            property_type:'Tipo de propiedad',
            marketed_by:'Comercializado por',
            listing_price:'Precio de lista',
            utilities:'Servicios públicos y construcción',
            
            
            lot_interior:'Tamaño del lote Interior',
            lot_exterior:'Tamaño del lote Exterior',
            parking:'Estacionamiento',
            car_garage:'Garaje',
            interior:'Interior',
            amenities:'Comodidades',
            additonal_features:'Características adicionales',

            schedule_visit:'PROGRAMAR UNA VISITA',
            luxury:'Especialista en propiedades de lujo',
            letsget:"Mantengámonos en contacto",
            
            continueyoursearch:'Continúa tu búsqueda',
            form:{
                fullname:'Nombre completo',
                email:'Correo electrónico',
                schedule_date:'Fecha de programacion',
                select_time:'Seleccionar hora',
                schedule:'PROGRAMAR',

                names:'Nombre(s)',
                lastnames:'Apellidos',
                phone:'Teléfono',
                comments:'Comentarios',

                sendmessage:'ENVIAR MENSAJE'

            }
        },
        search_component:{
            search:'BUSCAR',
            property:'PROPIEDAD',
            listing_type:'TIPO DE ANUNCIO',
            properties_for_sale:'Propiedades en venta',
            properties_for_rent:'Propiedades en renta',
            for_sale:'EN VENTA',
            for_rent:'EN RENTA',

            price:'PRECIO',
            property_type_filter:'TIPO DE PROPIEDAD',
            lifestyle:'ESTILOS DE VIDA',
            any:'Cualquier',
            clear:'Limpiar todo',
            apply:'Aplicar',
            bedrooms:'HABITACIONES',
            toilets:'BAÑOS',
            interior:'INTERIOR',
            exterior:'EXTERIOR',
            keywords:'PALABRAS CLAVE',
            select_option:'Selecciona una opcion',
        },
        sell_with_us: {
            title1: 'Venda con nosotros',
            title2: "No se limite a solo poner su propiedad en venta. <br>Consígale la atención que merece.",
            title3: "Esté donde el mundo está buscando",
            title4: 'Lo que solo nosotros le ofrecemos:',
            title5: 'Síganos en las redes sociales para estar al tanto de las novedades del sector inmobiliario de lujo a nivel internacional.',
            title6: 'Experiencia que se extiende localmente hasta todo el mundo.',
            title7: 'UNA PRESENCIA EN REDES SOCIALES INCOMPARABLE',
            title8: 'Hacemos que su propiedad atraiga más miradas',
            title9: "Anuncie su vivienda con Sotheby's International Realty",
            title10: 'Conozca a un experto en su zona que lo acompañe en este proceso.',
            title11: 'Servicio tan elevado como sus estándares.',
  
            text1: 'Las propiedades extraordinarias deben ser anunciadas como merecen.',
            text2: "En México Sotheby's International Realty entendemos que cada propiedad y propietario es único, por eso nuestro equipo ha diseñado una estrategia combinando nuestra vasta experiencia, tecnología innovadora y alcance global inigualable para atraer la atención de los compradores más calificados alrededor del mundo.",
            text3: "Las propiedades que representamos se benefician del reconocimiento y prestigio mundial del nombre de Sotheby's y obtienen acceso exclusivo a una clientela global altamente calificada. Nuestro galardonado sitio web, ahora en 14 idiomas, atrae a más visitantes que cualquier otro sitio web de bienes raíces de lujo. Nuestra relación con los principales medios de comunicación aumenta el tráfico a nuestro sitio y genera más exposición a la propiedad.",
            text4: 'Anunciamos nuestras propiedades en los sitios web más relevantes de todo el mundo y medimos el impacto con las mejores herramientas analíticas. Nuestros socios en todo el mundo incluyen las noticias más autorizadas, el estilo de vida y las voces financieras, así como sitios web centrados en bienes raíces. Nada se compara con la experiencia y la exposición que podemos ofrecer.',
            text5: "En México Sotheby's International Realty, creamos contenido exclusivo para promocionar su inmueble a aquellos a los que les fascina el buen vivir.",
            text6: "Nuestra red de profesionales inmobiliarios locales aplica su conocimiento del mercado local para crear un plan integral y personalizado para su hogar. Encuentre un agente que esté cerca de usted y aprenda su experiencia local.",
            text7: "Es probable que la decisión de vender su casa sea una de las decisiones más importantes que tomará, y es muy importante contar con un profesional de bienes raíces confiable y de calidad que lo guíe durante todo el proceso.",
            text9: "Si está considerando mudarse a cualquier parte del mundo, será un privilegio para nosotros ayudarle.",
            linkList: "VENDA SU PROPIEDAD",
            linkConnect: 'Conecte con nosotros',
            linkAgents: 'Conozca nuestros asesores',
            linkFindAgent: 'Encontrar un asesor',
            linkSellProp: 'Venda su propiedad',
  
            list1: "Marketing inigualable",
            list2: "Alcance global",
            list3: "Excelentes relaciones públicas",
            list4: "La reputación e historia de nuestra marca",
            list5: "Un alcance global",
            list6: "Un servicio insuperable",
            list7: "Seguidores que interactúan",
            list8: "El canal sobre inmobiliaria más visto de YouTube",
            list9: "Visitas anuales a sothebysrealty.com",
          },
          about_us: {
            title1: 'Acerca de nosotros',
            title2: 'Llevamos el juego inmobiliario al siguiente nivel',
            title3: 'Déjenos guiarle. Visite nuestras oficinas.',
            title4: 'Estableciendo el estándar más alto del mundo.',
            title5: 'Síganos en las redes sociales para conocer lo más destacado sobre bienes raíces de lujo a nivel mundial.',
            title6: 'Nuestra marca. Su beneficio.',
            title7: "Líder en bienes raíces de lujo en México",
  
            text1: 'Innovando la industria inmobiliaria de lujo con una red global de agentes excepcionales.',
            text2: "La apertura de Sotheby's International Realty en México fue muy natural. La marca atesora propiedades extraordinarias en el mundo así como un servicio excepcional.Con más de 10 años de experiencia en el mercado inmobiliario de lujo en México, somos experto en guiar a cada cliente durante el proceso inmobiliario.",
            text3: '',
            text4: "Fundada en 1976, Sotheby's International Realty se ha convertido en la principal agencia de bienes raíces residenciales de lujo del mundo. A través de nuestra amplia presencia global, experiencia local y red de referencias inigualable, nos aseguramos de que las propiedades que representamos reciban la exposición de gran alcance e incomparable que merecen.",
            text5: "La marca Sotheby's International Realty® se basa en el prestigio centenario de la casa de subastas más importante del mundo. La historia de Sotheby's y nuestra reputación incomparable brindan a nuestros listados la mejor exposición que merecen y garantizan a los compradores que están trabajando con un socio confiable.",
            text6: "Las prestigiosas playas de México, su hermosa naturaleza y su próspera economía local han llevado a muchas personas a llamar a México su hogar. Es un país con gente increíble, un alto nivel de vida y una mezcla apasionante de culturas locales y extranjeras.",
  
            linkVisitUs: '¡VISITANOS!',
            linkAgents: 'CONOZCA A NUESTROS ASESORES',
            linkContact: 'Conecte con nosotros',
  
            list1a: 'Alcance<br>global',
            list1b: 'Países y territorios',
            list2a: 'Nuestra<br>red',
            list2b: 'Oficinas en todo el mundo',
            list3a: 'Experiencia<br>local',
            list3b: 'Asociados de ventas',
            list4a: 'Volumen de ventas<br>globales 2023',
            list4b: 'Ventas anuales (USD)',
          },
    },
}
export default messages;
