<template lang="html">
    <div id="about-us-2-page">
  
      <!-- <section class="info-1-section" >
        <div class="container">
          <div class="box-intro">
            <h1 class="title">Privacy Notice</h1>
  
          </div>
  
  
        </div>
      </section> -->
  
      <section class="container info-2-section" style="padding-top: 70px;">
        <div class="row row-1" v-if="$i18n.locale == 'en'" >
          <div class="col-lg-12 text-justify pt-0 box-1-info" v-html="row.body"></div>
        </div>
        <div class="row row-1" v-if="$i18n.locale == 'es'" >
          <div class="col-lg-12 text-justify pt-0 box-1-info" v-html="row.body_es"></div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: null,
          lastName: null,
          phone: null,
          email: null,
          comments: null,
        },
  
        recaptchaVerified:false,
  
        modal:{
          msg:'',
          icon:'',
          block:false,
        },
        row:{
  
        }
      }
    },
  
    methods: {
      goToSection(idx) {
        document.querySelector('#info-6-section').scrollIntoView({behavior: 'smooth'});
      },
  
      goToFooter(idx) {
        document.querySelector('#footer').scrollIntoView({behavior: 'smooth'});
      },
  
      makeToast(variant = null, msg, title) {
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          solid: true,
          toaster: 'b-toaster-top-right',
          appendToast: true
        })
      },
  
      handleSuccess(response) {
        this.recaptchaVerified = true;
      },
      getRow() { // Obtener la info de la BD
        axios.get(tools.url("/api/terms")).then((response)=>{
          this.row = response.data;
  
        });
      },
    },
  
    mounted(){
        this.getRow();
    }
  }
  </script>
  