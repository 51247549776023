<template lang="html">
  <div id="agent-page">

    <section class="basic-info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-info">
            <h5 class="location">{{agent.address}}</h5>
            <h1 class="name">{{agent.name}}</h1>

            <!-- <h4 class="mb-5 pos">{{agent.profession}}</h4> -->
            <h4 class="mb-5 pos">{{ $t("agents.detail.luxury") }}</h4>

            <a class="t-150 btn-msg" @click="scrollToElement('contactSection')">{{ $t("agents.detail.sendmessage") }} <i class="fal fa-long-arrow-right"></i></a>
          </div>

          <div class="col-lg-4 col-photo"></div>
        </div>
      </div>
    </section>

    <section class="info-section" id="info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-8 col-text">
            <h6 class="title">{{ $t("agents.detail.aboutme") }}</h6>

            <div class="descr" v-html="agent.description_es" v-if="$i18n.locale == 'es'"></div>
            <div class="descr" v-html="agent.description" v-if="$i18n.locale == 'en'"></div>
            
          </div>

          <div class="col-lg-4 col-photo">
            <div class="placed-backg photo" v-bind:style="{ backgroundImage: 'url('+ agent.imageUrl +')' }">
              <img src="public/images/pages/agents/agent.png">
            </div>

            <div class="box-contacto">
              <h6 class="mb-2 office">MEXICO SOTHEBY'S INTERNATIONAL REALTY</h6>
              <h6 class="location">{{agent.address}}</h6>

              <hr class="style-1" />

              <h6 class="mb-1 contact">{{ $t("agents.detail.office") }}: {{agent.phone}}</h6>
              <h6 class="mb-1 contact">{{ $t("agents.detail.mobile") }}: {{agent.mobile}}</h6>
              <!-- <h6 class="mb-1 contact">Email: {{agent.email}}</h6> -->
              <h6 class="mb-1 contact">{{ $t("agents.detail.email") }}: info@cdmxsir.com</h6>
              <h6 class="contact">{{ $t("agents.detail.language") }}: {{agent.languages}}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container properties-section" >
      <div class="row">
        <div class="col-12 col-top">
          <div class="col-title">
            <h5>{{ $t("agents.detail.mylisted") }}</h5>
          </div>

          <div class="col col-options" v-if="$i18n.locale == 'en'">
            <b-form-group class="mb-0 custom-f-group-radio-1"
              v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="buyRent"
                :options="$root.buyRentOpts"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </div>

          <div class="col col-options" v-if="$i18n.locale == 'es'">
            <b-form-group class="mb-0 custom-f-group-radio-1"
              v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="buyRent"
                :options="$root.buyRentOptsEs"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                name="radio-btn-outline"
                buttons
              ></b-form-radio-group>
            </b-form-group>
          </div>

        </div>
      </div>

      <div class="row row-props-xl-3" v-if="properties.length > 0" >
        <div class="col-md-6 col-lg-4 col-xl-3 col-property-sample-1" v-for="(prop, prInx) in properties" :key="'prInx-'+prInx" >
          <router-link class="box-link" :to="'/property/'+prop.id">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+prop.imageUrl+')' }">
              <img src="public/images/pages/properties/property.png">
            </div>

            <div class="box-text">
              <h5 class="name">{{ prop.name }}</h5>
              <h6 class="price" v-if="$root.currency == 'usd'">$ {{ formatNum(prop.price_usd) }} USD</h6>
                    <h6 class="price" v-else-if="$root.currency == 'mxn'">$ {{ formatNum(prop.price_mxn) }}<span v-if="prop.price == 0 || prop.price == null"> USD </span> <span v-else> MXN</span></h6>
                  

              <div class="box-prop-info">
                <!-- <span>SIZE: {{ prop.meters_ground}} FT</span> -->
                <span style="padding-right: 0px">{{ prop.toilets}} {{ $t("result.result_bath") }}</span> <span style="padding-right: 0px" v-if="prop.toilets_partial != null && prop.toilets_partial != 0">, {{prop.toilets_partial}} {{ $t("result.result_bath_partial") }}</span>
                <span>{{ prop.bedrooms}} {{ $t("result.result_bed") }}</span>
                <span>{{ prop.size_interior}} </span>
                
              </div>
            </div>
          </router-link>
        </div>
        
        

        

      </div>
      <div class="row row-props-xl-3" v-else>
          <p style="padding-left: 15px;">{{ $t("agents.detail.notpropeties") }} </p>
        
      </div>
      <br v-if="properties.length > 0"><br v-if="properties.length > 0">
      <div class="row" v-if="properties.length > 0">
            <div class="col-12 text-center">
              <div class="d-inline-block mx-0 col-pagination-sample-1">
                <b-pagination-nav :link-gen="linkGen" :number-of-pages="pagination.total" use-router></b-pagination-nav>
              </div>
            </div>
        </div>
    </section>

    <section class="container oversized-container contact-section">
      <div class="anchor" ref="contactSection"></div>

      <b-form class="row justify-content-center" @submit="onSubmit">
        <div class="col-12 col-title">
          <h2 class="title">{{ $t("agents.detail.contactme") }}</h2>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
            :label='$t("agents.form.name")'>
            <b-form-input
              v-model="form.name"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.lastname")'>
            <b-form-input
              v-model="form.lastname"
              type="text"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.phone")'>
            <b-form-input
              v-model="form.phone"
              type="number"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-4 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.email")'>
            <b-form-input
              v-model="form.email"
              type="email"
              size="lg"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12"></div>

        <div class="col-lg-8 mb-2">
          <b-form-group class="custom-f-group-2"
          :label='$t("agents.form.comentarios")'>
            <b-form-textarea
              v-model="form.msg"
              placeholder=""
              rows="3"
              max-rows="3"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-5" style="background-color: black;"></div>
        <div class="col-8">

          <vue-recaptcha 
            sitekey="6LcaE4okAAAAAFYKGFfWc9wbF0IXRcRBzSzZmiyw"
            ref="recaptcha"
            @verify="handleSuccess"
            >

          </vue-recaptcha>
        </div>

        <div class="col-12 text-center">
          <b-button type="submit" class="btn-s1 blue btn-bg">{{ $t("agents.form.submit") }}</b-button>
        </div>
      </b-form>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      properties: [
        /*{ id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },

        { id: 1, imageUrl: 'public/images/pages/properties/property-4.jpg', name: 'Villa Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-3.jpg', name: 'Vista hermosa', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-2.jpg', name: 'Villa de los Angeles', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        { id: 1, imageUrl: 'public/images/pages/properties/property-1.jpg', name: 'Margarita valley', price: 7000000, toilets: 3, toilets_partial: 1, bedrooms: 3, size_interior: '11.204 Sq Ft', size_extrerio: '' },
        */
      ],
      propertiesAll:{

      },

      buyRent: 1,

      form: {
        name: null,
        lastName: null,
        phone: null,
        email: null,
        comments: null,
        agents_id:null
      },
      agent:{
        properties:[]
      },
      pagination:{
        currentpage:1,
        total:1,
        number: 10,
        total_products:1,
      },
      recaptchaVerified:false,
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      
    }
  },
  watch:{
    'buyRent':function(val){
      /*var new_properties = [];
      
      if(val == 1){
        for (let x = 0; x < this.agent.properties.length; x++) {
            if (this.agent.properties[x].type_operation == 'Venta') {
                new_properties.push(this.agent.properties[x]);
            }
        }
      }
      else if(val == 2){
        for (let x = 0; x < this.agent.properties.length; x++) {
            if (this.agent.properties[x].type_operation == 'Renta') {
              new_properties.push(this.agent.properties[x]);
            }
        }
      }

      this.properties = new_properties;*/
      this.pagination.currentpage = 1;
      this.$router.push('/agents/'+this.$route.params.id);
      this.getAgentProperties();
      var sect_pro = document.getElementById("info-section");
        window.scroll(0, sect_pro.scrollHeight);
    },
    '$route.query.page':function(val){
        if (val) {
            this.pagination.currentpage = val;
        }
        else{
            this.pagination.currentpage = 1;
        }
        this.getAgentProperties();
        var sect_pro = document.getElementById("info-section");
        window.scroll(0, sect_pro.scrollHeight);
    },
  },
  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    scrollToElement(ref) {
      this.$refs[ref].scrollIntoView({ behavior: "smooth" })
    },

    formatNum(num) {
      let x = Intl.NumberFormat("en-US").format(num);
      return x;
    },

    onSubmit(evt) {
      evt.preventDefault();

      if(this.recaptchaVerified == true){
          this.form.agents_id = this.$route.params.id;
          var params = this.form;
          var apiURL = tools.url("/api/contactagents");

          axios.post( apiURL,params )
          .then( (response) => {
            // alert(response.data.response);

            if(this.$i18n.locale == 'es'){
                this.makeToast('success', response.data.response, 'Mensaje enviado');
                alert('Mensaje enviado con éxito');
            }
            else{
                this.makeToast('success', response.data.response, 'Message sent');
                alert('Message sent successfully');
            }
            

            for (let prop in this.form) {
              this.form[prop] = '';
            }
          })
          .catch( (error) => {
            // console.log(error.response.data);
            let errors = error.response.data;

            for (let prop in errors) {
              // console.log(errors[prop]);
              this.makeToast('danger', errors[prop], '¡Error!');
            }
          });
      }
      else{
        this.modal.block = false;
          this.modal.icon = "warning";
          this.modal.msg = "Complete the captcha";
          this.$refs.modal.open();
      }
    },
    getAgent() { // Obtener la info de la BD
      this.$root.modal_loading = true;
      axios.get(tools.url("/api/agents/"+this.$route.params.id)).then((response)=>{
        this.agent = response.data;
        var new_properties = [];
        
        /*for (let x = 0; x < this.agent.properties.length; x++) {
            if (this.agent.properties[x].type_operation == 'Venta') {
                new_properties.push(this.agent.properties[x]);
            }
        }
        this.properties = new_properties;*/
        this.$root.modal_loading = false;
      });
    },
    linkGen(pageNum) {
         return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getAgentProperties() { 
      this.$root.modal_loading = true;

      axios.get(tools.url("/api/agentsproperties/"+this.buyRent+"/"+this.$route.params.id+"?page=" + this.pagination.currentpage)).then((response)=>{

        this.propertiesAll = response.data.data;
        this.pagination.total = response.data.last_page;
        this.pagination.total_products = response.data.total;

        this.properties = response.data.data;
        this.$root.modal_loading = false;
      });
    },
    handleSuccess(response) {
      this.recaptchaVerified = true;
            
    }
  },
  mounted(){
    if(this.$route.params.id){
        this.getAgent();
        this.getAgentProperties();
    }
  },
}

</script>
